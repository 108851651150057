<p-blockUI [blocked]="loader">
  <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 99999999999999999;">
      <p-progressSpinner class="spin"></p-progressSpinner>
  </div>
</p-blockUI>

<p-confirmDialog [baseZIndex]="200" [style]="{width: '400px'}"></p-confirmDialog>
<p-toast [baseZIndex]="300" key="tst" position="center" [style]="{ 'width': '90vw', 'maxWidth': '400px' }"></p-toast>


<div class="ui-fluid">
<div class="ui-g">
  <div class="ui-g-12 ui-md-12 ui-xl-12">
        <p class="migaPan"><a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>Planning</p>
        <div class="card card-w-title">
              <app-headerbar
                  titulo='Planning'
                  [status]="status"
                  [showSave]=false
                  [showDelete]="false"
                  newCaption="Crear nuevo calendario"
                  (cancel)="cancel()">
              </app-headerbar>

              <app-errordata *ngIf="status<0"
                  [error]="txtError"
                  (reintentar)="reintentar()">
              </app-errordata>

             <div  [hidden]="status!=1" >
              <div class="p-grid">
                <div class="p-col-fixed" style="width:275px; padding-top: 6px">
                    <h1>Fecha</h1>
                    <p-calendar (onSelect)="changeDate()" [locale]="es"   [(ngModel)]="fecha" [inline]="true" [selectOtherMonths]="true"></p-calendar>
                    <h1 style="margin-bottom: 5px">Agendas</h1>
                    <p-listbox [listStyle]="{'max-height':'50vh'}" [style]="{'width':'260px'}"
                        [options]="agendas"
                        (onChange)="changeAgendas()"
                        [dataKey]="idAgenda"
                        optionLabel="nombre"
                        [(ngModel)]="selectedAgendas"
                        multiple="multiple"
                        filter="true"
                        checkbox="true">
                    </p-listbox>

                </div>
                <div class="p-col app-calendar">
                    <div *ngIf="cargando" class="cargando"><p-progressSpinner></p-progressSpinner></div>
                    <!--
                    <full-calendar
                    #calen
                    defaultView= 'timeGridWeek'
                    schedulerLicenseKey= '0090342599-fcs-1573548059'
                    [plugins]="calendarPlugins"
                    deepChangeDetection="true"
                    locale="es"
                    [slotLabelFormat]="{hour: 'numeric',minute: '2-digit',omitZeroMinute: false,meridiem: 'short'}"
                    [resources]="resources"
                    slotDuration='00:10:00'
                    slotLabelInterval='01:00:00'
                    weekNumberCalculation="ISO"
                    height= "parent"
                    [weekends]="true"
                    [minTime]= 'inicio'
                    [maxTime]= 'fin'
                    editable="true"
                    eventLimit= "true"
                    [titleFormat]= "{ month: 'long',year: 'numeric',day: 'numeric',weekday: 'long'}"
                    [header]="headers"
                    resourceLabelText= "Agendas"
                    [views]="vistas"
                    [buttonText]="{today:'hoy',day:'dia'}"
                    (eventRender)="eventRender($event)"
                    (dayRender)="dayRender($event)"
                    (eventDrop)="eventDrop($event)"
                    (eventResize)="eventResize($event)"
                    (dateClick)="dateClick($event)"
                    (eventClick)="eventClick($event)"
                    ></full-calendar>
-->
                </div>
               </div>
               </div>
  </div>
</div>
</div>

<div *ngIf="status==1" class="ui-dialog-buttonpane ui-helper-clearfix center" style="max-width: 1000px;">

</div>




<!-- Formulario de creación de calendario -->


<p-dialog [header]="title" [(visible)]="displayEvent" [modal]="true">
    <div style="margin-bottom: 50px; min-width: 600px">

        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Agenda</label>
            </div>
            <div class="ui-g-12 ui-md-9 ui-lg-7">
              <p-dropdown appendTo="body"  optionLabel="nombre" [options]="agendas" [(ngModel)]="agenda"></p-dropdown>
            </div>
        </div>
        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Paciente</label>
            </div>
            <div class="ui-g-10 ui-md-8 ui-lg-7">
                <input #nombre type="text" pInputText [(ngModel)]="entrada.nombre">
            </div>
            <div class="ui-g-2">
                <button pButton type="button" icon="fas fa-search" class="ui-button-secondary" title="Buscar cliente" (click)="buscaCli()"></button>
            </div>
        </div>
        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Teléfono</label>
            </div>
            <div class="ui-g-10 ui-md-8 ui-lg-5">
                <input #telefono type="text" pInputText [(ngModel)]="entrada.telefono">
            </div>
        </div>
        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Tipo de visita</label>
            </div>
            <div class="ui-g-12 ui-md-8 ui-lg-5">
              <p-dropdown placeholder="Seleccione un tipo de visita" optionLabel="descripcion" dataKey="idCita" (onChange)="cambiaTipoVisita($event)"
                [style]="{ 'width': '90px', 'margin-right' : '10px'}" [options]="agenda.agendaCitas" [(ngModel)]="selectedCita">
             </p-dropdown>
            </div>

        </div>
        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Tratamiento</label>
            </div>
            <div class="ui-g-10 ui-md-8 ui-lg-7">
                <input #trata type="text" pInputText [(ngModel)]="entrada.tratamiento">
            </div>
            <div class="ui-g-2">
                <button pButton type="button" icon="fas fa-search" class="ui-button-secondary" title="Buscar tratamiento" (click)="buscaTrata()"></button>
            </div>
        </div>
        <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Inicio</label>
            </div>
            <div class="ui-g-12 ui-md-2">
                <p-calendar #desde (change)="cambiaDuracion()" (onClose)="cambiaDuracion()"  appendTo="body" [locale]='es' [style]="{'width':'65px'}"
                [inputStyle]="{'width':'65px', 'text-align':'center'}" [(ngModel)]="selectedDesde" [timeOnly]="true"></p-calendar>
            </div>
            <div class="ui-g-12 ui-md-1 divLabel">
                <label for="input">Fin</label>
            </div>
            <div class="ui-g-12 ui-md-2">
                  <p-calendar #hasta (onClose)="cambiaFin()" (change)="cambiaFin()" appendTo="body" [locale]='es' [style]="{'width':'65px'}"
                  [inputStyle]="{'width':'65px', 'text-align':'center'}" [(ngModel)]="selectedHasta" [timeOnly]="true"></p-calendar>
            </div>
            <div class="ui-g-12 ui-md-2 divLabel">
                <label for="input">Duración</label>
            </div>
            <div class="ui-g-12 ui-md-2" style="max-width: 65px;">
                <input (change)="cambiaDuracion()"  maxlength="3" type="text" pInputText pKeyFilter="int" [(ngModel)]="entrada.duracion" style="text-align: right">
            </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-2 ui-lg-2 divLabel">
                  <label for="input">Observaciones</label>
              </div>
              <div class="ui-g-12 ui-md-9 ui-lg-10">
                  <textarea rows="4" pInputTextarea [(ngModel)]="entrada.observaciones" ></textarea>
              </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-2 ui-lg-2 divLabel">
                <label for="input">Color</label>
            </div>
            <div class="ui-g-12 ui-md-9 ui-lg-2" style="max-width: 50px;">
                <p-colorPicker appendTo="body"  [(ngModel)]="entrada.color"></p-colorPicker>
            </div>
         </div>
         <div class="ui-g form-group" >
            <div class="ui-g-12 ui-md-2 divLabel">
              <label for="input">Estado</label>
            </div>
            <div class="ui-g-12 ui-md-8 ui-lg-5">
              <p-dropdown appendTo="body"  [style]="{ 'width': '90px', 'margin-right' : '10px'}" [options]="estados" [(ngModel)]="entrada.estado">
             </p-dropdown>
            </div>

        </div>



   </div>
   <p-footer>
       <button type="button" class="ui-button-secondary" pButton icon="pi pi-check" (click)="updateEvent()" label="Aceptar"></button>
       <button *ngIf="entrada.id>0" type="button" class="ui-button-secondary" pButton icon="fa fa-trash" (click)="deleteEvent(entrada.id)" title="Eliminar cita" label="Eliminar"></button>
       <button type="button" class="ui-button-secondary" pButton icon="fa fa-times" (click)="displayEvent=false" label="Cancelar"></button>
   </p-footer>
</p-dialog>


<!-- desplegable lateral para un nuevo tratamiento  -->
<p-sidebar [(visible)]="showSelTrata" [style]="{width:'45em'}" [baseZIndex]="10000">
    <h1 style="font-weight:normal">Seleccione un tratamiento</h1>
            <p-table #tm  [value]="tratamientosMaestros"
                      selectionMode="single"
                      [scrollable]="true"
                      scrollHeight="80vh"
                      [columns]="['id', 'tipo','descripcion']"
                      [globalFilterFields]="['tipo','descripcion']"
                      dataKey="id">
                    <ng-template pTemplate="caption">
                            <div>
                                <div style="text-align: left">
                                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="50" placeholder="Buscar..." (input)="tm.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                </div>
                            </div>
                        </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: left; width:90px;">Tipo</th>
                    <th style="text-align: left">Tratamiento</th>
                    <th style="text-align: right; width:80px;">Precio</th>
                </tr>
           </ng-template>
           <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" (click)="selTratamiento(item)" title="Seleccionar el tratamiento">
                    <td style="text-align: left; width:90px;">{{item.tipo}}</td>
                    <td>{{item.descripcion}}</td>
                    <td style="text-align: right;width:80px;">{{item.precio | number:'0.2-50':'es'}}</td>
                </tr>
            </ng-template>
        </p-table>
</p-sidebar>

<!-- desplegable lateral para un nuevo cliente  -->
<p-sidebar [(visible)]="showSelCliente" [style]="{width:'45em'}" [baseZIndex]="10000">
    <h1 style="font-weight:normal">Seleccione un paciente</h1>
            <p-table #tm  [value]="clientes"
                      selectionMode="single"
                      [scrollable]="true"
                      scrollHeight="80vh"
                      [columns]="['id', 'nombre','telefono']"
                      [globalFilterFields]="['id', 'nombre','telefono']"
                      dataKey="id">
                    <ng-template pTemplate="caption">
                            <div>
                                <div style="text-align: left">
                                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="50" placeholder="Buscar..." (input)="tm.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                </div>
                            </div>
                        </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: right; width:80px;">Código</th>
                    <th style="text-align: left">Nombre</th>
                    <th style="width:120px;">Teléfono</th>
                </tr>
           </ng-template>
           <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" (click)="selCliente(item)" title="Seleccionar el paciente">
                    <td style="text-align: right; width:80px;">{{item.id}}</td>
                    <td>{{item.nombre}}</td>
                    <td style="width:120px;">{{item.telefono}}</td>
                </tr>
            </ng-template>
        </p-table>
</p-sidebar>

