import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthorizatedGuard} from "./core/auth/authorizated.guard";
import { SchedulerComponent } from './modules/scheduler/scheduler.component';
import { AccountComponent } from './modules/account/account.component';
import { CartelComponent } from './modules/cartel/cartel.component';



const routes: Routes = [
  {path: '', component: CartelComponent},
  //{path: '', component: HomeComponent, canActivate: [ AuthorizatedGuard ]},
  {path: 'home', component: HomeComponent, canActivate: [ AuthorizatedGuard ]},
  {path: 'scheduler', component: SchedulerComponent, canActivate: [ AuthorizatedGuard ]},
  {path: 'account', component: AccountComponent, canActivate: [ AuthorizatedGuard ]},
  {path: 'login', component: LoginComponent},
  {path: 'cartel', component: CartelComponent},
  {
    path: 'config',
    loadChildren: () => import('./modules/config/config.module').then(m => m.ConfigModule),
  },
  {
    path: 'clientes',
    loadChildren: () => import('./modules/clientes/clientes.module').then(m => m.ClientesModule),
  },
  {
    path: 'tratamientos',
    loadChildren: () => import('./modules/tratamientos/tratamientos.module').then(m => m.TratamientosModule),
  },

  {
    path: 'productos',
    loadChildren: () => import('./modules/productos/productos.module').then(m => m.ProductosModule)
  },
  {
    path: 'bonos',
    loadChildren: () => import('./modules/bonos/bonos.module').then(m => m.BonosModule)
  },
  {
    path: 'informes',
    loadChildren: () => import('./modules/informes/informes.module').then(m => m.InformesModule)
  },

  //{ path: '**', component: HomeComponent, canActivate: [ AuthorizatedGuard ]}
  { path: '**', component: CartelComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
