export class LoginObjectDTO {
  public username: string;
  public password: string;
  public nif: string;
  public uuid:string='pc';
  public model:string;
  public platform:string;
  public recordar:boolean;

  constructor( object: any){
    this.username = (object.username) ? object.username : null;
    this.password = (object.password) ? object.password : null;
    this.nif = (object.nif) ? object.nif : null;
    this.recordar = (object.recordar) ? object.recordar : false;
    this.uuid = (object.uuid) ? object.uuid : 'pc';
  }
}
