<p-blockUI [blocked]="loader">
  <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
      <p-progressSpinner class="spin"></p-progressSpinner>
  </div>
</p-blockUI>
<p-confirmDialog [style]="{width: '50vw', maxWidth:'450px'}"></p-confirmDialog>
<p-toast key="tst" position="top-center" [style]="{marginTop: '35vh', width:'400px'}"></p-toast>



<div class="ui-fluid" [hidden]="status==0">
  <div class="ui-g">
      <div class="ui-lg-12" style="max-width: 800px;">
            <p class="migaPan">
                <a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>
                <a [routerLink]="['/clientes']">Clientes </a>
                <i class="fas fa-greater-than miguita"></i>
                <a [routerLink]="['/clientes/edit', intervencion.idCliente]">Cliente ({{intervencion.idCliente}})</a>
                <i class="fas fa-greater-than miguita"></i>
                <a [routerLink]="['/clientes/tratamiento', intervencion.idTratamiento]">Tratamiento ({{intervencion.idTratamiento}})</a>
                <i class="fas fa-greater-than miguita"></i>
                Intervención
            </p>
            <div class="card card-w-title">

                <app-headerbar
                    [titulo]="'Intervención'"
                    [status]="status"
                    (save)="save()"
                    (delete)="delete()"
                    (cancel)="cancel()">
                </app-headerbar>

                <app-errordata *ngIf="status<0"
                    [error]="txtError"
                    (reintentar)="reintentar()"
                ></app-errordata>
                <div [hidden]="status!=1" class="divSeccion" >

                    <div class="p-grid">
                        <div class="p-col-fixed" style="width: 55px;">
                            <div (click)="borrar=false" title="Dibujo activo" class="btn" [class.activo]="!borrar"><i class="fas fa-paint-brush fa-2x"></i></div>
                            <div (click)="borrar=true" title="Borrar" class="btn" [class.activo]="borrar"><i class="fas fa-eraser fa-2x"></i></div>
                            <div (click)="view_brushes.toggle($event)" title="Grosor de la linea" class="btn"><i class="fas fa-pencil-ruler fa-2x"></i></div>
                            <div (click)="view_colores.toggle($event)" [style.color]="arr_colores[currentColor].color" title="Color" class="btn"><i class="fas fa-palette fa-2x"></i></div>
                            <div (click)="desHacer($event)" title="Deshacer últimos trazos" class="btn"><i class="fas fa-undo fa-2x"></i></div>
                            <div (click)="canvas.cleanCanvas()" title="Eliminar todos los trazos" class="btn"><i class="fas fa-trash-alt fa-2x"></i></div>
                        </div>
                        <div class="p-col-fixed" style="width:45px;">
                            <div class="celda"></div>
                            <div class="celda"></div>
                            <div class="celda">{{arr_brushes[currentBrush].name}}</div>
                            <div class="celda">{{arr_colores[currentColor].acro}}</div>
                            <div class="celda"></div>
                            <div class="celda"></div>
                        </div>

                        <div class="p-col">
                            <div class="contenedor">
                                    <img style="width: 600px;" [src]="template">
                                    <app-canvas #canvas style="position: absolute; left:0;top:0;"
                                        [width]=600
                                        [height]=800
                                        [color]="arr_colores[currentColor].color"
                                        [brush]="arr_brushes[currentBrush].size"
                                        [borrar]="borrar"
                                    ></app-canvas>
                            </div>
                        </div>
                    </div>

                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">Fecha</label>
                        </div>
                        <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-4 ">
                          <p-calendar [inputStyle]="{'width':'125px'}" [(ngModel)]="fecha" [locale]="es" dateFormat="dd/mm/yy"  [selectOtherMonths]="true" [showIcon]="true" showButtonBar="true"></p-calendar>
                      </div>
                    </div>
                    <div class="ui-g form-group">
                            <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                              <label for="input">Doctor</label>
                            </div>
                            <div class="ui-g-12 ui-md-9 ui-lg-8">
                                <p-dropdown [style]="{'width':'250px'}" [options]="doctors" [(ngModel)]="currentDoctor"  placeholder="Seleccione un doctor" optionLabel="nombre" [showClear]="false"></p-dropdown>
                          </div>
                        </div>



                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                              <label for="input">Observaciones</label>
                          </div>
                          <div class="ui-g-12 ui-md-9 ui-lg-10">
                                <textarea [(ngModel)]="intervencion.observaciones" style="width: 100%;min-height:60px;" [rows]="3" pInputTextarea autoResize="true"></textarea>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div  [hidden]="status!=1" class="ui-dialog-buttonpane ui-helper-clearfix center" style="max-width: 800px; margin-bottom: 50px;">

    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-save" (click)="save()" label="Guardar" title="Guardar datos"></button>
    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-trash" (click)="delete()" title="Eliminar registro" label="Eliminar"></button>
    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-times" (click)="cancel()" label="Cancelar" title="Cancelar edición"></button>

</div>




<p-overlayPanel #view_colores [style]="{width:'20em'}">
    <p>Color de los trazos</p>
    <p-table  #t1 [value]="arr_colores" selectionMode="single"  [(selection)]="selectedColor">
    <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item"  title="Seleccione un color" (click)="setColor(item.name)">
            <td><i [style.color]="item.color" class="fas fa-circle"></i> <span class="itemName">{{item.name}}</span> </td>
        </tr>
    </ng-template>
</p-table>
</p-overlayPanel>

<p-overlayPanel #view_brushes [style]="{width:'20em'}">
        <p>Grosor del trazo</p>
        <p-table  #t2  [value]="arr_brushes" selectionMode="single" [(selection)]="selectedBrush">

        <ng-template pTemplate="body" let-item>
            <tr [pSelectableRow]="item" title="Seleccione un grososr" (click)="setBrush(item.name)">
                <td><i class="fas fa-circle" [style.font-size.px]="5+item.size" ></i> <span class="itemName">{{item.name}}</span> </td>
            </tr>
        </ng-template>
    </p-table>
    </p-overlayPanel>
