import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { productoDTO, listaproductoDTO, movalmacenDTO } from 'src/app/shared/model/productoDTO';


@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};
  
  constructor(private http:HttpClient, private auth:AuthService) { }


  getProductos(activos:boolean):Observable<listaproductoDTO[]>{
    if (activos)
      return this.http.get<productoDTO[]>(this.auth.endPoint + 'productos/activos'); 
    else
      return this.http.get<productoDTO[]>(this.auth.endPoint + 'productos'); 
  }

  getProducto(codigo:string):Observable<productoDTO>{
    return this.http.get<productoDTO>(this.auth.endPoint + 'productos/'+codigo); 
  }
  delProducto(codigo:string):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'productos/'+codigo, this.httpOptions);
  }
  getMovalmacen(codigo:string):Observable<any[]>{
    return this.http.get<any[]>(this.auth.endPoint + 'productos/movimientos/'+codigo+'/asc'); 
  }


  saveProducto(prod:productoDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'productos/', prod, this.httpOptions);
  }
  saveMovAlmacen(linea:movalmacenDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'productos/movalmacen', linea, this.httpOptions);
  }
  delMovAlmacen(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'productos/movalmacen/'+id, this.httpOptions);
  }



}
