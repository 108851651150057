import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DatosService } from 'src/app/core/services/datos.service';
import { TratamientosService } from 'src/app/core/services/tratamientos.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private tratamientosService:TratamientosService) { }

  ngOnInit() {
        
  }

  ngAfterViewInit(){

  }


}
