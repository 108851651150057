<div class="topbar">
    <a routerLink="/" class="topbar-logo">
        <!-- <img [attr.src]="app.layout === 'default' ? 'assets/layout/images/logo-black.png' : 'assets/layout/images/logo-white.png'" style="border: 0 none">-->
       <img  src='assets/logo-erpclinic.png' style="border: 0 none">
    </a>

    <a href="#" id="menu-button" (click)="app.onMenuButtonClick($event)">
        <i class="fa fa-bars"></i>
    </a>

    <a href="#" id="user-display" (click)="app.onTopbarMenuButtonClick($event)">
        <i *ngIf="doctor" style="font-size: 22px; padding-right: 10px;" class="fas fa-user-md"></i>
        <i *ngIf="!doctor" style="font-size: 22px; padding-right: 10px;" class="fas fa-user"></i>
        <span class="username" style="font-size: 16px;">{{nombre}}</span>

        <i  class="fa fa-angle-down"></i>

    </a>


    <!--
    <label for="topbar-search">
        <i class="fa fa-search topbar-search-icon"></i>
    </label>
    <input type="text" class="topbar-search" placeholder="Type your search here" id="topbar-search" />
-->
    <ul id="topbar-menu" class="fadeInDown" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}">

        <li role="menuitem">
            <a href="#" (click)="app.verPerfil($event)">
                    <i class="fa fa-fw fa-user"></i>
                <span>Perfil de usuario</span>
            </a>
        </li>

        <li role="menuitem">
            <a href="#" (click)="app.logOut($event)">
                <i class="fas fa-sign-out-alt"></i>
                <span>Log out</span>
            </a>
        </li>

        <!--
        <li #profile [ngClass]="{'menuitem-active':app.activeTopbarItem === profile}">
            <a href="#" (click)="app.onTopbarItemClick($event, profile)">
                <i class="topbar-icon fa fa-fw fa-user"></i>
                <span class="topbar-item-name">Profile</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-user"></i>
                        <span>Profile</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-user-secret"></i>
                        <span>Privacy</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-cog"></i>
                        <span>Settings</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-sign-out"></i>
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #settings [ngClass]="{'menuitem-active':app.activeTopbarItem === settings}">
            <a href="#" (click)="app.onTopbarItemClick($event, settings)">
                <i class="topbar-icon fa fa-fw fa-cog"></i>
                <span class="topbar-item-name">Settings</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-paint-brush"></i>
                        <span>Change Theme</span>
                        <span class="topbar-badge">1</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-star-o"></i>
                        <span>Favorites</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-lock"></i>
                        <span>Lock Screen</span>
                        <span class="topbar-badge">3</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-picture-o"></i>
                        <span>Wallpaper</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #messages [ngClass]="{'menuitem-active':app.activeTopbarItem === messages}">
            <a href="#" (click)="app.onTopbarItemClick($event, messages)">
                <i class="topbar-icon material-icons animated swing fa fa-fw fa-envelope-o"></i>
                <span class="topbar-item-name">Messages</span>
                <span class="topbar-badge animated rubberBand">4</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar1.png" width="35">
                        <span>Give me a call</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="35">
                        <span>Reports attached</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar3.png" width="35">
                        <span>About your invoice</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar2.png" width="35">
                        <span>Meeting today</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                        <img src="assets/layout/images/avatar4.png" width="35">
                        <span>Out of office</span>
                    </a>
                </li>
            </ul>
        </li>
        <li #notifications [ngClass]="{'menuitem-active':app.activeTopbarItem === notifications}">
            <a href="#" (click)="app.onTopbarItemClick($event, notifications)">
                <i class="topbar-icon fa fa-fw fa-bell-o"></i>
                <span class="topbar-item-name">Notifications</span>
                <span class="topbar-badge animated rubberBand">2</span>
            </a>
            <ul>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-tasks"></i>
                        <span>Pending tasks</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-calendar-check-o"></i>
                        <span>Meeting today</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-download"></i>
                        <span>Download</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a href="#" (click)="app.onTopbarSubItemClick($event)">
                        <i class="fa fa-fw fa-plane"></i>
                        <span>Book flight</span>
                    </a>
                </li>
            </ul>
        </li>
-->

    </ul>

</div>
