import { Injectable, ɵConsole } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { userDTO } from 'src/app/shared/model/userDTO';
import { stat } from 'fs';

@Injectable()
export class AuthorizatedGuard implements CanActivate {
  constructor(private router: Router,
              private auth: AuthService) { }



  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    

    this.auth.setCurrentPage(state.url);
    
    if (!this.auth.isAuthenticated() || this.auth.estaCaducado()) {
      this.auth.removeCurrentSession();
      this.router.navigate(['/login']);
      return false;
    }

    if (route.data.role){
      if (!this.tienePermiso(route.data.role)){
        this.router.navigate(['/login']);
        return false;
      }
    }

    if (this.auth.isAuthenticated()) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }


  tienePermiso(role){
     let user:userDTO=this.auth.getCurrentUser();
     let p=false; 
    switch (role){
      case 'config':
          p= user.permisos.config>0;
          break;
      case 'clientes':
          p= user.permisos.clientes>0;
          break;
      case 'historial':
          p= user.permisos.historial>0;
          break;
      case 'tratamientos':
          p= user.permisos.tratamientos>0;
          break;
      case 'productos':
          p= user.permisos.productos>0;
          break;
      case 'agenda':
          p= user.permisos.agenda>0;
          break;
      default:
        p=false;
      }

      return p;
  }

}
