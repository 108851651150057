import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private httpOptions: any =  { headers: new HttpHeaders()};
  constructor(private http:HttpClient, private auth:AuthService) { }


  postFile(tipo: string, fileToUpload: FileList, idCliente:number): Observable<any> {

    const formData: FormData = new FormData();

    formData.append('tipo', tipo);
    formData.append('idCliente', idCliente.toString());
    formData.append('file', fileToUpload[0], fileToUpload[0].name);

    return this.http.post<any>(this.auth.endPoint + 'fileUpload', formData, this.httpOptions);

  }




  createBase64(tipo: string, fileToUpload: FileList): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('tipo', tipo);
    formData.append('file', fileToUpload[0], fileToUpload[0].name);
    return this.http.post<any>(this.auth.endPoint + 'fileUpload/convert', formData, this.httpOptions);

  }



}
