export class intervencionDTO {
    id: number=0;
    idTratamiento: number;
    idCliente: number;
    fecha:string=new Date().toISOString().slice(0,10);
    idDoctor:number;
    template:string;
    trazos:string;
    observaciones:string;

}

export class intervencionCompletaDTO {
    intervencion: intervencionDTO;
    image: any;
}
