<p-blockUI [baseZIndex]="1300" [blocked]="loader">
    <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
        <p-progressSpinner class="spin"></p-progressSpinner>
    </div>
</p-blockUI>
<p-confirmDialog [baseZIndex]="200" [style]="{width: '50vw'}"></p-confirmDialog>
<p-toast [baseZIndex]="300" key="tst" position="center" [style]="{ 'width': '90vw', 'maxWidth': '500px' }"></p-toast>

<div class="ui-fluid">
    <div class="ui-g">
        <div class="ui-g-12 ui-md-12 ui-xl-12" style="max-width: 1000px;">
            <div class="card card-w-title">
                    <p class="migaPan">
                            <a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>
                            Usuario <span *ngIf="user.id==0">(nuevo)</span>
                    </p>
                    <div class="card card-w-title">
                        <app-headerbar
                                [titulo]="user.id==0 ? 'Ficha de usuario (nuevo)': 'Ficha de usuario ('+user.id+')'"
                                [status]="status"
                                (save)="save()"
                                (delete)="delete()"
                                (cancel)="cancel()">
                        </app-headerbar>

                        <app-errordata *ngIf="status<0"
                                [error]="txtError"
                                (reintentar)="reintentar()">
                        </app-errordata>

                        <div>

                            <div class="ui-g form-group">
                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                    <label for="input">Login</label>
                                </div>
                                <div class="ui-g-12 ui-md-9" style="max-width: 150px">
                                    <input [disabled]="user.login=='admin'" #login type="text" pInputText [(ngModel)]="user.login">
                                </div>
                            </div>
                            <div class="ui-g form-group">
                                    <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                        <label for="input">Password</label>
                                    </div>

                                    <div class="ui-g-12 ui-md-3 ui-lg-2" style="max-width: 150px">
                                        <button *ngIf="user.id>0" type="button" class="ui-button-secondary" pButton icon="fa fa-unlock-alt" (click)="openChangePass()" label="Cambiar" title="Cambiar contraseña"></button>
                                        <input  *ngIf="user.id==0" type="text" pInputText [(ngModel)]="user.login">
                                    </div>
                                    <div *ngIf="user.id==0" class="ui-g-12 ui-md-3 ui-lg-1 divLabel">
                                            <label for="input">Repetir</label>
                                        </div>
                                        <div *ngIf="user.id==0" class="ui-g-12 ui-md-3 ui-lg-2" style="max-width: 150px">
                                            <input #login type="text" pInputText [(ngModel)]="user.login">
                                        </div>
                                </div>
                            <div class="ui-g form-group">
                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                    <label for="input">Nombre</label>
                                </div>
                                <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                    <input #nombre type="text" pInputText [(ngModel)]="user.nombre">
                                </div>
                            </div>
                            <div class="ui-g form-group">
                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                    <label for="input">E-mail</label>
                                </div>
                                <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                    <input  type="text" pInputText [(ngModel)]="user.email">
                                </div>
                            </div>


                            <div class="ui-g form-group" style="margin-bottom: 30px">
                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel" style="margin-top: 0">
                                    <label for="input">Cancelado</label>
                                </div>
                                <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 ">
                                    <p-checkbox name="groupname" value="val1" binary="true" [(ngModel)]="user.baja"></p-checkbox>
                                </div>
                            </div>

                            <p-tabView #solapas (onChange)="changeSolapa($event)">

                                <p-tabPanel header="Datos personales" leftIcon="fas fa-address-card fa-2x">
                                    <h1>Datos personales</h1>

                                    <div class="contenido">
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">NIF</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-9" style="max-width: 130px">
                                                    <input type="text" pInputText [(ngModel)]="user.nif">
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Dirección</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                                    <input type="text" pInputText [(ngModel)]="user.direccion">
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Código postal</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-9" style="max-width: 130px">
                                                    <input type="text" pInputText [(ngModel)]="user.cp">
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Poblacion</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                                    <input type="text" pInputText [(ngModel)]="user.poblacion">
                                                </div>
                                        </div>
                                    </div>
                                </p-tabPanel>

                                <p-tabPanel header="Especialidad" leftIcon="fas fa-stethoscope fa-2x">
                                    <h1>Espcialista/doctor</h1>

                                    <div class="contenido">
                                            <div class="ui-g form-group">
                                                    <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel" style="margin-top: 0">
                                                        <label for="input">Especialista</label>
                                                    </div>
                                                    <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 ">
                                                        <p-checkbox name="groupname" value="val1" binary="true" [(ngModel)]="user.doctor"></p-checkbox>
                                                    </div>
                                                </div>
                                            <div class="ui-g form-group">
                                                    <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                        <label for="input">Especialidad</label>
                                                    </div>
                                                    <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                                        <input type="text" pInputText [(ngModel)]="user.especialidad">
                                                    </div>
                                            </div>
                                            <div class="ui-g form-group">
                                                    <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                        <label for="input">Tratamiento</label>
                                                    </div>
                                                    <div class="ui-g-12 ui-md-9" style="max-width: 350px">
                                                        <input type="text" pInputText [(ngModel)]="user.tratamiento">
                                                    </div>
                                            </div>
                                            <div class="ui-g form-group">
                                                    <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                        <label for="input">Firma<br> (300 x 150)</label>
                                                    </div>
                                                    <div class="ui-g-12 ui-md-9">
                                                            <div class="firma">
                                                                    <img *ngIf="user.firma !=null && user.firma!=''" [src]="user.firma">
                                                                </div>
                                                                <div class="botonera">
                                                                        <p-fileUpload   name="myfile[]"
                                                                                        mode="basic"
                                                                                        (onSelect)="selectFile($event)"
                                                                                        [auto]="true"
                                                                                        chooseLabel="Seleccionar" >
                                                                        </p-fileUpload>
                                                                </div>
                                                    </div>
                                            </div>
                                    </div>
                                </p-tabPanel>
                                <!--
                                <p-tabPanel header="Agenda" leftIcon="fas fa-calendar-alt fa-2x">
                                    <h1>Agenda</h1>
                                    <div class="contenido">
                                        <div class="p-grid">
                                            <div class="p-col-fixed" style="width:250px">
                                                    <p-listbox [options]="calendars" (onChange)="changeCalendar()" [dataKey]="idAgenda" optionLabel="nombre"  [(ngModel)]="selectedCalendars" multiple="multiple" checkbox="checkbox">
                                                            <p-header>
                                                                   Agendas
                                                            </p-header>
                                                            <ng-template let-calen let-i="index" pTemplate="item">
                                                                <i [style.color]="getColor(i)" class="fas fa-square"></i> {{getName(i)}}
                                                            </ng-template>

                                                    </p-listbox>

                                            </div>
                                            <div class="p-col" style="position: relative">
                                                    <div *ngIf="cargando" class="cargando"><p-progressSpinner></p-progressSpinner></div>

                                                    <full-calendar
                                                        #calen
                                                        deepChangeDetection="true"
                                                        schedulerLicenseKey= '0090342599-fcs-1573548059'
                                                        defaultView="dayGridMonth"
                                                        weekNumberCalculation="ISO"
                                                        [plugins]="calendarPlugins"
                                                        [events]="calendarEvents"
                                                        [header]="{left: 'prev,next today',center: 'title',right: 'dayGridMonth,timeGridWeek'}"
                                                        [buttonText]="{today:'hoy',month:'mes',week:'semana',day:'dia',list:'lista'}"
                                                        [slotLabelFormat]="{hour: 'numeric',minute: '2-digit',omitZeroMinute: false,meridiem: 'short'}"
                                                        slotDuration='00:30:00'
                                                        slotLabelInterval='01:00:00'
                                                        [minTime]= 'inicio'
                                                        [maxTime]= 'fin'
                                                        locale="es"
                                                        [validRange]="{start: f1,end: f2}"
                                                        contentHeight="auto"
                                                        (dateClick)="handleDateClick($event)">
                                                    </full-calendar>

                                            </div>
                                        </div>



                                    </div>
                                </p-tabPanel>
                                -->
                                <p-tabPanel header="Permisos" leftIcon="fas fa-user-lock fa-2x">
                                    <h1>Permisos de acceso</h1>
                                    <div class="contenido">
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">config</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.config"  [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                        <!--
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Agenda</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.agenda" [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                        -->
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Produtos</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.productos" [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Clientes</label>
                                                </div>

                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.clientes" [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Clientes-Historial</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.historial" [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                        <div class="ui-g form-group">
                                                <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                                                    <label for="input">Clientes-Tratamientos</label>
                                                </div>
                                                <div class="ui-g-12 ui-md-8" style="max-width: 300px">
                                                    <p-selectButton [disabled]="user.permisos.config!=2" [(ngModel)]="user.permisos.tratamientos" [options]="types"></p-selectButton>
                                                </div>
                                        </div>
                                    </div>
                                </p-tabPanel>

                            </p-tabView>
                        </div>
                    </div>
                </div>
        </div>
</div>
</div>
    <div *ngIf="status==1" class="ui-dialog-buttonpane ui-helper-clearfix center" style="max-width: 1000px;">
        <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-save" (click)="save()" label="Guardar" title="Guardar datos"></button>
        <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-trash" (click)="delete()" title="Eliminar registro" label="Eliminar"></button>
        <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-times" (click)="cancel()" label="Cancelar" title="Cancelar edición"></button>
    </div>





<!-- Formualario de edición de una semana -->
<p-dialog header="Agenda: {{selectedAgenda}}   -  {{rangos[0].label}}" [(visible)]="displayEditSemana" [modal]="true" [style]="{width: '600px'}" [baseZIndex]="100">
        <div style="width: 600px">
            <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-4 ui-lg-3 divLabel">
                      <label for="input">Selección</label>
                </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 165px;">
                      <p-dropdown #rango [options]="rangos" [(ngModel)]="selectedRango"></p-dropdown>
                </div>
            </div>
            <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-4 ui-lg-3" style="text-align: right">
                      <label for="input">Festivo</label>
                </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 165px;">
                    <p-checkbox value="val1" binary="true" [(ngModel)]="selectedDiaAgenda.festivo"></p-checkbox>
                </div>
            </div>

            <div class="ui-g form-group">
                <div class="ui-g-12 ui-md-4 ui-lg-3 divLabel">
                      <label><strong>Mañanas:</strong> Desde</label>
                </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 85px;">
                    <p-calendar [locale]='es'  [disabled]="selectedDiaAgenda.festivo"  [style]="{'width':'80px'}" [inputStyle]="{'width':'80px'}" [(ngModel)]="selectedDesde1" [timeOnly]="true"></p-calendar>
                </div>
                <div class="ui-g-12 ui-md-4 ui-lg-2 divLabel">
                    <label>hasta</label>
              </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 85px;">
                    <p-calendar [locale]='es' [disabled]="selectedDiaAgenda.festivo"  [style]="{'width':'80px'}" [inputStyle]="{'width':'80px'}" [(ngModel)]="selectedHasta1" [timeOnly]="true"></p-calendar>
                </div>

            </div>
            <div class="ui-g form-group" style="margin-bottom: 100px;">
                <div class="ui-g-12 ui-md-4 ui-lg-3 divLabel">
                      <label><strong>Tardes:</strong> Desde</label>
                </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 85px;">
                    <p-calendar [locale]='es'  [disabled]="selectedDiaAgenda.festivo"  [style]="{'width':'80px'}" [inputStyle]="{'width':'80px'}" [(ngModel)]="selectedDesde2" [timeOnly]="true"></p-calendar>
                </div>
                <div class="ui-g-12 ui-md-4 ui-lg-2 divLabel">
                    <label>hasta</label>
              </div>
                <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-3" style="max-width: 85px;">
                    <p-calendar [locale]='es'  [disabled]="selectedDiaAgenda.festivo"  [style]="{'width':'80px'}" [inputStyle]="{'width':'80px'}" [(ngModel)]="selectedHasta2" [timeOnly]="true"></p-calendar>
                </div>

            </div>


       </div>
       <p-footer>
           <button type="button" class="ui-button-secondary" pButton icon="pi pi-check" (click)="saveDia()" label="Aceptar"></button>
           <button type="button" class="ui-button-secondary" pButton icon="fa fa-times" (click)="displayEditSemana=false" label="Cancelar"></button>
       </p-footer>
</p-dialog>





<!-- Formulario de cambio de password -->
<p-dialog header="Cambio de contraseña"  [(visible)]="displayChangePass" [modal]="true" [style]="{width: '600px'}">
    <div style="width: 550px;">

        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-5 divLabel">
                <label for="input">Contraseña actual</label>
            </div>
            <div class="ui-g-12 ui-md-4">
                <input style="width: 100%" type="password" pInputText [(ngModel)]="passActual">
            </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-5 divLabel">
                <label for="input">Contraseña nueva</label>
            </div>
            <div class="ui-g-12 ui-md-4">
                <input style="width: 100%" type="password" pInputText [(ngModel)]="passNuevo1">
            </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-5 divLabel">
                <label for="input">Confirmar contraseña</label>
            </div>
            <div class="ui-g-12 ui-md-4">
                <input style="width: 100%" type="password" pInputText [(ngModel)]="passNuevo2">
            </div>
        </div>
        <div class="ui-g form-group">
            <div class="error">{{txtError}}</div>
        </div>

    </div>

   <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="changePass()" label="Aceptar" title="Modificar contraseña" class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-times" (click)="displayChangePass=false" label="Cancelar" title="Cerrar formulario" class="ui-button-secondary"></button>
 </p-footer>
</p-dialog>



