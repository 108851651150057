export class pagoDTO {
    id: number=0;
    idTratamiento: number=0;
    idCliente: number=0;
    fecha:string=new Date().toISOString().slice(0,10); 
    descripcion: string='';
    importe:number=0;
    formaPago:number=0;
}

