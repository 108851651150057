import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BonoDTO, tratamientosSeleccionablesBonoDTO, BonoCliDTO } from '../../shared/model/bonoDTO';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BonosService {
  public endpoint: string = '';
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};

  constructor(private http:HttpClient, private auth:AuthService) { 
    
  }



  getBonos(soloActivos:boolean):Observable<BonoDTO[]>{
    let strApi=soloActivos ? 'bonos' : 'bonos/activos';
    return this.http.get<BonoDTO[]>(this.auth.endPoint + strApi).pipe(timeout(8000));;
  }

  getBono(id:number):Observable<BonoDTO>{
    return this.http.get<BonoDTO>(this.auth.endPoint + 'bonos/'+id).pipe(timeout(8000));;
  }

  delBono(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'bonos/'+id, this.httpOptions).pipe(timeout(8000));;
  }

  getDisponibilidadBono(id:number):Observable<tratamientosSeleccionablesBonoDTO[]>{
    return this.http.get<tratamientosSeleccionablesBonoDTO[]>(this.auth.endPoint + 'bonosCli/disponibilidad/'+id).pipe(timeout(8000));;
  }


  saveBono(datos:BonoDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'bonos', datos,this.httpOptions).pipe(timeout(8000));;
  }



  saveBonoCli(datos:BonoCliDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'bonosCli', datos,this.httpOptions).pipe(timeout(8000));;
  }

  delBonoCli(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'bonosCli/'+id, this.httpOptions).pipe(timeout(8000));;
  }



}
