

export class BonoDTO {
    id: number=0;
    descripcion: string='';
    sesiones:number;
    precio:number;
    baja:boolean=false;
    validez:number=0;
    enUso:boolean=false;
    tratamientosBono:tratamientosBonoDTO[];
}

class tratamientosBonoDTO{
    idBono: number=0;
    idTratamiento: number=0;
    descripcion: string='';
    sesiones: number=0;
}

export class tratamientosSeleccionablesBonoDTO{
    idBonoCli: number=0;
    idTratamiento: number=0;
    descripcion: string='';
    usados: number=0;
}

export class BonoCliDTO {
    id: number=0;
    idCliente: number=0;
    fecha:string=new Date().toISOString().slice(0,10); 
    caduca:string;
    idBono:number=0;
    descripcion: string=null;
    precio:number=0;
    baja:number=0;
    sesiones:number=0;
    pagado:boolean=false;
    
}

