import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MockBackend} from "@angular/http/testing";
import {BaseRequestOptions} from "@angular/http";

import {AuthorizatedGuard} from "./auth/authorizated.guard";
import { GlobalService } from './services/global.service';
import { AuthService } from './auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { TratamientosService } from './services/tratamientos.service';


@NgModule({
  declarations: [  ],
  imports: [],
  providers: [
    AuthService,
    AuthorizatedGuard,
    MockBackend,
    BaseRequestOptions,
    GlobalService,
    TratamientosService,

  ],
  bootstrap: []
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule, private http:HttpClient, private auth:AuthService) {

    /*
    this.http.get<any>('../../../assets/data/config.json').subscribe(
      (data)=>{
        this.auth.endPoint=data.server_address;
    });
    */


  if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }


  }
}
