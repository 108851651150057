import { getLocaleDayPeriods } from '@angular/common';

export class lineaTratamientoDTO {
    id: number=0;
    idTratamiento: number;
    idCliente: number;
    tratamiento: number;
    tipo:string='';
    descripcion:string='';
    precio:number=0;
    fecha:string=new Date().toISOString().slice(0,10); 
    firmado:boolean=false;
    idBono:number;
    idDoctor:number;
}


export class lineaTablaTratamientoDTO {
    id: number=0;
    fecha:string=new Date().toISOString().slice(0,10); 
    tratamientos: string='' ;
    productos: string='' ;
    fechaRevision:string=new Date().toISOString().slice(0,10); 
    revision: string='' ;
    importe:number=0;
    
}

