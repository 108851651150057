import {Component, AfterViewInit, Input, OnInit, OnDestroy, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/core/auth/auth.service';
import { userDTO } from 'src/app/shared/model/userDTO';
import { MenuItem, ScrollPanel } from 'primeng';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() reset: boolean;

    model: any[];

    @ViewChild('layoutMenuScroller', {static: true}) layoutMenuScrollerViewChild: ScrollPanel;

    constructor(public app: AppComponent, private authService: AuthService ) {}

    ngOnInit() {

        let user:userDTO = this.authService.getCurrentUser();

        /*
        this.model = [
            {label: 'Home', icon: 'fa fa-fw fa-home', routerLink: ['/home']},
            {
                label: 'Configuración', icon: 'fa fa-fw fa-sitemap', badge: '4', badgeStyleClass: 'orange-badge',
                items: [
                    {label: 'General', icon: 'fas fa-tools', routerLink: ['/config']},
                    {label: 'Usuarios', icon: 'fas fa-user', routerLink: ['/config/users']},
                    {label: 'Agendas', icon: 'fas fa-calendar-alt', routerLink: ['/config/agendas']},
                    {label: 'Tratamientos', icon: 'fas fa-user-md', routerLink: ['/tratamientos']},
                    {label: 'Bonos', icon: 'fas fa-credit-card', routerLink: ['/bonos']},
                ]
            },
            {label: 'Agenda', icon: 'fa fa-fw fa-calendar-alt', routerLink: ['/scheduler']},
            {label: 'Clientes', icon: 'fa fa-fw fa-users', routerLink: ['/clientes']},
            {label: 'Productos', icon: 'fas fa-fw fa-syringe', routerLink: ['/productos']},


        ];
        */
       this.model = [{label: 'Home', icon: 'fa fa-fw fa-home', routerLink: ['/home']}];
        if (user.permisos.config>0){
            this.model.push({
                label: 'Configuración', icon: 'fa fa-fw fa-sitemap', badge: '5', badgeStyleClass: 'orange-badge',
                items: [
                    {label: 'General', icon: 'fas fa-tools', routerLink: ['/config']},
                    {label: 'Usuarios', icon: 'fas fa-user', routerLink: ['/config/users']},
                    //{label: 'Agendas', icon: 'fas fa-calendar-alt', routerLink: ['/config/agendas']},
                    {label: 'Tratamientos', icon: 'fas fa-user-md', routerLink: ['/tratamientos']},
                    {label: 'Bonos', icon: 'fas fa-credit-card', routerLink: ['/bonos']},
                ]
            });
        }


        if (user.permisos.agenda>0){
            //this.model.push({label: 'Agenda', icon: 'fa fa-fw fa-calendar-alt', routerLink: ['/scheduler']});
        }
        if (user.permisos.clientes>0){
            this.model.push({label: 'Clientes', icon: 'fa fa-fw fa-users', routerLink: ['/clientes']});
        }
        if (user.permisos.productos>0){
            this.model.push({label: 'Productos', icon: 'fas fa-fw fa-syringe', routerLink: ['/productos']});
        }


        if (user.permisos.informes>0){
            this.model.push({
                label: 'Informes', icon: 'fas fa-chart-line', badge: '5', badgeStyleClass: 'purple-badge',
                items: [
                    {label: 'Pendiente de pago', icon: 'fas fa-cash-register', routerLink: ['/informes/pendiente']},
                    {label: 'Ingresos', icon: 'fas fa-file-invoice-dollar', routerLink: ['/informes/ingresos']},
                    {label: 'Tratamientos', icon: 'fas fa-stethoscope', routerLink: ['/informes/tratas']},
                    {label: 'Productos', icon: 'fas fa-syringe', routerLink: ['/informes/prods']},
                ]
            });
        }



        this.changeLayout('smoke');
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        themeLink.href = 'assets/theme/theme-' + theme + '.css';
    }

    changeLayout(layout) {

        this.app.layout = layout;
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');
        layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
    }

    ngAfterViewInit() {
      setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    onWrapperClick(event: Event) {
        this.app.onMenuClick(event);
        setTimeout(() => {
          this.layoutMenuScrollerViewChild.moveBar();
        }, 450);
    }

    ngOnDestroy() {
    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                   (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child.items"></i>
                </a>

                <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
                    [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
                    (mouseenter)="onMouseEnter(i)">
                    <i [ngClass]="child.icon"></i>
                    <span>{{child.label}}</span>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                    <i class="fa fa-fw fa-angle-down" *ngIf="child.items"></i>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">{{child.label}}</div>
                </div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                    [@children]="app.slimMenu&&root ? isActive(i) ? 'visible' : 'hidden' : isActive(i) ?
                    'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*'
            })),
            state('hidden', style({
                height: '0px'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _parentActive: boolean;

    _reset: boolean;

    activeIndex: number;

    constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) {}

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        if (item.routerLink || item.items) {
            this.activeIndex = (this.activeIndex === index) ? null : index;
        }

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item: item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
              this.appMenu.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.overlayMenu || this.app.isMobile()) {
                this.app.overlayMenuActive = false;
                this.app.mobileMenuActive = false;
            }

            if (!this.root && this.app.slimMenu) {
                this.app.resetSlim = true;
            }

            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && this.app.slimMenu) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    unsubscribe(item: any) {
        if (item.eventEmitter) {
            item.eventEmitter.unsubscribe();
        }

        if (item.items) {
            for (const childItem of item.items) {
                this.unsubscribe(childItem);
            }
        }
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && this.app.slimMenu) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
