
<div *ngIf="!isLogged()">
    <router-outlet></router-outlet>
</div>

<div *ngIf="isLogged()" class="layout-wrapper" (click)="onLayoutClick()"
    [ngClass]="{'layout-menu-slim': slimMenu, 
                'layout-menu-overlay': overlayMenu, 
                'layout-menu-static': staticMenu,
                'layout-menu-static-inactive': staticMenuInactive,
                'layout-menu-overlay-active': overlayMenuActive,
                'layout-menu-active': mobileMenuActive}">
    
    <app-topbar></app-topbar>

    <div class="layout-main">
        <app-menu [reset]="resetSlim"></app-menu>
        
        <div class="layout-content">
            <p-progressBar *ngIf="loading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>
        
        <app-footer></app-footer>
    </div>     
    
    <div class="layout-mask" *ngIf="mobileMenuActive"></div>   
</div>
