import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginObjectDTO } from '../../shared/model/loginObjectDTO';
import { sessionDTO } from '../../shared/model/sessionDTO';
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import { userDTO } from 'src/app/shared/model/userDTO';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public  endPoint= environment.endPoint;



  private localStorageService;
  public  currentSession : sessionDTO = null;
  private httpOptions: any =  {headers: new HttpHeaders({'Content-Type':  'application/json'})};

  public currentPage:string='';
  public lastPage:string='';


  constructor(private http:HttpClient, private router:Router ) {

    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }



  login(loginObj: LoginObjectDTO): Observable<any> {

    return this.http.post<sessionDTO>(this.endPoint + 'login/authenticate', loginObj, this.httpOptions).pipe(timeout(15000));
  }

/*
  inicializar(){
    return this.http.get<any>('../../../assets/data/config.json').subscribe(
      (data)=>{
        this.endPoint=data.server_address;

      }
    );
  }

*/



  refreshToken(): Observable<any> {


    const token=this.getCurrentToken();
    const tk=this.getCurrentTokenRefresh();

    if (token && !this.estaCaducado() && tk){
        let param={tokenRefresh:tk};
        return this.http.post<sessionDTO>(this.endPoint + 'login/refresh', param, this.httpOptions);
    }


    this.removeCurrentSession();
    return observableThrowError("");
    return null;
  }

  getCurrentTokenRefresh(): string {
    var session = this.getCurrentSession();
    return (session && session.tokenRefresh) ? session.tokenRefresh : null;
  };


  caducidad():number{
    const token=this.getCurrentToken()
    if (token){
      const ahora = Date.now();
      const tokenDecoded=this.getDecodedAccessToken(token);
      const caducado = Math.trunc(ahora/1000)-tokenDecoded.exp;

      return caducado;
    }
    return 10000;

  }

  setCurrentPage(url:string){
    this.lastPage=this.currentPage;
    this.currentPage=url;
  }

  setCurrentSession(session: sessionDTO): void {
    this.currentSession = session;
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }
  loadSessionData(): sessionDTO{
    var sessionStr = this.localStorageService.getItem('currentUser');
    return (sessionStr) ? <sessionDTO> JSON.parse(sessionStr) : null;
  }
  getCurrentSession(): sessionDTO {
    return this.currentSession;
  }
  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.currentSession = null;
  }
  getCurrentUser(): userDTO {
    var session: sessionDTO = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };
  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  estaCaducado():boolean{
    return(this.caducidad()>900);
  }


  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  };
  logout(): void{
    this.removeCurrentSession();
    this.router.navigate(['/login']);
  }

  getDecodedAccessToken(token: string): any {
    try{
        return jwt_decode(token);
    }
    catch(Error){
        return null;
    }
  }






}
