import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { clienteDTO } from '../../shared/model/clienteDTO';

import { doctorDTO } from '../../shared/model/doctorDTO';
import { lineaTratamientoDTO } from '../../shared/model/lineaTratamientoDTO';
import { intervencionDTO } from '../../shared/model/intervencionDTO';

import { docDTO } from '../../shared/model/docDTO';
import { consentimientoTrataDTO } from '../../shared/model/consentimientoTrataDTO';

import { productoDTO } from '../../shared/model/productoDTO';
import { AuthService } from '../auth/auth.service';
import { tratamientoCliDTO } from 'src/app/shared/model/tratamientoDTO';


@Injectable({
  providedIn: 'root'
})
export class DatosService {

  public tratamientos:tratamientoCliDTO[];
  public doctores:doctorDTO[]=[];
  public tmpNombreCli='';

  public endpoint: string = '';

  public currectDoctor=new doctorDTO;

  private httpOptions: any =  {
        headers: new HttpHeaders({'Content-Type':  'application/json'})
  };


  constructor(private http:HttpClient, private auth:AuthService) {

    this.inicializar();

  }

  inicializar(){

    return this.http.get<any>('assets/data/config.json').subscribe(
      (data)=>{
        this.endpoint=data.server_address;
      }
    );



  }

  obtenerDoctor(val){
    this.getDoctor(val).subscribe(
      (data) => {
          this.currectDoctor = data;
          if (val == 0){
            localStorage.setItem('currentDoctor', data.id.toString());
          }
      }
    );
  }


  saveEndPoint(value){
    this.endpoint=value;
    localStorage.setItem('endPoint', value);

  }

  setCurrentDoctor(id):Observable<doctorDTO>{
    localStorage.setItem('currentDoctor', id.toString());
    let result=this.getDoctor(id);
    result.subscribe((doc)=>{
      this.currectDoctor=doc;
    })
    return result;
  }

  /**** clientes  */
  getClientes():Observable<clienteDTO[]>{
    return this.http.get<clienteDTO[]>(this.endpoint + 'api/clientes.php');
  }

  getCliente(idCliente:number):Observable<clienteDTO>;
  getCliente(idCliente:number,  idTratamiento:number):Observable<clienteDTO>;
  getCliente(idCliente:number,  idTratamiento:number, idIntervencion:number):Observable<clienteDTO>;

  getCliente(idCliente:number,  idTratamiento?:number,  idIntervencion?:number ):Observable<clienteDTO>{
    if (idTratamiento && idIntervencion)
      return this.http.get<any>(this.endpoint + 'api/clientes.php?idCliente='+idCliente+'&idTratamiento='+idTratamiento+'&idIntervencion='+idIntervencion);
    else if (idTratamiento)
      return this.http.get<any>(this.endpoint + 'api/clientes.php?idCliente='+idCliente+'&idTratamiento='+idTratamiento);
    else
      return this.http.get<any>(this.endpoint + 'api/clientes.php?idCliente='+idCliente);
  }




  saveCliente(datos:clienteDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/clientes.php', datos,this.httpOptions);
  }

  delCliente(idCliente:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/clientes.php?idCliente='+idCliente, this.httpOptions);
  }


  getTratamiento(id:number):Observable<tratamientoCliDTO>{
    return this.http.get<tratamientoCliDTO>(this.endpoint + 'api/tratamientos.php?id='+id);
  }

  getTratamientosMaestros():Observable<any[]>{
    return this.http.get<any[]>(this.auth.endPoint + 'tratamientos');
  }

  getTratamientoMaestro(id:number):Observable<any>{
    return this.http.get<any>(this.endpoint + 'api/maestroTratamientos.php?id='+id);
  }

  delTratamientoMaestro(id:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/maestroTratamientos.php?id='+id, this.httpOptions);
  }
  //saveTratamientoMaestro(datos:trataDTO):Observable<any>{
    //return this.http.post<any>(this,au.endpoint + 'api/maestroTratamientos.php', datos,this.httpOptions);
  //}

  getTipoTratamientos():Observable<string[]>{
    return this.http.get<string[]>(this.endpoint + 'api/maestroTratamientos.php?tipos=1');
  }

  saveTratamiento(datos:tratamientoCliDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/tratamientos.php', datos,this.httpOptions);
  }

  delTratamiento(id:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/tratamientos.php?id='+id, this.httpOptions);
  }

  /*** lineaTratamientos */
  getLineaTratamiento(id:number):Observable<lineaTratamientoDTO>{
    return this.http.get<any>(this.endpoint + 'api/lineaTratamientos.php?id='+id);
  }
  saveLineaTratamiento(datos:lineaTratamientoDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/lineaTratamientos.php', datos,this.httpOptions);
  }
  delLineaTratamiento(id:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/lineaTratamientos.php?id='+id, this.httpOptions);
  }

  /*** Intervenciones */
  getIntervenciones(id:number):Observable<intervencionDTO[]>{
    return this.http.get<intervencionDTO[]>(this.endpoint + 'api/intervenciones.php?idTratamiento='+id);
  }

  getIntervencion(id:number):Observable<intervencionDTO>{
    return this.http.get<intervencionDTO>(this.endpoint + 'api/intervenciones.php?id='+id);
  }

  saveIntervencion(datos:intervencionDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/intervenciones.php', datos,this.httpOptions);
  }
  delIntervencion(id:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/intervenciones.php?id='+id, this.httpOptions);
  }




  getContrato(idContrato:number):Observable<any>{
    return this.http.get<any>(this.endpoint + 'api/contratos.php?id='+idContrato);
  }

  delContrato(idContrato:number, idCliente:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/contratos.php?id='+idContrato+'&idCliente='+idCliente);

  }


  firmaContrato(idContrato:number, idCliente:number, firma:string, clicadas:string):Observable<any>{
    let datos={idContrato:idContrato, idCliente:idCliente, firma:firma, clicadas:clicadas};
    
    return this.http.post<any>(this.endpoint + 'api/firmaContrato.php', datos,this.httpOptions);
  }

  firmaConsentimiento(idTratamiento:number, firma:string, doctor:string):Observable<any>{
    let datos={idTratamiento:idTratamiento, firma:firma, doctor:doctor};
    return this.http.post<any>(this.endpoint + 'api/firmaConsentimiento.php', datos,this.httpOptions);
  }



  getTemplates(idCliente:number, tratamiento:string):Observable<any[]>{
    return this.http.get<any[]>(this.endpoint + 'api/templates.php?idCliente='+idCliente+'&tratamiento='+tratamiento);
  }


  /*** doctores */

  getDoctor(id:number):Observable<doctorDTO>{
    return this.http.get<doctorDTO>(this.endpoint + 'api/doctores.php?id='+id);
  }

  getDoctores():Observable<doctorDTO[]>{

      return this.http.get<doctorDTO[]>(this.endpoint + 'api/doctores.php');

  }


  getCurrentDoctor():doctorDTO{
    return this.currectDoctor;
  }

  getNewDoctor():Observable<number>{
    return this.http.get<number>(this.endpoint + 'api/doctores.php?newId=1');
  }
  saveDoctor(datos:doctorDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/doctores.php', datos,this.httpOptions);
  }
  delDoctor(idDoctor:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/doctores.php?id='+idDoctor, this.httpOptions);
  }

  sendFoto(datos:any):any{
    return this.http.post<any>(this.endpoint + 'api/foto.php', datos,this.httpOptions);
  }

  saveDoc(datos:docDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/docs.php', datos,this.httpOptions);
  }
  delDoc(id:number):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/docs.php?id='+id,this.httpOptions);
  }

  getIngresos(f1:string, f2:string, idDoctor?:number, tipo?:string):Observable<any>{
    let clausula='f1='+f1+'&f2='+f2;
    if (idDoctor && idDoctor>0)
      clausula += '&idDoctor='+idDoctor;
    if (tipo && tipo!='')
      clausula += '&tipo='+tipo;

    return this.http.get<any>(this.endpoint + 'api/ingresos.php?'+clausula);
  }

  getPendientes():Observable<any>{
    return this.http.get<any>(this.endpoint + 'api/pendientes.php?');
  }

  getConsentimientoTratamiento():Observable<consentimientoTrataDTO>{
    return this.http.get<consentimientoTrataDTO>(this.endpoint + 'api/consentimientoTratamiento.php');
  }

  file_exists(file: string):Observable<any>{
    return this.http.get<any>(this.endpoint + 'api/file_exists.php?file='+file);
  }

  /*** Productos - Labels */
  getProductosMaestros():Observable<productoDTO[]>{
    return this.http.get<any[]>(this.endpoint + 'api/maestroProductos.php');
  }

  getProductoMaestro(id:string):Observable<any>{
    return this.http.get<any[]>(this.endpoint + 'api/maestroProductos.php?id='+id);
  }

  saveProductoMaestro(datos:productoDTO):Observable<any>{
    return this.http.post<any>(this.endpoint + 'api/maestroProductos.php', datos,this.httpOptions);
  }

  delProductoMaestro(id:string):Observable<any>{
    return this.http.delete<any>(this.endpoint + 'api/maestroProductos.php?id='+id);
  }


}
