import { Routes } from '@angular/router';

import { ClientesComponent } from './clientes.component';
import { EditComponent } from './edit/edit.component';
import { AuthorizatedGuard } from 'src/app/core/auth/authorizated.guard';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { IntervencionComponent } from './intervencion/intervencion.component';

export const ClientesRoutes: Routes = [
    {
      path: '',
      component: ClientesComponent,
      canActivate: [ AuthorizatedGuard ],
      data: { role: 'clientes'} 
    },
    {
      path: 'edit/:id',
      component: EditComponent,
      canActivate: [ AuthorizatedGuard ],
      data: { role: 'clientes'} 
    },
    {
      path: 'tratamiento/:id',
      component: TratamientoComponent,
      canActivate: [ AuthorizatedGuard ],
      data: { role: 'clientes'} 
    },
    {
      path: 'intervencion/:id',
      component: IntervencionComponent ,
      canActivate: [ AuthorizatedGuard ],
      data: { role: 'clientes'} 
    }
  ];
