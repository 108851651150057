<p-blockUI [baseZIndex]="1300" [blocked]="loader">
    <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
        <p-progressSpinner class="spin"></p-progressSpinner>
    </div>
</p-blockUI>

<p-toast key="tst" position="top-center" [baseZIndex]="5000" [style]="{marginTop: '35vh', width:'500px'}"></p-toast>
<p-confirmDialog [baseZIndex]="4000" [style]="{width: '80vw', maxWidth:'400px'}"></p-confirmDialog>



<div [hidden]="status==0" class="ui-fluid">
    <div class="ui-g">
        <div class="ui-g-12 ui-md-12 ui-xl-12" style="max-width: 1400px;">

            <p class="migaPan">
                <a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>
                <a [routerLink]="['/clientes']">Clientes </a>
                <i class="fas fa-greater-than miguita"></i>
                Cliente
            </p>
            <div class="card card-w-title">

                <app-headerbar
                        [titulo]="cliente.id==0 ? 'Ficha de cliente (nuevo)': 'Ficha de cliente ('+cliente.id+')'"
                        [status]="status"
                        (save)="save()"
                        (delete)="delete()"
                        (cancel)="cancel()">
                </app-headerbar>

                <app-errordata *ngIf="status<0"
                    [error]="txtError"
                    (reintentar)="reintentar()">
                </app-errordata>

                <div [hidden]="status!=1">


                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                            <label for="input">Nombre</label>
                        </div>
                        <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-4 ">
                            <input #nombre type="text" pInputText [(ngModel)]="cliente.nombre">
                        </div>
                    </div>
                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                            <label for="input">Género</label>
                        </div>
                        <div class="ui-g-12 ui-md-3 ui-lg-1">
                            <p-dropdown [options]="generos" [(ngModel)]="genero" optionLabel="label" ></p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">F. Nacimiento</label>
                        </div>
                        <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 ">
                            <p-inputMask (change)="calculaEdad()" mask="99/99/9999" [(ngModel)]="fecha" slotChar="dd/mm/yyyy"></p-inputMask>
                        </div>
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel" style="text-align: left; color:#3498db; font-weight: bold;">
                            <label for="input">{{edad}}</label>
                          </div>
                    </div>

                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">Dirección</label>
                        </div>
                        <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-4 ">
                          <input type="text" pInputText [(ngModel)]="cliente.direccion">
                      </div>
                    </div>

                    <div class="ui-g form-group">
                      <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">Código Postal</label>
                      </div>
                      <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 ">
                          <input type="text" pInputText [(ngModel)]="cliente.cp">
                      </div>
                      <div class="ui-g-12 ui-md-2 ui-lg-1 divLabel">
                          <label for="input">Población</label>
                      </div>
                      <div class="ui-g-12 ui-md-4 ui-lg-3 ui-xl-2 ">
                          <input type="text" pInputText [(ngModel)]="cliente.poblacion">
                      </div>
                    </div>

                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">Teléfono</label>
                        </div>
                        <div class="ui-g-12 ui-md-4 ui-lg-2">
                          <input type="text" pInputText [(ngModel)]="cliente.telefono">
                        </div>
                    </div>

                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                          <label for="input">E-mail</label>
                        </div>
                        <div class="ui-g-12 ui-md-9 ui-lg-6 ui-xl-4 ">
                          <input type="text" pInputText [(ngModel)]="cliente.email">
                        </div>
                    </div>
                    <div class="ui-g form-group">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 divLabel">
                            <label for="input">Observaciones</label>
                        </div>
                        <div class="ui-g-12 ui-md-9 ui-lg-10">
                            <textarea [(ngModel)]="cliente.observaciones" style="width: 100%;min-height:60px;" [rows]="6" pInputTextarea autoResize="true"></textarea>
                        </div>
                    </div>

                </div>


            </div>


            <div  [hidden]="status!=1 || cliente.id==0" class="card no-margin">
                    <h1>Consentimientos</h1>
                    <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-10 ui-xl-7">
                        <p-table #dt [value]="consentimientos">
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr>
                                    <td>({{item.idConsentimiento}}) {{item.nombre}}</td>
                                    <td style="width: 250px;">
                                        <div [hidden]="!(item.fecha==null || item.fecha=='0000-00-00')" class="p-col">

                                            <i class="fa fa-exclamation-triangle danger" aria-hidden="true"></i>&nbsp;
                                            <a target="_blank" href="#" title="Ver consentimiento pendiente de firma" (click)="showDemo($event, item.idConsentimiento)" style="color:#e17576; font-weight: bold; text-decoration: underline;">Pendiente de firma</a>

                                    </div>
                                    <div [hidden]="(item.fecha==null)" class="p-col">
                                        <a target="_blank" href="#" title="Ver consentimiento" (click)="abrirConsentimiento($event, item.idConsentimiento)" style="color:#3498db; font-weight: bold; text-decoration: underline;">Firmado el  {{item.fecha | date:'dd/MM/yyyy'}}</a>
                                    </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>


            </div>
         <div  [hidden]="status!=1 || cliente.id==0" class="card no-margin" >
                <p-tabView #solapas (onChange)="editingItems=''">
                    <p-tabPanel header="Historial médico" leftIcon="fas fa-file-medical fa-2x">
                        <h1>Historial médico</h1>
                        <div style="min-height: 400px;">
                            <div class="ui-g form-group">
                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="alergias">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr >
                                                        <th>Alérgias</th>
                                                        <th class="header1btn" (click)="addHistoria(0)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(0, i)"  title="eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="enfermedades">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th>Enfermedades</th>
                                                        <th class="header1btn" (click)="addHistoria(2)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(2, i)"  title="eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="medicaciones">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th>Medicación actual</th>
                                                        <th class="header1btn" (click)="addHistoria(1)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(1, i)"  title="Eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="intervenciones">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th>Intervenciones Quirurgicas</th>
                                                        <th class="header1btn" (click)="addHistoria(3)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(3, i)"  title="Eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>

                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="antecedentesF">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th>Antecedentes familiares</th>
                                                        <th class="header1btn" (click)="addHistoria(4)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(4, i)"  title="Eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                    <div class="ui-g-12 ui-md-6 ui-lg-3">
                                        <p-table #dt [value]="antecedentesM">
                                            <ng-template pTemplate="header" let-columns>
                                                    <tr>
                                                        <th>Antecedentes médico-estéticos</th>
                                                        <th class="header1btn" (click)="addHistoria(5)" title="Agregar registro">
                                                            <i class="fa fa-plus"></i>
                                                        </th>
                                                    </tr>
                                                </ng-template>
                                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                                <tr>
                                                    <td>{{item.descripcion}}</td>
                                                    <td class="header1btn" (click)="editHistoria(5, i)"  title="Eliminar registro">
                                                        <i class="fa fa-edit col2btn" title="Editar registro" ></i>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>

                            </div>
                        </div>
                    </p-tabPanel>

                    <p-tabPanel header="Tratamientos" leftIcon="fas fa-user-md fa-2x">
                        <h1>Tratamientos</h1>
                        <div style="min-height: 400px;">
                            <p-table  #dt   [columns]="colsTratamientos"
                                            [value]="tratamientos"
                                            [globalFilterFields]="['id','fecha', 'tratamientos', 'productos', 'revision']"
                                            [paginator]="true"
                                            [rows]="25"
                                            dataKey="id"
                                            selectionMode="single"
                                            [(selection)]="selectedTrat">

                            <ng-template pTemplate="caption">
                                <div>
                                    <div style="text-align:left; float: left;">
                                        <button type="button" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="openNewTrata()" label="Nuevo tratamiento" title="Crear un nuevo tratamiento"></button>
                                    </div>
                                    <div style="text-align: right">
                                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="50" placeholder="Buscar..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th style="width: 75px;" pSortableColumn="id">Id<p-sortIcon field="id"></p-sortIcon></th>
                                    <th style="width: 90px;" pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
                                    <th pSortableColumn="tratamientos">Tratamientos</th>
                                    <th pSortableColumn="productos">Productos</th>
                                    <th style="width: 100px;" pSortableColumn="fechaRevision">F.revisión<p-sortIcon field="fechaRevision"></p-sortIcon></th>
                                    <th pSortableColumn="revision">Revisión<p-sortIcon field="revision"></p-sortIcon></th>
                                    <th style="width: 100px;" pSortableColumn="importe">Importe<p-sortIcon field="importe"></p-sortIcon></th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-linea let-columns="columns">
                                <tr [pSelectableRow]="linea" title="Editar tratamiento" (click)="editTratamiento(linea.id)">
                                    <td style="text-align: right" >{{linea.id}}</td>
                                    <td style="text-align: right" >{{linea.fecha | date:'dd/MM/yyyy'}}</td>
                                    <td [innerHTML]="linea.tratamientos"></td>
                                    <td [innerHTML]="linea.productos"></td>
                                    <td style="text-align: right" >{{linea.fechaRevision | date:'dd/MM/yyyy'}}</td>
                                    <td>{{linea.revision}}</td>
                                    <td style="text-align: right" >{{linea.importe | number:'0.2-50':'es'}}  €</td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr style="text-align: right; font-weight: bold;">
                                    <td colspan="6">Sumas ...</td>
                                    <td>{{totalImportes | number:'0.2-50':'es'}}  €</td>
                                </tr>
                            </ng-template>

                        </p-table>
                        </div>
                    </p-tabPanel>

                    <!-----D O C S------------------------------------------------->
                    <p-tabPanel header="Archivo general" leftIcon="fas fa-file-medical-alt fa-2x">
                            <div style="min-height: 400px;">
                                <h1>Archivo general</h1>
                                <div class="p-grid">
                                        <div class="p-col-fixed" style="width:100px">
                                            <p-fileUpload   name="myfile[]" mode="basic" (onSelect)="onUploadItem('docs', $event)" [auto]="true" chooseLabel="Agregar" ></p-fileUpload>
                                        </div>
                                        <div class="p-col-fixed" style="width:100px">
                                            <button type="button"  class="ui-button-secondary" pButton icon="fa fa-times" (click)="onEditItem('docs')" label="Eliminar" title="Eliminar ficheros"></button>
                                        </div>
                                </div>
                                <div class="p-grid">

                                        <div *ngFor="let th of docThumbs; let i=index;" class="p-col-fixed" style="width:140px; position: relative; ">
                                                <img [ngClass]="(selectedImage!=th.nombre) ?'box':'box selected'" src="{{th.base64}}" (click)="onSelectItem('docs',th.nombre)">
                                                <div *ngIf="editingItems=='docs'" class="icoBorrar2"  title="Eliminar imagen" (click)="onDeleteItem('docs', i, th.nombre)"><i class="fa fa-times col1btn"></i></div>
                                                <p class="leyenda">{{th.nombre}}<br>{{th.fecha | date:'dd/MM/yyyy HH:mm'}}</p>
                                        </div>
                                        <div *ngIf="uploadingItems=='docs'" class="p-col-fixed" style="width:140px; ">
                                            <div class="uploading">
                                                <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </p-tabPanel>


                    <!-----F O T O S------------------------------------------------->
                    <p-tabPanel header="Galería de fotos" leftIcon="fas fa-smile-beam fa-2x">
                        <div style="min-height: 400px;">
                            <h1>Galería de fotos</h1>
                            <div class="p-grid">
                                    <div class="p-col-fixed" style="width:100px">
                                        <p-fileUpload   name="myfile[]" mode="basic" (onSelect)="onUploadItem('images', $event)" [auto]="true" chooseLabel="Agregar" accept="image/*" ></p-fileUpload>
                                    </div>
                                    <div class="p-col-fixed" style="width:100px">
                                        <button type="button"  class="ui-button-secondary" pButton icon="fa fa-times" (click)="onEditItem('images')" label="Eliminar" title="Eliminar ficheros"></button>
                                    </div>
                                    <div class="p-col"></div>
                                    <div *ngIf="selectedFotos.length>1" class="p-col-fixed" style="width:170px">
                                        <button type="button" class="ui-button-warning" pButton icon="fas fa-grin-squint" (click)="openFaceToFace()" label="Comparar" title="Comparar imágenes"></button>
                                    </div>
                                    <div *ngIf="selectedFotos.length>0" class="p-col-fixed" style="width:170px">
                                        <button type="button" class="ui-button-secondary" pButton icon="fas fa-eraser" (click)="resetSel()" label="Reset selección" title="Quitar la selección de imágenes"></button>
                                    </div>
                            </div>
                            <div class="p-grid">
                                    <div *ngFor="let th of imgThumbs; let i=index;" class="p-col-fixed" style="width:140px; position: relative; ">
                                            <img [ngClass]="(selectedImage!=th.nombre) ?'box':'box selected'" src="{{th.base64}}" (click)="onSelectItem('images',th.nombre)">
                                            <div *ngIf="editingItems=='images'"  title="Eliminar imagen" (click)="onDeleteItem('images', i, th.nombre)" class="icoBorrar"><i class="fa fa-times col1btn" ></i></div>
                                            <p (click)="selectFoto(i)" title="Seleccionar para comparar" class="leyenda" [class.selected]="isSelected(i)"><span class="ff">{{th.fecha | date:'dd/MM/yyyy HH:mm'}}</span></p>
                                    </div>
                                    <div *ngIf="uploadingItems=='images'" class="p-col-fixed" style="width:140px; ">
                                        <div class="uploading">
                                            <i class="pi pi-spin pi-spinner" style="font-size: 3em"></i>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </p-tabPanel>

                    <!-----B O N O S------------------------------------------------->
                    <p-tabPanel header="Bonos" leftIcon="fas fa-ticket-alt fa-2x">
                        <h1>Bonos</h1>
                        <div style="min-height: 400px;">
                            <p-table  #dt   [value]="cliente.bonosCli"
                                            [globalFilterFields]="['id','fecha', 'descripcion', 'caduca', 'precio', 'sesiones']"
                                            [paginator]="true"
                                            [rows]="25"
                                            dataKey="id"
                                            selectionMode="single"
                                            [(selection)]="selectedBono">

                            <ng-template pTemplate="caption">
                                <div>
                                    <div style="text-align:left; float: left;">
                                        <button type="button" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="openNewBono()" label="Nuevo Bono" title="Agregar Bono"></button>
                                    </div>
                                    <div style="text-align: right">
                                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="50" placeholder="Buscar..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 75px;" pSortableColumn="id">Id<p-sortIcon field="id"></p-sortIcon></th>
                                    <th style="width: 100px;" pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
                                    <th pSortableColumn="descripcion">Descripción</th>
                                    <th style="width: 100px;">Válido hasta</th>
                                    <th>Sesiones</th>
                                    <th style="width: 70px;">Pagado</th>
                                    <th style="width: 100px;">Precio</th>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-linea let-columns="colsBonos">
                                <tr [pSelectableRow]="linea" title="Editar bono">
                                    <td  (click)="editBono(linea)" style="text-align: right" >{{linea.id}}</td>
                                    <td  (click)="editBono(linea)" style="text-align: right" >{{linea.fecha | date:'dd/MM/yyyy'}}</td>
                                    <td  (click)="editBono(linea)">{{linea.descripcion}}</td>
                                    <td  (click)="editBono(linea)" style="text-align: right" >{{linea.caduca | date:'dd/MM/yyyy'}}</td>
                                    <td  (click)="editBono(linea)">
                                         <span *ngFor="let t of tickets(linea.id); let i=index;" class="bsesion" style="font-size: 25px;">
                                                <i title="Ver uso del ticket" style="cursor: pointer;" *ngIf="t" class="pi pi-circle-on" (click)="openTicketUsado($event, linea.id, i, opTicketUsado)"></i>
                                                <i title="Utilizar bono" style="cursor: pointer;" *ngIf="!t" class="pi pi-circle-off" (click)="openSeleccionaTicket($event, linea.id, opSeleccionaTicket)"></i>
                                        </span>

                                    </td>
                                    <td  (click)="editBono(linea)" style="text-align: center">
                                            <i *ngIf="linea.pagado" title="Bono pagado" style="color: #3eb839; font-size: 18px;" class="pi pi-check"></i>
                                        </td>
                                    <td  (click)="editBono(linea)" style="text-align: right" >{{linea.precio | number:'0.2-50':'es'}}  €</td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                    <tr style="text-align: right; font-weight: bold;">
                                        <td colspan="6">Sumas ...</td>
                                        <td>{{totalBonos() | number:'0.2-50':'es'}}  €</td>

                                    </tr>
                            </ng-template>


                        </p-table>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
        </div>


</div>


<p-dialog [header]="historiasTipo[tipo]" [(visible)]="displayDialog" [responsive]="true" showEffect="fade" [modal]="true"  [style]="{width: '90vw', maxWidth:'500px'}">
        <div>
                <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-3 divLabel">
                            <label for="his">Descripción</label>
                    </div>
                    <div class="ui-g-12 ui-md-9">
                            <input #nombreHis style="width: 100%" pInputText  [(ngModel)]="currentHis.descripcion" />
                    </div>
                </div>
        </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">

            <button type="button" class="ui-button-secondary" pButton icon="fa fa-check" (click)="saveHistoria()" label="Guardar"></button>
            <button *ngIf="currentHis.id!=0" type="button" class="ui-button-secondary" pButton icon="fa fa-trash" (click)="delHistoria()" label="Eliminar"></button>
            <button type="button" class="ui-button-secondary" pButton icon="fa fa-times" (click)="cancelHistoria()" label="Cancelar"></button>

        </div>
    </p-footer>
</p-dialog>


<!-- desplegable lateral para un nuevo tratamiento  -->
<p-sidebar [(visible)]="visibleSeleccionTrata" [style]="{width:'45em'}" [baseZIndex]="10000">
        <h1 style="font-weight:normal">Nuevo tratamiento</h1>

                <p-table #tm  [value]="tratamientosMaestros"
                          selectionMode="single"
                          [scrollable]="true"
                          scrollHeight="80vh"
                          [columns]="['id', 'tipo','descripcion']"
                          [globalFilterFields]="['tipo','descripcion']"
                          dataKey="id">
                        <ng-template pTemplate="caption">
                            <div>
                                <div style="text-align: left; margin-bottom: 5px;">
                                    <i class="fa fa-user-md" style="margin:4px 6px 0 0; color:green"></i>
                                    <p-dropdown [options]="doctors" [(ngModel)]="currentDoctor" placeholder="Seleccione un doctor " optionLabel="nombre"></p-dropdown>
                                </div>
                                <div style="text-align: left">
                                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="50" placeholder="Buscar..." (input)="tm.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                </div>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: left; width:90px;">Tipo</th>
                                <th style="text-align: left">Tratamiento</th>
                                <th style="text-align: right; width:80px;">Precio</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-item>
                            <tr [pSelectableRow]="item" (click)="nuevoTratamiento(item.id)" title="Adquirir el bono">
                                <td style="text-align: left; width:90px;">{{item.tipo}}</td>
                                <td>{{item.descripcion}}</td>
                                <td style="text-align: right;width:80px;">{{item.precio | number:'0.2-50':'es'}}</td>
                            </tr>
                        </ng-template>
            </p-table>
</p-sidebar>


<!-- desplegable lateral para un nuevo bono  -->
<p-sidebar [(visible)]="visibleSeleccionBono" [style]="{width:'35em'}" [baseZIndex]="10000">
    <h1 style="font-weight:normal">Seleccione un bono</h1>
            <p-table  [value]="bonos" selectionMode="single">
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: left">Bono</th>
                    <th style="text-align: right; width:80px;">Precio</th>
                </tr>
           </ng-template>
           <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" (click)="comprarBono(item.id)" title="Adquirir el bono">
                    <td>{{item.descripcion}}</td>
                    <td style="text-align: right;">{{item.precio | number:'0.2-50':'es'}}</td>
                </tr>
            </ng-template>
        </p-table>
</p-sidebar>


<!-- Formualario de edición de bono -->
<p-dialog header="{{currentBonoCli.fecha | date:'dd/MM/yyyy'}}" [(visible)]="displayEditBono" [modal]="true" [style]="{width: '600px'}" [baseZIndex]="222">
    <div style="width: 500px">
       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Bono</label>
           </div>
           <div class="ui-g-12 ui-md-9">
               <input style="width: 100%"  readonly type="text" pInputText [(ngModel)]="currentBonoCli.descripcion">
           </div>
       </div>

       <div class="ui-g form-group">
               <div class="ui-g-12 ui-md-3 divLabel">
                   <label for="input">Válido hasta</label>
               </div>
               <div class="ui-g-12 ui-md-2">
                   <p-inputMask #fCaduca id="fCaduca" mask="99/99/9999" [(ngModel)]="currentBonoCli.caduca" slotChar="dd/mm/yyyy"></p-inputMask>
               </div>
       </div>

       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Precio</label>
           </div>
           <div class="ui-g-12 ui-md-2" >
               <input  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentBonoCli.precio" style="text-align: right; width: 70px;">


           </div>
       </div>
       <div class="ui-g form-group">
               <div class="ui-g-10 ui-md-3 divLabel" style="margin-top: 0">
                   <label for="input">Pagado</label>
               </div>
               <div class="ui-g-2 ui-md-3">
                   <p-checkbox name="groupname" value="val1" binary="true" [(ngModel)]="currentBonoCli.pagado"></p-checkbox>
               </div>
       </div>
   </div>
   <p-footer>
       <button type="button" class="ui-button-secondary" pButton icon="pi pi-check" (click)="saveBonoCli(currentBonoCli)" label="Aceptar"></button>
       <button type="button" class="ui-button-secondary" pButton icon="pi pi-trash" (click)="delBonoCli(currentBonoCli)" label="Eliminar"></button>
       <button type="button" class="ui-button-secondary" pButton icon="fa fa-times" (click)="displayEditBono=false" label="Cancelar"></button>
   </p-footer>
</p-dialog>


<!-- info de un ticket usado-->
<p-overlayPanel #opTicketUsado [showCloseIcon]="true">
        <p-panel header="Bono utilizado el {{tmpLineaTrat.fecha | date:'dd/MM/yyyy'}}">
            <h3>{{tmpLineaTrat.descripcion}}</h3>
        </p-panel>
</p-overlayPanel>


<!-- info para la selección de un ticket -->
<p-overlayPanel #opSeleccionaTicket [showCloseIcon]="true">
                <p-table [value]="tmpTratamientosSeleccionables"  selectionMode="single"  [style]="{width: '400px'}">
                        <ng-template pTemplate="caption">
                                Seleccione un tratamiento
                             </ng-template>
                             <ng-template pTemplate="header">
                                     <tr>
                                        <th style="text-align: left">Tratamiento</th>
                                        <th style="text-align: right; width: 60px;">Usados</th>
                                     </tr>
                             </ng-template>
                       <ng-template pTemplate="body" let-item>
                            <tr [pSelectableRow]="item" title="Usar bono con este tratamiento" (click)="gastarBono(item)">
                                <td>{{item.descripcion}}</td>
                                <td style="text-align: right;">{{item.usados}}</td>

                            </tr>
                        </ng-template>
                    </p-table>

</p-overlayPanel>




<!--****** FACE TO FACE-->
<p-sidebar [(visible)]="faceToFace" [fullScreen]="true" [baseZIndex]="100">
    <div>
        <div *ngIf="faceFotos.length==2" class="ui-g faces">
            <div class="ui-g-6 ftf"><div class="face1"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-6 ftf"><div class="face2"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
        </div>

        <div *ngIf="faceFotos.length==3" class="ui-g faces">
            <div class="ui-g-4 ftf"><div class="face11"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-4 ftf"><div class="face22"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
            <div class="ui-g-4 ftf"><div class="face22"><img [src]="faceFotos[2]"></div>{{fechaFoto(2)}}</div>
        </div>
        <div *ngIf="faceFotos.length==4" class="ui-g faces">
            <div class="ui-g-3 ftf"><div class="face111"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[2]"></div>{{fechaFoto(2)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[3]"></div>{{fechaFoto(3)}}</div>
        </div>

    </div>
    <div>
        <div class="botonera1">
            <button pButton type="button" (click)="faceToFace = false" icon="fas fa-times" label="Cerrar" class="ui-button-secondary"></button>
        </div>
        <div class="botonera2">
                <button pButton type="button" (click)="faceToFace = false" icon="fas fa-times" label="Cerrar" class="ui-button-secondary"></button>
        </div>
    </div>
</p-sidebar>
