import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { DatosService } from './core/services/datos.service';

import { Observable } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { userDTO } from './shared/model/userDTO';
import { GlobalService } from './core/services/global.service';
enum MenuMode {
    STATIC,
    OVERLAY,
    SLIM
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    menu: MenuMode = MenuMode.STATIC;

    layout = 'default';

    darkMenu: boolean;

    documentClickListener: Function;

    staticMenuInactive: boolean;

    overlayMenuActive: boolean;

    mobileMenuActive: boolean;

    menuClick: boolean;

    menuButtonClick: boolean;

    topbarMenuButtonClick: boolean;

    topbarMenuActive: boolean;

    activeTopbarItem: Element;

    resetSlim: boolean;

    menuHoverActive: boolean;

    isLoggedIn: boolean;

    loading:boolean=false;

    constructor(public renderer: Renderer2, 
                private datos:DatosService, 
                private auth:AuthService, 
                private router:Router, 
                private global:GlobalService) {

        registerLocaleData(es);
        this.isLoggedIn = this.auth.isAuthenticated();

        this.router.events.subscribe(
            (event)=>{
                switch(true){
                    case event instanceof NavigationStart:{
                        this.loading=true;
                        break;
                    }
                    case event instanceof NavigationEnd:
                    case event instanceof NavigationCancel:
                    case event instanceof NavigationError:{
                        this.loading=false;
                        break;
                    }
                    default:{
                        break;
                    }
                }
            }
        );
         
        
        
    }


    isLogged(){
        return this.auth.isAuthenticated();
    }

    logOut(event: Event){
        //event.preventDefault();
        this.auth.removeCurrentSession();
        this.router.navigate(['login']);
    }

    verPerfil(e){    
        let user:userDTO=this.auth.getCurrentUser();
        if (user.id){
            this.router.navigate(["/account"]);    
        }
        event.preventDefault();
    }

    onLayoutClick() {
        if (!this.menuClick && !this.menuButtonClick) {
            this.mobileMenuActive = false;
            this.overlayMenuActive = false;
            this.resetSlim = true;
            this.menuHoverActive = false;
        }

        if (!this.topbarMenuButtonClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        this.menuClick = false;
        this.menuButtonClick = false;
        this.topbarMenuButtonClick = false;
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick = true;

        if (this.isMobile()) {
            this.mobileMenuActive = !this.mobileMenuActive;
        } else {
            if (this.staticMenu) {
                this.staticMenuInactive = !this.staticMenuInactive; } else if (this.overlayMenu) {
                this.overlayMenuActive = !this.overlayMenuActive; }
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event: Event) {
        this.topbarMenuButtonClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item: Element) {
        this.topbarMenuButtonClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onMenuClick(event: Event) {
        this.menuClick = true;
        this.resetSlim = false;
    }

    get slimMenu(): boolean {
        return this.menu === MenuMode.SLIM;
    }

    get overlayMenu(): boolean {
        return this.menu === MenuMode.OVERLAY;
    }

    get staticMenu(): boolean {
        return this.menu === MenuMode.STATIC;
    }

    changeToSlimMenu() {
        this.menu = MenuMode.SLIM;
    }

    changeToOverlayMenu() {
        this.menu = MenuMode.OVERLAY;
    }

    changeToStaticMenu() {
        this.menu = MenuMode.STATIC;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }


    onActivate(event) {
        let scrollToTop = window.setInterval(() => {
            
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }
}
