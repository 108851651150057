export class productoDTO {
    codigo:string;
    descripcion:string='';
    proveedor:string='';
    precio:number=0;
    stockMin:number=0;
    observaciones:string='';
    ubicacion:string='';
    baja:boolean=false;
    stock:number=0;

}

export class listaproductoDTO {
    codigo:string;
    descripcion:string='';
    proveedor:string='';
    precio:number=0;
    stockMin:number=0;
    ubicacion:string='';
    baja:boolean=false;
    stock:number=0;
}

export class movalmacenDTO {
    id: number=0;
    fecha:string=new Date().toISOString().slice(0,10); 
    codigo: string='';
    tipo: number=0;
    descripcion: string='';
    proveedor: string='';
    lote: string='';
    cantidad:number=0;
    precio:number=0;
    idTratamiento?: number=0;
    idCliente?: number=0;
    observaciones: string='';
    
}


export class movimientosDTO {
    id:number=0;
    codigo:string;
    fecha:string;
    tipo:number=0;
    cantidad?:number=0;
    precio?:number=0;
    idTratamiento?:number=0;
    lote:string='';
    observaciones:string='';
    tratamiento:string='';
    nombreCliente:string='';

}