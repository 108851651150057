import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BonoDTO } from '../../shared/model/bonoDTO';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { tratamientoCliDTO, tratamientoDTO } from 'src/app/shared/model/tratamientoDTO';

@Injectable({
  providedIn: 'root'
})
export class TratamientosService {
  public endpoint: string = '';
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};

  constructor(private http:HttpClient, private auth:AuthService) {
    
  }



  getTratamientos(activos:boolean):Observable<tratamientoDTO[]>{
    if (activos)
      return this.http.get<tratamientoDTO[]>(this.auth.endPoint + 'tratamientos/activos');
    else
      return this.http.get<tratamientoDTO[]>(this.auth.endPoint + 'tratamientos');
  }

  getTratamiento(id:number):Observable<tratamientoDTO>{
    return this.http.get<tratamientoDTO>(this.auth.endPoint + 'tratamientos/' + id);
  }

  saveTratamiento(trat:tratamientoDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'tratamientos/', trat, this.httpOptions);
  }

  delTratamiento(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'tratamientos/'+id, this.httpOptions);
  }


  printPresupuesto(id:number):Observable<any>{
    return this.http.get(this.auth.endPoint + 'pdf/presupuesto/' + id, {responseType: 'arraybuffer'}).pipe(timeout(15000));
  }

  printConsentimiento(datos:any):Observable<any>{
    return this.http.post(this.auth.endPoint + 'pdf/consentimiento/',datos, {responseType: 'arraybuffer'}).pipe(timeout(15000));
  }




}
