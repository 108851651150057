import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { Router, ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { GlobalService } from 'src/app/core/services/global.service';
import { userDTO, permisoDTO } from 'src/app/shared/model/userDTO';
import { UsersService } from 'src/app/core/services/users.service';
import { FilesService } from 'src/app/core/services/files.service';


import { AgendaService } from 'src/app/core/services/agenda.service';
import { DatePipe } from '@angular/common';
import { diaDTO, agendaDTO } from 'src/app/shared/model/agendaDTO';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {


  @ViewChild('login',{static: true}) login: ElementRef;


  user:userDTO=new userDTO();
  loader=false;
  status=0;
  txtError='';
  id=0;
  types = [
    {label: 'Sín acceso', value: 0},
    {label: 'Solo lectura', value: 1},
    {label: 'Acceso total', value: 2}
  ];



  options: any;
  selectedDays: any[];
  permiso:permisoDTO=new permisoDTO();
  displayChangePass=false;

  inicio='08:00';
  fin='22:00';
  f1='2020-01-01';
  f2='2021-01-01';
  dias:diaDTO[];
  calendars:agendaDTO[] = [];
  selectedCalendars:agendaDTO[]=[];
  cargando=false;
  lastClick:any=0;
  lasDate:string='';
  selectedEjercicio:number;
  selectedRango:number=0;
  selectedDate:string;
  selectedDay:number;
  selectedTxtDay:string;
  selectedTxtMonth:string;
  rangos:SelectItem[]=[{label:'', value:0}];
  selectedDesde1:Date;
  selectedDesde2:Date;
  selectedHasta1:Date;
  selectedHasta2:Date;
  selectedFestivo:boolean=false;
  selectedDiaAgenda:diaDTO=new diaDTO();
  displayEditSemana=false;
  selectedAgenda='';
  idAgenda=0;
  us:userDTO=new userDTO();
  es={};
 /**end Calendar */

 passActual='';
 passNuevo1='';
 passNuevo2='';


  constructor(  private usersService: UsersService,
                private router: Router,
                private route: ActivatedRoute,
                private global:GlobalService,
                private auth:AuthService,
                private filesService:FilesService,
                private confirmationService:ConfirmationService,
                private agendaService:AgendaService,
                private datePipe:DatePipe,
                private service:MessageService) {

                  this.es={
                    firstDayOfWeek: 0,
                    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
                    monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
                    monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                    today: 'Aceptar',
                    clear: 'Borrar',
                    dateFormat: 'HH:mm',
                    weekHeader: 'Wk'
                  }

                }

  ngOnInit() {

   this.us=this.auth.getCurrentUser();
    this.id=this.us.id;

    if (this.id==0){
        this.status=1;
        this.login.nativeElement.focus();
        this.loader=false;
        return;
    }

    this.loader=true;

    this.agendaService.getAgendas(this.id).subscribe(
      (data)=>{
        this.calendars=data;
        this.selectedCalendars=data;
        for (let i=0; i<data.length; i++){

           this.calendars[i].color=this.getColor(i);
           this.selectedCalendars[i].color=this.getColor(i);
        }
      }
    );





    this.usersService.getUser(this.id).subscribe(
        (data)=>{
            this.user=data;
            this.status=1;
            this.loader=false;

            setTimeout(() => {
              this.login.nativeElement.focus();
            }, 400);
        },
        (error)=>{
          this.status=-1;
          this.loader=false;
          this.txtError=error.message;
        }
      );
  }

  reintentar(){
    this.ngOnInit();
  }

  save() {

    console.log(this.selectedCalendars);

    if (this.user.login=='' || this.user.nombre==''){
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error', detail: 'Los campos login y nombre no pueden estar vacios!!!' });
      return;
    }

    this.loader=true;

    this.usersService.saveUser(this.user).subscribe(
      (data)=> {
        this.loader=false;
        if (data.success) {
          this.service.add({ key: 'tst',severity:'success', life:1000, summary: 'Guardado correcto', detail:'Datos guardados satisfactoriamente'});
          this.user=data.data;

        } else {
          this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: data.info });
        }

      },
      (error) => {
        this.loader=false;
        this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: error.message });
      }
    );


  }

  cancel(){

      this.router.navigateByUrl('/home');
  }

  delete(){

    if (this.user.id==0){
        this.cancel();
        return;
    }

    this.confirmationService.confirm({
      message: 'Desea eliminar el usuario id nº ' + this.user.id + '?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        this.loader=true;
        this.usersService.delUser(this.user.id).subscribe(
          (data)=>{
            this.loader=false;
            if(data.success){
              this.cancel();
              return;
            }else{
              this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el usuario.', detail: data.message});
            }
          },
          (error)=>{
            this.loader=false;
            this.service.add({ key: 'tst', severity: 'error', summary: 'Database Error.', detail: error.message});
          }
        );
      },
      reject: () => {
          return;
      }
  });

  }


  selectFile(event){

    this.loader=true;
    this.filesService.createBase64('firma',event.files).subscribe(
      (data)=>{
          this.loader=false;
          if (data.success){
            this.user.firma=data.data.base64;
            return;
          }
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: data.message });
      },
      (error)=>{
        this.loader=false;
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: error.message });
      }
    );


  }





  changeSolapa(e){
    console.log(e);
    if (e.index==2){
        this.refreshCalendar(2019);
    }
  }

  changeCalendar(){
    this.refreshCalendar(2019);
  }


  addCalendar(){
    console.log('add Calendars');
  }

  handleDateClick(e){
    if (!e.allDay && this.lasDate==e.dateStr && Date.now()-this.lastClick < 350){
      if (this.selectedCalendars.length==0){
        this.service.add({ key: 'tst', severity: 'error', summary: 'Seleccione al menos una Agenda!!!'});
        return;
      }
      if (this.selectedCalendars.length>1){
        this.service.add({ key: 'tst', severity: 'error', summary: 'Seleccione solo una agenda para editar el horario'});
        return;
      }

      this.loader=true;
      const fecha=this.datePipe.transform(e.dateStr, 'yyyy-MM-dd');
      this.selectedDay=new Date(fecha).getDay();
      this.selectedTxtDay=this.global.diasSemana[this.selectedDay];
      this.selectedTxtMonth=this.global.meses[new Date(fecha).getMonth()];
      this.rangos=[];
      this.rangos.push({label:this.selectedTxtDay+', ' + this.datePipe.transform(e.dateStr, 'dd/MM/yyyy'), value:0});
      this.rangos.push({label:'Todos los ' + this.selectedTxtDay + ' desde el ' + this.datePipe.transform(e.dateStr, 'dd/MM/yyyy') + ' hasta fin de '+this.selectedTxtMonth+'.', value:1});
      this.rangos.push({label:'Todos los ' + this.selectedTxtDay + ' desde el ' + this.datePipe.transform(e.dateStr, 'dd/MM/yyyy') + ' hasta fin de año.', value:2});
      this.agendaService.getDia(this.selectedCalendars[0].idAgenda, fecha).subscribe(
        (data)=>{
          this.selectedAgenda=this.selectedCalendars[0].nombre;
          this.selectedDiaAgenda=new diaDTO();
          if (data!=null){
            this.selectedDesde1=(data.desde1==null ? null : new Date(fecha + 'T' + data.desde1));
            this.selectedDesde2=(data.desde2==null ? null : new Date(fecha + 'T' + data.desde2));
            this.selectedHasta1=(data.hasta1==null ? null : new Date(fecha + 'T' + data.hasta1));
            this.selectedHasta2=(data.hasta2==null ? null : new Date(fecha + 'T' + data.hasta2));
          }
            this.displayEditSemana=true;
          this.selectedRango=0;
          this.loader=false;

        },
        (error)=>{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al obtener los datos del servidor', detail: error.message});
          this.loader=false;
        }
      );

  }

   this.lasDate=e.dateStr;
   this.lastClick=Date.now();
  }


  saveDia(){

    if (this.selectedDiaAgenda.festivo){
      this.selectedDiaAgenda.desde1 = null;
      this.selectedDiaAgenda.desde2 = null;
      this.selectedDiaAgenda.hasta1 = null;
      this.selectedDiaAgenda.hasta2 = null;
    }else{
      this.selectedDiaAgenda.desde1= (this.selectedDesde1==null || this.selectedHasta1==null) ? null : this.datePipe.transform(this.selectedDesde1, 'HH:mm');
      this.selectedDiaAgenda.hasta1= (this.selectedDesde1==null || this.selectedHasta1==null) ? null : this.datePipe.transform(this.selectedHasta1, 'HH:mm');
      this.selectedDiaAgenda.desde2= (this.selectedDesde2==null || this.selectedHasta2==null) ? null : this.datePipe.transform(this.selectedDesde2, 'HH:mm');
      this.selectedDiaAgenda.hasta2= (this.selectedDesde2==null || this.selectedHasta2==null) ? null : this.datePipe.transform(this.selectedHasta2, 'HH:mm');
    }

    if (this.selectedDiaAgenda.desde1!=null && this.selectedDiaAgenda.hasta1!=null && this.selectedDiaAgenda.hasta1<=this.selectedDiaAgenda.desde1){
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'EL horario de mañanas es incorrecto' });
      return;
    }
    if (this.selectedDiaAgenda.desde2!=null && this.selectedDiaAgenda.hasta2!=null && this.selectedDiaAgenda.hasta2<=this.selectedDiaAgenda.desde2){
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'EL horario de tardes es incorrecto' });
      return;
    }
    if (this.selectedDiaAgenda.hasta1!=null && this.selectedDiaAgenda.desde1!=null && this.selectedDiaAgenda.desde2<this.selectedDiaAgenda.hasta1){
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'EL horario de tardes es incorrecto' });
      return;
    }


    this.loader=true;
    this.agendaService.saveDia(this.selectedRango, this.selectedDiaAgenda).subscribe(
      (data)=>{
        this.loader=false;
        if (data.success){
          this.refreshCalendar(2019);
          this.displayEditSemana=false
        }else{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: data.message });
        }
      },
      (error)=>{
        this.loader=false;
        this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: error.message });
      }
    );




  }


  refreshCalendar(ejercicio){
    this.cargando=true;

    var ages:number[]=[];
    ages.push(0);
    this.selectedCalendars.forEach(element => {
        ages.push(element.idAgenda);
    });
    this.f1=ejercicio + '-01-01';
    this.f2=(ejercicio+1) + '-01-01';
    this.agendaService.getDiass(ejercicio, ages).subscribe(
      (data)=>{
        let ev=this.agendaService.getCalendarEvents(data, this.selectedCalendars);
        //this.calen.events=ev;
        this.render();
        setTimeout(() => {
          this.cargando=false;
        }, 200);


      },
      (error)=>{
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error al obtener los datos del calendario/agenda', detail: error.message});
        this.cargando=false;
      }
    );
  }


  getColor(i){
    if (i>=10)
      i=Math.trunc(i/10);

    return this.agendaService.colores[i];

  }

  getName(i){

      return this.calendars[i].nombre;

  }


  render(){


  }


  openChangePass(){

    this.passNuevo1='';
    this.passNuevo2='';
    this.passActual='';
    this.displayChangePass=true;
    this.txtError='';
  }

  changePass(){
    this.txtError='';

    if (this.passActual==''){
      this.txtError="La contraseña actual no puede estar vacia !!!"
      return;
    }


    if (this.passNuevo1.length<8){
      this.txtError="La nueva contraseña debe de contener al menos 8 caracteres !!!";
      return;
    }
    if (!this.validar_clave(this.passNuevo1)){
        this.txtError="La nueva contraseña debe contener al menos un número, una mayúscula y una minúscula !!!";
        return;
    }

    if (this.passNuevo1!=this.passNuevo2){
      this.txtError="Las nuevas contraseñas no coinciden !!!";
      return;
    }

    this.loader=true;
    this.usersService.changePass(this.user.id, this.passActual, this.passNuevo1).subscribe(
      (success)=>{
          setTimeout(() => {
            this.loader=false;
            this.displayChangePass=false;
            this.service.add({ key: 'tst',severity:'success', life:1000, summary: 'Proceso correcto', detail:'La contraseña ha sido modificada satisfactoriamente'});
          }, 500);
      },
      (error)=>{
          console.log(error);
          this.loader=false;
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al cambiar la contraseña', detail: error.error.Message });
      });
  }


  validar_clave(contrasenna)
		{
			if(contrasenna.length >= 6)
			{
				var mayuscula = false;
				var minuscula = false;
				var numero = false;
				var caracter_raro = false;

				for(var i = 0;i<contrasenna.length;i++)
				{
					if(contrasenna.charCodeAt(i) >= 65 && contrasenna.charCodeAt(i) <= 90)
					{
						mayuscula = true;
					}
					else if(contrasenna.charCodeAt(i) >= 97 && contrasenna.charCodeAt(i) <= 122)
					{
						minuscula = true;
					}
					else if(contrasenna.charCodeAt(i) >= 48 && contrasenna.charCodeAt(i) <= 57)
					{
						numero = true;
					}
					else
					{
						caracter_raro = true;
					}
				}
				if(mayuscula == true && minuscula == true && numero == true)
				{
					return true;
				}
			}
			return false;
		}


}
