import {Component} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/core/auth/auth.service';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    nombre:string='';
    doctor=false;
    constructor(public app: AppComponent, public authService:AuthService) {
        
        let user = this.authService.getCurrentUser();
        this.nombre=user.nombre;
        this.doctor=user.doctor;
    }

}
