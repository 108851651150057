import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';




import { HttpClientModule } from '@angular/common/http';
import {TableModule} from 'primeng/table';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';

import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';


import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {KeyFilterModule} from 'primeng/keyfilter';
import {BlockUIModule} from 'primeng/blockui';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ProgressSpinnerModule} from 'primeng/progressspinner'
import { HeaderbarComponent } from './components/headerbar/headerbar.component';
import { ErrordataComponent } from './components/errordata/errordata.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CalendarModule, EditorModule, ToastModule } from 'primeng';






@NgModule({
  declarations: [
    HeaderbarComponent,
    ErrordataComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    PanelModule,
    ProgressSpinnerModule,
    BlockUIModule,
    ConfirmDialogModule,
    ToastModule,
    HttpClientModule,
    ButtonModule,
    PanelModule,
    TableModule,
    TabViewModule,
    InputTextModule,
    SidebarModule,
    KeyFilterModule,
    FileUploadModule,
    EditorModule,
    ListboxModule,
    ColorPickerModule,
    InputTextareaModule,
    SelectButtonModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    MessageModule,
    MessagesModule,
    InputMaskModule,
    OverlayPanelModule,
    GalleriaModule,
    OverlayPanelModule,
    LightboxModule,
    InputTextareaModule,
    InputSwitchModule,
    ToolbarModule,
    FileUploadModule,
    PickListModule,
    MultiSelectModule,
    ChartModule,
    DeviceDetectorModule.forRoot(),
    CalendarModule


  ],
  exports:[
    PickListModule,
    HeaderbarComponent,
    ErrordataComponent,
    ScrollPanelModule,
    PanelModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    BlockUIModule,
    ConfirmDialogModule,
    ToastModule,
    HttpClientModule,
    ButtonModule,
    PanelModule,
    TableModule,
    TabViewModule,
    InputTextModule,
    SidebarModule,
    KeyFilterModule,
    EditorModule,
    ProgressSpinnerModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    FileUploadModule,
    ListboxModule,
    ColorPickerModule,
    InputTextareaModule,
    SelectButtonModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    MessageModule,
    MessagesModule,
    InputMaskModule,
    OverlayPanelModule,
    GalleriaModule,
    OverlayPanelModule,
    LightboxModule,
    InputTextareaModule,
    InputSwitchModule,
    ToolbarModule,
    FileUploadModule,
    MultiSelectModule,
    ChartModule,
    CalendarModule
  ]
})
export class SharedModule { }
