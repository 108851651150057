import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-errordata',
  templateUrl: './errordata.component.html',
  styleUrls: ['./errordata.component.scss']
})
export class ErrordataComponent implements OnInit {
  @Input() public error = 'hola hola';
  @Output() public reintentar = new EventEmitter();

  
  constructor() { }

  ngOnInit() {
  }

  retry(){
    this.reintentar.emit();
  }

}
