export class userDTO {
    id: number=0;
    login: string='';
    nif: string='';
    nombre: string='';
    email: string='';
    doctor: boolean=false;
    especialidad: string='';
    direccion: string='';
    cp: string='';
    poblacion: string='';
    telefono: string='';
    baja: boolean=false;
    firma: string='';
    tratamiento: string;
    permisos:permisoDTO=new permisoDTO();
}

export class permisoDTO{
    idUser: number=0;
    config: number=0;
    clientes: number=0;
    historial: number=0;
    tratamientos: number=0;
    productos: number=0;
    agenda: number=0;
    informes: number=0;
}

export class userPermisoDTO{
    id: number=0;
    nombre: string;
    permiso:number=-1;
}
