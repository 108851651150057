import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { clienteDTO } from 'src/app/shared/model/clienteDTO';
import { FuncionesService } from 'src/app/core/services/funciones.service';
import { ClientesService } from 'src/app/core/services/clientes.service';
import {MenuItem} from 'primeng/api';
import { Table } from 'primeng/table';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
    selector: 'clientes-cmp',
    templateUrl: 'clientes.component.html',
    styleUrls: ['./clientes.component.scss']
})

export class
ClientesComponent implements OnInit {
  status=0;
  loader=true;
  readOnly=false;
  txtError='';
  selectedClient:any;
  clientes:any[];
  cols = [
    { field: 'id', header: 'Código' },
    { field: 'fechaAlta', header: 'F.Alta' },
    { field: 'nombre', header: 'Nombre' },
    { field: 'telefono', header: 'Teléfono' },
    { field: 'email', header: 'E-mail' },
    { field: '', header: 'Acciones' },
  ];
  @ViewChild('dt', {static: true}) dt: Table;

  constructor(private clientesService:ClientesService , private router:Router, private auth :AuthService) { }

  ngOnInit() {
    this.readOnly=(this.auth.currentSession.user.permisos.clientes==1);
    this.clientesService.getClientes().subscribe(
      (data)=>{
          this.clientes=data;
          this.loader=false;
          this.status=1;
      },
      (error)=>{
        this.status=-1;
        this.loader=false;
      }
    );
  }


    reintentar(){
        this.ngOnInit();

    }

  newCli(){
    this.router.navigate(["/clientes/edit", 0]);
  }

  editCli(cli:clienteDTO){
    this.router.navigate(["/clientes/edit", cli.id]);
  }


  exportExcel() {
    import("xlsx").then(xlsx => {
        //const worksheet = xlsx.utils.json_to_sheet(this.getCars());
        const worksheet = xlsx.utils.json_to_sheet(this.clientes);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "clientes");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
}



}


