import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BonoDTO, tratamientosSeleccionablesBonoDTO, BonoCliDTO } from '../../shared/model/bonoDTO';
import { Observable, bindCallback } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { diaDTO, diaSemanaDTO, agendaDTO, agendaCitaDTO, entradaDTO, permisoAgendaDTO } from 'src/app/shared/model/agendaDTO';
import { DatePipe } from '@angular/common';
import { permisoDTO } from 'src/app/shared/model/userDTO';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};
  public colores=['#2ecc71', '#3498db', '#ffda79', '#ff793f', '#706fd3', '#00d2d3', '#f368e0', '#786fa6', '#63cdda','#ff4757'];

  constructor(private http:HttpClient, private auth:AuthService, private datePipe:DatePipe) {

  }

  getColor(i:number){
    if (i>=10)
      i=Math.trunc(i/10);

    return this.colores[i];

  }
  /******************* GENERAL****************************** */

  /*Retorna los ejercicios de las agendas */
  getEjercicios(idAgenda:number):Observable<number[]>{
    return this.http.get<number[]>(this.auth.endPoint + 'agenda/ejercicios/'+idAgenda).pipe(timeout(8000));;
  }





  /******************* AGENDAS ****************************** */

  /*Obtiene las agendas de un usuario*/

  getAgenda(idAgenda:number):Observable<agendaDTO>{
    return this.http.get<agendaDTO>(this.auth.endPoint + 'agenda/'+idAgenda).pipe(timeout(8000));
  }


  getAgendas():Observable<agendaDTO[]>;
  getAgendas(idUser:number):Observable<agendaDTO[]>;
  getAgendas(idUser?:number):Observable<agendaDTO[]>{
    if (idUser)
      return this.http.get<agendaDTO[]>(this.auth.endPoint + 'agenda/agendas/'+idUser).pipe(timeout(8000));
    else
       return this.http.get<agendaDTO[]>(this.auth.endPoint + 'agenda/agendas').pipe(timeout(8000));
  }

  saveAgenda(datos:agendaDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'agenda', datos,this.httpOptions).pipe(timeout(8000));
  }

  savePermisos(datos:any):Observable<any>{
    
    return this.http.post<any>(this.auth.endPoint + 'agenda/permisos', datos, this.httpOptions).pipe(timeout(8000));
  }

  delAgenda(idAgenda:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'agenda/'+idAgenda, this.httpOptions).pipe(timeout(8000));;
  }

  /*Lista de dias de una agendas*/
  getDias(idAgenda:number, ejercicio:number):Observable<any[]>{
    return this.http.get<any[]>(this.auth.endPoint + 'agenda/dias/'+idAgenda+'/'+ejercicio).pipe(timeout(8000));;
  }

  /*Lista de dias de varias agendas*/
  getDiass(ejercicio:number, agendas:number[]):Observable<any[]>{
    let url='agenda/agendas/' + ejercicio + '/agendas?';
    agendas.forEach(element => {
      url=url+'agendas='+element+'&';
    });

    return this.http.get<any[]>(this.auth.endPoint + url).pipe(timeout(8000));
  }

  /* guarda los datos de una cita */
  saveCita(datos:agendaCitaDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'agenda/cita', datos,this.httpOptions).pipe(timeout(8000));;
  }
  delCita(idAgenda:number, idCita:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'agenda/cita/'+idAgenda+'/'+idCita, this.httpOptions).pipe(timeout(8000));;
  }


  /*Elimina solapaciones con la agenda general */
  arreglaCalendar(dias:diaDTO[]):diaDTO[]{
    const festivos0=dias.filter(dia=>dia.idAgenda==0 && dia.festivo);

    let rdo = dias.filter(function(current) {
        if (current.idAgenda==0 || !current.festivo)
          return current;
        else{
          let i = festivos0.findIndex(item=>item.fecha==current.fecha);
          if (i<0)
            return current;
        }
    });

    return rdo;

  }




  esSuficientementeGrande(elemento) {
    return elemento >= 10;
  }




  getDia(idAgenda:number, fecha:string):Observable<diaDTO>{
    return this.http.get<diaDTO>(this.auth.endPoint + 'agenda/dia/'+idAgenda+'/'+fecha).pipe(timeout(8000));;
  }

  saveDia(tipo:number, datos:diaDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'agenda/dia/'+tipo, datos,this.httpOptions).pipe(timeout(8000));;
  }
  saveDiaFestivo(datos:diaDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'agenda/dias/festivo', datos,this.httpOptions).pipe(timeout(8000));;
  }

  createCalendar(semana:diaSemanaDTO[]):Observable<any>{
    let datos={dias:semana};
    return this.http.post<any>(this.auth.endPoint + 'agenda/calendario', datos, this.httpOptions).pipe(timeout(8000));;
  }
  delCalendar(id:number, ejercicio:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'agenda/calendario/'+id+'/'+ejercicio, this.httpOptions).pipe(timeout(8000));;
  }

  cloneCalendar(ejercicio:number, idAgenda:number){
    let datos={ejercicio:ejercicio, idAgenda:idAgenda};
    return this.http.post<any>(this.auth.endPoint + 'agenda/calendario/clonar', datos, this.httpOptions).pipe(timeout(8000));;
  }


  saveEntrada(entrada:entradaDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'planning/entrada', entrada ,this.httpOptions).pipe(timeout(8000));
  }


  /*********************************************** SCHEDULER *********************************************/

  /*Dados unos dias y unas agendas-->Obtiene la base del planning*/
getPlanningBase(agendas:number[], fechas:string[]):Observable<any>{

    let datos={agendas:agendas,fechas:fechas};

    return this.http.post<any>(this.auth.endPoint + 'planning', datos, this.httpOptions).pipe(timeout(8000));

}

/*Dados unos dias y unas agendas-->Obtiene las entradas*/
getPlanning(agendas:number[], fechas:Date[]):Observable<any>{

  let datos={agendas:agendas,fechas:fechas};
  return this.http.post<any>(this.auth.endPoint + 'planning/entradas', datos, this.httpOptions).pipe(timeout(8000));
}

getEntrada(idEntrada:number):Observable<entradaDTO>{
  return this.http.get<entradaDTO>(this.auth.endPoint + 'planning/entrada/'+idEntrada).pipe(timeout(8000));
}
delEntrada(idEntrada:number):Observable<any>{
  return this.http.delete<any>(this.auth.endPoint + 'planning/entrada/'+idEntrada, this.httpOptions).pipe(timeout(8000));
}



    /*Rellena los eventos de un calendario*/

  getCalendarEvents(dias:diaDTO[], calendarios:agendaDTO[]):any[]{
      let ini = "08:00:00";
      let fi = "22:00:00";
      let agendas:number[]=[];
      dias=this.arreglaCalendar(dias);
      dias.forEach(element => {
        if (element.desde1<ini)
          ini=element.desde1;
        if (element.desde2<ini)
          ini=element.desde2;
        if (element.hasta1>fi)
          fi=element.hasta1
        if (element.hasta2>fi)
          fi=element.hasta2
        if (agendas.findIndex(item=>item==element.idAgenda)<0)
          agendas.push(element.idAgenda);
      });


      var ev=[];
      var color;
      dias.forEach(dia=>{
          let inicio=ini;
          let fin=fi;
          if (dia.festivo){
            const d=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd');
            ev.push({ start: d, end: d, allDay:true, rendering: 'background',backgroundColor:'red'});
          }else{

            if (dia.idAgenda==0){
                var d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                var d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + fin;

                if (dia.desde1==null && dia.desde2==null){
                  ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#d7d7d7'});
                }else{
                  if (dia.desde1!=null && dia.hasta1!=null){
                    d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                    d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde1;
                    ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#d7d7d7'});
                    inicio=dia.hasta1;
                  }

                  if (dia.desde2!=null && dia.hasta2!=null){
                    d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                    d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde2;
                    ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#d7d7d7'});
                    inicio=dia.hasta2;
                  }
                  if (inicio<fin){
                    d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                    d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + fin;
                    ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#d7d7d7'});
                  }
                }
              }else{
                let calx=calendarios.find(item=>item.idAgenda==dia.idAgenda);

                color=calx.color;

                if (dia.desde1!=null && dia.hasta1!=null){
                  d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde1;
                  d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.hasta1;
                  ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:color,   resourceId:dia.idAgenda});;

                }

                if (dia.desde2!=null && dia.hasta2!=null){
                  d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde2;
                  d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.hasta2;
                  ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:color,   resourceId:dia.idAgenda});

                }
              }
          }
      });

      return ev;


  }

  getPlanningEvents(dias:diaDTO[]):any[]{
    let ini = "08:00:00";
    let fi = "22:00:00";
    let agendas:number[]=[];
    dias.forEach(element => {
      if (element.desde1<ini)
        ini=element.desde1;
      if (element.desde2<ini)
        ini=element.desde2;
      if (element.hasta1>fi)
        fi=element.hasta1
      if (element.hasta2>fi)
        fi=element.hasta2
      if (agendas.findIndex(item=>item==element.idAgenda)<0)
        agendas.push(element.idAgenda);
    });

    var ev=[];
    var color;
    dias.forEach(dia=>{
        let inicio=ini;
        let fin=fi;
        if (dia.idAgenda>0){
          if (dia.festivo){
            const d=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd');
            ev.push({ start: d, end: d, allDay:true, rendering: 'background',backgroundColor:'red', resourceId:dia.idAgenda});
          }else{
            var d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
            var d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + fin;
            if (dia.desde1==null && dia.desde2==null){
                ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#bdc3c7', resourceId:dia.idAgenda});
            }else{
                if (dia.desde1!=null && dia.hasta1!=null){
                  d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                  d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde1;
                  ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#bdc3c7', resourceId:dia.idAgenda});
                  inicio=dia.hasta1;
                }
                if (dia.desde2!=null && dia.hasta2!=null){
                        d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                        d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + dia.desde2;
                        ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#bdc3c7', resourceId:dia.idAgenda});
                        inicio=dia.hasta2;
                }
                if (inicio<fin){
                        d1=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + inicio;
                        d2=this.datePipe.transform(dia.fecha, 'yyyy-MM-dd') + 'T' + fin;
                        ev.push({ start: d1, end: d2, allDay:false, rendering: 'background', backgroundColor:'#bdc3c7', resourceId:dia.idAgenda});
                }
            }
          }
        }

        dia.entradas.forEach(entrada=>{
          var d1=this.datePipe.transform(entrada.fecha, 'yyyy-MM-dd') + 'T' + entrada.desde;
          var d2=this.datePipe.transform(entrada.fecha, 'yyyy-MM-dd') + 'T' + entrada.hasta;
          var txt='';

          if (entrada.estado==0)
            txt='<i style="color:blue; font-size:16px;" class="fas fa-phone-square-alt"></i> ';
          if (entrada.estado==1)
            txt='<i style="color:green;font-size:16px;" class="fas fas fa-clipboard-check"></i> ';
          if (entrada.estado==2)
            txt='<i style="color:orange;font-size:16px;" class="fas fa-hourglass"></i> ';
          if (entrada.estado==3)
            txt='<i style="color:yellow;font-size:16px;" class="fas fa-thumbs-up"></i> ';
          if (entrada.estado==4)
            txt='<i style="color:black;font-size:16px;" class="fas fa-frown"></i> ';

          txt+=entrada.nombre==null ? '' : entrada.nombre;
          if (entrada.tratamiento!=null && entrada.tratamiento!='')
            txt+='<br><strong>'+entrada.tratamiento+'</strong>';
          if (entrada.observaciones!=null && entrada.observaciones!='')
            txt+='<br>'+ this.nl2br(entrada.observaciones);
          ev.push({ start: d1, end: d2, title:txt, textColor:"black",  backgroundColor:entrada.color, resourceId:dia.idAgenda, id:entrada.id });

        });
    });

    return ev;


}

nl2br (str) {
  if (typeof str == 'undefined' || str == null) {
      return '';
  }
  const breakTag = '<br>';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}
}
