<canvas #canvas
    (mousedown)  = "mouseDown($event)" 
    (touchstart)  = "touchStart($event)" 
    (mousemove)   = "mouseMove($event)"
    (touchmove)   = "touchMove($event)"
    (mouseup)    = "mouseUp($event)"
    (touchend)    = "touchEnd($event)"
    >
</canvas>
  

