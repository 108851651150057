import {NgModule} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppMenuComponent, AppSubMenuComponent} from './modules/layout/menu/app.menu.component';
import {AppTopBarComponent} from './modules/layout/topbar/app.topbar.component';
import {AppFooterComponent} from './modules/layout/footer/app.footer.component';
import { AppRoutingModule } from './app-routing.module';
import { FuncionesService } from './core/services/funciones.service';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';

import { TokenInterceptor } from './core/auth/token.interceptor';


import { ClientesModule } from './modules/clientes/clientes.module';


import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { SchedulerComponent } from './modules/scheduler/scheduler.component';
import { AccountComponent } from './modules/account/account.component';
import { CartelComponent } from './modules/cartel/cartel.component';
import { SharedModule } from './shared/shared.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';




@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        ClientesModule,
        SharedModule,
        CoreModule,
        BrowserAnimationsModule,



    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        HomeComponent,
        LoginComponent,
        SchedulerComponent,
        AccountComponent,
        CartelComponent,






    ],
    exports:[
        SharedModule,

    ],


    providers: [
        FuncionesService,
        FormBuilder,
        ConfirmationService,
        MessageService,
        FormBuilder,
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
