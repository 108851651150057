import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { configDTO } from 'src/app/shared/model/configDTO';
import { consentimientoGeneralDTO } from 'src/app/shared/model/consentimientoGeneralcliDTO';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

es={
                    firstDayOfWeek: 0,
                    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
                    monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
                    monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                    today: 'Aceptar',
                    clear: 'Borrar',
                    dateFormat: 'HH:mm',
                    weekHeader: 'Wk'
                  };
  private localStorageService;
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};
  public diasSemana=['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sábado', 'domingo'];
  public meses=['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio','agosto', 'septiembre', 'octutbre', 'noviembre', 'diciembre'];
  constructor(private http:HttpClient, private auth:AuthService) {
    this.localStorageService = localStorage;
  }


  getDoctors():Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'doctors').pipe(timeout(8000));
  }


  getConfig():Observable<any>{
    return this.http.get<any>(this.auth.endPoint + 'config').pipe(timeout(8000));
  }

  saveConfig(registro:configDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'config', registro, this.httpOptions).pipe(timeout(8000));
  }





  getConsentimientos():Observable<any>{
    return this.http.get<any>(this.auth.endPoint + 'config/consentimientos').pipe(timeout(8000));
  }
  getConsentimiento(id:number):Observable<any>{
    return this.http.get<any>(this.auth.endPoint + 'config/consentimientos/'+id).pipe(timeout(8000));
  }
  saveConsentimiento(registro:consentimientoGeneralDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'config/consentimientos', registro, this.httpOptions).pipe(timeout(8000));
  }
  delConsentimiento(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'config/consentimientos/'+id, this.httpOptions).pipe(timeout(8000));;
  }

  showDemoConsentimientoGeneral(id:number):Observable<any>{
    return this.http.get(this.auth.endPoint + 'pdf/consentimientoGeneral/demo/' + id, {responseType: 'arraybuffer'}).pipe(timeout(15000));
  }

  getDispositivos():Observable<any>{
    return this.http.get<any>(this.auth.endPoint + 'config/dispositivos').pipe(timeout(8000));
  }
  bajaDispositivo(id:number):Observable<any>{
    return this.http.get<any>(this.auth.endPoint + 'config/dispositivos/baja/'+id).pipe(timeout(8000));
  }





  getColor(){
    var c = this.localStorageService.getItem('currentColor')

    if (c==null || c<0)
      return 0;
    else
      return c;

  }

  setColor(idColor){
    this.localStorageService.setItem('currentColor', idColor);
  }


  getBrush(){
    var c = this.localStorageService.getItem('currentBrush')
    if (c==null)
      return 0;
    else
      return c;

  }

  setBrush(idBrush){
    this.localStorageService.setItem('currentBrush', idBrush);
  }


  calculaEdad(fecha) {

    try {


      if (fecha == undefined || fecha == null || fecha == '0000-00-00') {
        return '';
      }

      fecha = this.fecha_yyyymmdd(fecha);
      if (fecha=='')
        return '';
      const hoy = new Date();
      const cumpleanos = new Date(fecha);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      const m = hoy.getMonth() - cumpleanos.getMonth();



      if (isNaN(m)){
        return 'Fecha erronea';
      }

      if (m < 0 || (m == 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
      }

      return  edad + ' años. ';

    } catch (error) {
      return '';
    }

  }

  fecha_yyyymmdd(f: string) {

    if (f == null || f == undefined || f.length < 10) {
      return f;
    }

    const expresion=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

    if (f.match(expresion)) {
      return f;
    }

    const rdo = f.substr(6, 4) + '-' + f.substr(3, 2) + '-' + f.substr(0, 2);
    return rdo;

  }

}
