import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from 'src/app/core/services/clientes.service';
import { tratamientoCliDTO, tratamientoDTO } from 'src/app/shared/model/tratamientoDTO';
import { imageDTO } from 'src/app/shared/model/imageDTO';
import { TratamientosService } from 'src/app/core/services/tratamientos.service';
import { lineaTratamientoDTO } from 'src/app/shared/model/lineaTratamientoDTO';
import { ProductosService } from 'src/app/core/services/productos.service';
import { productoDTO, listaproductoDTO, movalmacenDTO } from 'src/app/shared/model/productoDTO';

import { pagoDTO } from 'src/app/shared/model/pagoDTO';
import { FuncionesService } from 'src/app/core/services/funciones.service';
import { intervencionDTO } from 'src/app/shared/model/intervencionDTO';
import { GlobalService } from 'src/app/core/services/global.service';
import { DatePipe } from '@angular/common'
import { AuthService } from 'src/app/core/auth/auth.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { userDTO } from 'src/app/shared/model/userDTO';
import { UsersService } from 'src/app/core/services/users.service';
import { ConfirmationService, MessageService, TabView } from 'primeng';



interface drop {
  name: string;
  value: string;
}

@Component({
  selector: 'app-tratamiento',
  templateUrl: './tratamiento.component.html',
  styleUrls: ['./tratamiento.component.scss']
})
export class TratamientoComponent implements OnInit {

  @ViewChild ('des1', {static: true}) des1: ElementRef;
  @ViewChild ('des2', {static: true}) des2: ElementRef;
  @ViewChild ('des3', {static: true}) des3: ElementRef;
  @ViewChild('solapas',{static: true}) solapas: TabView ;

  tratamiento:tratamientoCliDTO=new tratamientoCliDTO();
  idCliente:number=0;
  idTratamiento:number=0;
  loader=false;
  error=false;
  txtError='';
  visibleSeleccionTrata=false;
  visibleSeleccionProds=false;
  loadingTemplates=false;
  statusNuevoTrata=0;
  tratamientosMaestros:tratamientoDTO[]=[];
  productosMaestros:listaproductoDTO[]=[];
  fRevision:Date;
  tmpFecha:Date;
  doctors:userDTO[]=[];
  currentDoctor:userDTO=null;
  selectedFotos:number[]=[];


  faceFotos:string[]=[];
  imgThumbs:imageDTO[]=[];
  imgThumbs2:imageDTO[]=[];
  displayEditLinea:boolean=false;
  displayEditProd:boolean=false;
  displayEditPago:boolean=false;
  displaySelTemplate:boolean=false;
  currentLinea:lineaTratamientoDTO=new lineaTratamientoDTO();
  currentProd:movalmacenDTO=new movalmacenDTO();
  currentPago:pagoDTO=new pagoDTO();
  currentPago_fecha:string;
  pieles = [ {name: 'Fototipo 1', value: 1},{name: 'Fototipo 2', value: 2},{name: 'Fototipo 3', value:3},{name: 'Fototipo 4', value:4},{name: 'Fototipo 5', value:5},{name: 'Fototipo 6',value:6} ];
  envejes = [ {name: 'Grado 1', value: 1},{name: 'Grado 2', value: 2},{name: 'Grado 3', value:3},{name: 'Grado 4', value:4}];
  tipos = [ {name: "CAPILAR", value: "CAPILAR"},{name: "CORPORAL", value: "CORPORAL"}, {name: "FACIAL", value: "FACIAL"},{name: "OTROS", value: "OTROS"}];
  selectedPiel:any;
  selectedEnvejes:any;
  selectedIntervencion=0;
  tmpTipo:drop;
  faceToFace=false;
  es = {};
  status:number=0;


  totalTratas=0;
  totalProductos=0;
  totalActa=0;
  pagado=false;

  types = [
    {label: 'Efectivo', value: 0, icon: 'fas fa-money-bill-wave', color:"#2ecc71"},
    {label: 'Tarjeta', value: 1, icon: 'fas fa-credit-card', color:"#3498db"},
    {label: 'Transferencia', value: 2,icon: 'fas fa-university', color:"#9b59b6"},
    {label: 'Otros', value: 3, icon: 'fas fa-question', color:"#7f8c8d"}
  ];



  constructor(private route: ActivatedRoute,
              private router:Router,
              private clientesService: ClientesService,
              private tratamientosService: TratamientosService,
              private productosService: ProductosService,
              private confirmationService:ConfirmationService,
              private funciones:FuncionesService,
              private service:MessageService,
              private datePipe:DatePipe,
              private usersService:UsersService,
              private auth:AuthService

            ) {

              this.es=this.funciones.fecha_es();


            }


  ngOnInit() {
    this.loader=true;
    this.solapas.activeIndex=(this.auth.lastPage.startsWith('/clientes/intervencion')) ? 1 : 0;
    this.idTratamiento =this.route.snapshot.params.id;


    this.clientesService.getTratamientoCli(this.idTratamiento).subscribe(
        (data)=>{
            this.tratamiento=data;
            this.fRevision = (this.tratamiento.fRevision==null) ? null : new Date(this.tratamiento.fRevision);
            this.selectedPiel = (this.tratamiento.piel==null) ? null : this.pieles.find(item=>item.value==this.tratamiento.piel);
            this.selectedEnvejes = (this.tratamiento.envejecimiento==null) ? null : this.envejes.find(item=>item.value==this.tratamiento.envejecimiento);
            this.loader=false;
            this.status=1;
            this.totales();
            this.usersService.getDoctors().subscribe(
              (data)=>{
                this.doctors=data;
                let i = this.doctors.findIndex(item=>item.id==this.tratamiento.idDoctor);
                if (i>=0){
                  this.currentDoctor=this.doctors[i];
                }
              }
            );
        },
        (error)=>{
            this.txtError=error.message;
            this.error=true;
            this.status=-1;
        }
      );

      this.clientesService.getThumbsIntervenciones(this.idTratamiento).subscribe(
        (data)=>{
          this.imgThumbs=data;
        }
      );
  }


  cancel(){
    this.router.navigate(["/clientes/edit", this.tratamiento.idCliente ]);
  }

  delete(){
    this.confirmationService.confirm({
      message: 'Desea eliminar el tratamiento actual ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        this.loader=true;
        this.clientesService.delTratamientoCli(this.tratamiento.id).subscribe(
          (data)=>{
            if(data.success){
              this.totales();
              this.cancel();
            }else{
              this.notificaError('Error al eliminar el tratamiento.', data.message);
            }
          },
          (error)=>{
            this.notificaError('DB-Error al eliminar el tratamiento.', error.message);
          }
        );
      },
      reject: () => {
          return;
      }
  });
  }


  save(){

    if (this.currentDoctor!=null){
      this.tratamiento.idDoctor=this.currentDoctor.id;
    }

    this.tratamiento.fRevision=this.datePipe.transform(this.fRevision, 'yyyy-MM-dd');
    this.tratamiento.piel = (this.selectedPiel) ? this.selectedPiel.value : null;
    this.tratamiento.envejecimiento = (this.selectedEnvejes) ? this.selectedEnvejes.value : null;
    this.loader=true;
    this.clientesService.saveTratamientoCli(this.tratamiento).subscribe(
      (data)=>{
        this.loader=false;
        if (data.success) {
          this.totales();
          this.service.add({ key: 'tst',severity:'success', life:1000, summary: 'Guardado correcto', detail:'Datos guardados satisfactoriamente'});

        } else {
          this.notificaError("Error al guardar los datos,", data.message);
        }
      },
      (error)=>{
        this.notificaError("DB-Error al guardar los datos,", error.message);

      }
    );
  }
  changeSolapa(){
    this.selectedFotos=[];
  }


//#region *************** TRATAMIENTOS
openNewTrata(){
    this.loader=true;
    this.tratamientosService.getTratamientos(true).subscribe(
      (data)=>{
          this.tratamientosMaestros=data;
          this.statusNuevoTrata=1;
          this.visibleSeleccionTrata = true
          this.loader=false;
      },
      (error)=>{
        this.loader=false;
      }
    );
  }

nuevoTratamiento(idTrata){
    this.loader=true;
    this.visibleSeleccionTrata = false;
    let trat=this.tratamientosMaestros.find(item=>item.id===idTrata);
    let nuevaLinea:lineaTratamientoDTO=new lineaTratamientoDTO;
    nuevaLinea.tratamiento=trat.id;
    nuevaLinea.descripcion=trat.descripcion;
    nuevaLinea.precio=trat.precio;
    nuevaLinea.tipo=trat.tipo;
    nuevaLinea.idCliente=this.tratamiento.idCliente;
    nuevaLinea.idTratamiento=this.tratamiento.id;
    this.clientesService.saveLineaTratamientoCli(nuevaLinea).subscribe(
        (data)=>{
          this.loader=false;
          if(data.success){

            this.tratamiento.lineaTratamientos.push(data.data);
            this.totales();
          }else{
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: data.message});
          }
        },
        (error)=>{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: error.message});
          this.loader=false;
        }
      );
}

editLinea(linea:lineaTratamientoDTO){
  this.currentLinea=Object.assign({},linea);
  this.tmpTipo={name: this.currentLinea.tipo, value:this.currentLinea.tipo};
  this.displayEditLinea=true;
  setTimeout(()=>{ // this will make the execution after the above boolean has changed
    this.des1.nativeElement.focus();
  },300);
}

saveLinea(){
  this.displayEditLinea=false;
  this.loader=true;
  this.currentLinea.tipo=this.tmpTipo.value;

  this.clientesService.saveLineaTratamientoCli(this.currentLinea).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.lineaTratamientos.findIndex(item=>item.id==data.data.id);
        if (i<0)
          this.tratamiento.lineaTratamientos.push(data.data);
        else
          this.tratamiento.lineaTratamientos[i]=data.data;

        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: data.message });

    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: error.message });
    }

  );

}

delLinea(){
  this.displayEditLinea=false;
  this.loader=true;
  this.clientesService.delLineaTratamientoCli(this.currentLinea.id).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.lineaTratamientos.findIndex(item=>item.id==data.data.id);
        this.tratamiento.lineaTratamientos.splice(i,1);
        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: data.message });
    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: error.message });
    }
  );

}


tieneTipo(tipo:string){
  return this.tratamiento.lineaTratamientos.findIndex(item=>item.tipo==tipo)>=0;
}

//#endregion *************** TRATAMIENTOS







//#region 2 -  intervenciones ***************************************************************************************
selTemplate(){
  this.loadingTemplates=true;
  this.imgThumbs2=[];
  this.displaySelTemplate=true;
  this.clientesService.getThumbsCli('images', this.tratamiento.idCliente).subscribe(
    (data)=>{
        this.imgThumbs2=data;
        setTimeout(() => {
          this.loadingTemplates=false;
        }, 200);
    },
    (error)=>{
      this.loadingTemplates=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al obtener las plantillas de imagen.', detail: error.message });
      this.loadingTemplates=false;
    }
  );
}

selectTemplate(name){

  this.loader=true;
  this.displaySelTemplate=false;
  let inter = new intervencionDTO();
  inter.idTratamiento=this.tratamiento.id;
  inter.template = name;
  inter.idCliente=this.tratamiento.idCliente;

  let us = this.auth.getCurrentUser();
  if (us.doctor){
    inter.idDoctor=us.id;
  }



  this.clientesService.saveIntervencion(inter).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        this.router.navigate(["/clientes/intervencion", data.data.id]);
        return;
      }
      this.notificaError("Error al crear la intervención en el tratamiento.", data.message);
    },
    (error)=>{
      this.notificaError("DB-Error al crear la intervención en el tratamiento.", error.message);
    }
  );

}

onSelectIntervencion(id){
  this.selectedIntervencion=id;
  this.router.navigate(["/clientes/intervencion", id]);
}

resetSel(){
  this.selectedFotos=[];
}



selectFoto(id){

  let index=this.selectedFotos.findIndex(item=>item==id);
  if (index>=0){
    this.selectedFotos.splice(index,1);
    return;
  }
  if (this.selectedFotos.length<4){
    this.selectedFotos.push(id);
    return;
  }

  if (this.selectedFotos.length>=4){
    this.selectedFotos=[];
    this.selectedFotos.push(id);
    return;
  }

}

isSelected(id){
  let index=this.selectedFotos.findIndex(item=>item==id);
  return (index>=0);
}

fechaFoto(index){

  if (index>this.selectedFotos.length){
    return "";
  }

  let id=this.selectedFotos[index];

  let index2=this.imgThumbs.findIndex(item=>item.id==id);
  if (index2>=0)
    return this.datePipe.transform(this.imgThumbs[index2].fecha, 'dd/MM/yyyy hh:mm');
  else
    return ""
}

openFaceToFace(){
  this.loader=true;
  let f3 = this.selectedFotos.length>2 ? this.selectedFotos[2] : 0;
  let f4 = this.selectedFotos.length>3 ? this.selectedFotos[3] : 0;
  this.clientesService.getFaceToFace(this.selectedFotos[0], this.selectedFotos[1], f3, f4).subscribe(
    (data)=>{
      this.faceFotos=data;
      this.loader=false;
      this.faceToFace=true;
    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al obtener las imágenes de base de datos', detail: error.message });
    }
  );
}


//#endregion 2  - intervenciones ***************************************************************************************

/*** parte 3: productos */
openNewProd(){

    this.loader=true;
    this.productosService.getProductos(true).subscribe(
      (data)=>{
          this.productosMaestros=data;
          
          this.statusNuevoTrata=1;
          this.visibleSeleccionProds = true
          this.loader=false;
      },
      (error)=>{
        console.log(error);
        this.loader=false;
      }
    );
}


getTrazos(idInter){
  let inter=this.tratamiento.intervencionesCli.find(item=>item.id==idInter);
  if (inter!=null)
    return inter.trazos;
  else
    return "";

}

nuevoProd(codigo){

    this.visibleSeleccionProds = false;
    let p=this.productosMaestros.find(item=>item.codigo===codigo);
    let nuevo:movalmacenDTO=new movalmacenDTO;
    nuevo.codigo=p.codigo;
    nuevo.descripcion=p.descripcion;
    nuevo.proveedor=p.proveedor;
    nuevo.cantidad=-1;
    nuevo.precio=p.precio;
    nuevo.idTratamiento=this.tratamiento.id;
    nuevo.idCliente=this.tratamiento.idCliente;
    this.editProd(nuevo);


}

editProd(linea: movalmacenDTO){

  this.currentProd=Object.assign({},linea);
  this.currentProd.cantidad=this.currentProd.cantidad*-1;
  this.tmpTipo={name: this.currentLinea.tipo, value:this.currentLinea.tipo};
  this.displayEditProd=true;
  setTimeout(()=>{ // this will make the execution after the above boolean has changed
    this.des2.nativeElement.focus();
  },100);

}

saveProd(){
  this.displayEditProd=false;
  this.loader=true;
  this.currentProd.cantidad=this.currentProd.cantidad*-1;
  this.currentProd.tipo=2;
  this.productosService.saveMovAlmacen(this.currentProd).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.movAlmacen.findIndex(item=>item.id==data.data.id);
        if (i<0)
          this.tratamiento.movAlmacen.push(data.data);
        else
          this.tratamiento.movAlmacen[i]=data.data;
        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: data.message });

    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: error.message });
    }

  );

}

delProd(){
  this.displayEditProd=false;
  this.loader=true;
  this.productosService.delMovAlmacen(this.currentProd.id).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.movAlmacen.findIndex(item=>item.id==data.data.id);
        this.tratamiento.movAlmacen.splice(i,1);
        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: data.message });
    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: error.message });
    }
  );

}


/*** parte 4: pagos */

completarPago(){

  this.currentPago=new pagoDTO();
  this.tmpFecha=new Date();

  this.currentPago.descripcion="Pago del tratamiento";
  this.displayEditPago=true;
  this.currentPago.importe = this.totalTratas+this.totalProductos-this.totalActa;
  setTimeout(()=>{
    this.des3.nativeElement.focus();
  },300);
}

editPago(linea: pagoDTO){

  if (linea==null)
    linea= new pagoDTO();

  this.tmpFecha=(linea.fecha==null) ? new Date() : new Date(linea.fecha);
  this.currentPago=Object.assign({},linea);
  this.displayEditPago=true;
  setTimeout(()=>{
    this.des3.nativeElement.focus();
  },300);

}

savePago(){
  this.displayEditPago=false;
  this.loader=true;
  this.currentPago.fecha=this.datePipe.transform(this.tmpFecha, 'yyyy-MM-dd');
  this.currentPago.idTratamiento=this.tratamiento.id;
  this.currentPago.idCliente=this.tratamiento.idCliente;

  this.clientesService.savePagoCli(this.currentPago).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.pagosCli.findIndex(item=>item.id==data.data.id);
        if (i<0)
          this.tratamiento.pagosCli.push(data.data);
        else
          this.tratamiento.pagosCli[i]=data.data;
        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: data.message });

    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al guardar el registro en la base de datos', detail: error.message });
    }

  );

}

delPago(){
  this.displayEditPago=false;
  this.loader=true;
  this.clientesService.delPagoCli(this.currentPago.id).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.tratamiento.pagosCli.findIndex(item=>item.id==data.data.id);
        this.tratamiento.pagosCli.splice(i,1);
        this.totales();
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: data.message });
    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el registro en la base de datos', detail: error.message });
    }
  );

}





totales(){
  let suma=0;
  this.tratamiento.lineaTratamientos.forEach(element => {
    suma += element.precio == null ? 0 : element.precio;
  });
  this.totalTratas=suma;

  suma=0;
  this.tratamiento.movAlmacen.forEach(element => {
    suma += element.precio == null || element.cantidad==null ? 0 : element.precio * element.cantidad *-1;
  });
  this.totalProductos=suma;

  suma=0;
  this.tratamiento.pagosCli.forEach(element => {
    suma += element.importe == null ? 0 : element.importe;
  });
  this.totalActa=suma;

  this.pagado=(this.totalProductos+this.totalTratas-this.totalActa)<=0;
}


  totalNumProductos(){
    let suma=0;
    this.tratamiento.movAlmacen.forEach(element => {
      suma += element.cantidad==null ? 0 : element.cantidad *-1;
    });
    return suma;
  }





  notificaError(summary:string, detail:string){
    this.loader=false;
    this.service.add({ key: 'tst', severity: 'error', summary: summary, detail: detail});
  }


  print(){
      this.loader=true;
      this.tratamientosService.printPresupuesto(this.tratamiento.id).subscribe(
      (data)=>{
            let file = new Blob([data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            this.loader=false;
            window.open(fileURL);
      },
      (error)=>{
        this.notificaError('Database error', error.message);
      });
  }


  abrirConsentimiento(id){
    this.loader=true;
    const fileName="tr_"+id+'.pdf';
    this.clientesService.openFile(this.tratamiento.idCliente, 'signed', fileName).subscribe(
        (data)=>{
            let file = new Blob([data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            this.loader=false;
            window.open(fileURL);
        },
      (error)=>{
        this.notificaError('Database error', error.message);
      });
  }

  abrirDemoConsentimiento(id){
    let param={id:id};
    this.loader=true;
    this.tratamientosService.printConsentimiento(param).subscribe(
    (data)=>{
          let file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          this.loader=false;
          window.open(fileURL);
    },
    (error)=>{
      this.notificaError('Database error', error.message);
    });
  }

}
