import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { clienteDTO } from 'src/app/shared/model/clienteDTO';
import { FuncionesService } from 'src/app/core/services/funciones.service';
import { Router, ActivatedRoute } from '@angular/router';
import { historiaDTO } from 'src/app/shared/model/historiaDTO';


import { ClientesService } from 'src/app/core/services/clientes.service';
import { lineaTablaTratamientoDTO, lineaTratamientoDTO } from 'src/app/shared/model/lineaTratamientoDTO';

import { BonosService } from 'src/app/core/services/bonos.service';
import { tratamientosSeleccionablesBonoDTO, BonoDTO, BonoCliDTO } from 'src/app/shared/model/bonoDTO';
import { FilesService } from 'src/app/core/services/files.service';
import { imageDTO } from 'src/app/shared/model/imageDTO';
import { Lightbox } from 'ngx-lightbox';
import { TratamientosService } from 'src/app/core/services/tratamientos.service';
import { tratamientoDTO, tratamientoCliDTO } from 'src/app/shared/model/tratamientoDTO';
import { GlobalService } from 'src/app/core/services/global.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { consentimientoGeneralCliDTO } from 'src/app/shared/model/consentimientoGeneralcliDTO';
import { UsersService } from 'src/app/core/services/users.service';
import { ConfirmationService, MessageService, OverlayPanel, TabView } from 'primeng';
import { DatePipe,Location } from '@angular/common';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  @ViewChild('nombre',{static: true}) nombre: ElementRef;
  @ViewChild('nombreHis',{static: true}) nombreHis: ElementRef;
  @ViewChild('opTicketUsado',{static: true}) opTicketUsado: OverlayPanel;
  @ViewChild('solapas',{static: true}) solapas: TabView ;


  cliente=new clienteDTO();
  loader=true;
  vengoDe='';
  alergias:historiaDTO[]=[];
  enfermedades:historiaDTO[]=[];
  medicaciones:historiaDTO[]=[];
  intervenciones:historiaDTO[]=[];
  antecedentesF:historiaDTO[]=[];
  antecedentesM:historiaDTO[]=[];
  currentHis:historiaDTO=new historiaDTO();
  status=0;
  bonos:BonoDTO[]=[];
  doctors:any[];
  currentDoctor:any=null;
  faceToFace=false;
  selectedFotos:number[]=[];
  faceFotos:string[]=[];
  historiasTipo=['Alergias', 'Medicación actual', 'Enfermedades', 'Intervenciones Quirurgicas', 'Antecedentes familiares', 'Antecedentes médico-estéticos'];
  tipo=0;
  txtHistoria='';
  idHistoria=0;
  displayDialog = false;
  fecha:string;
  idRef=0;
  delOption='';
  tmpClick=false;
  txtError='';
  edad='';
  selectedImage='';

  es= {
    firstDayOfWeek: 1,
    dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
    dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
    dayNamesMin: [ "D","L","M","X","J","V","S" ],
    monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
    monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
    today: 'Hoy',
    clear: 'Borrar'
  }

  expanded=false;
  tratamientos:lineaTablaTratamientoDTO[]=[];
  tratamientosMaestros:tratamientoDTO[]=[];
  consentimientos:consentimientoGeneralCliDTO[]=[];
  selectedTrat=0;
  totalImportes=0;
  totalImportesBonos=0;
  generos= [{label: 'Mujer', value: 0},{label: 'Hombre', value: 1}];
  genero= {label: 'Mujer', value: 0};
  colsTratamientos = [
    { field: 'id', header: 'Código' },
    { field: 'fechaAlta', header: 'Fecha' },
    { field: 'tratamientos', header: 'Tratamientos' },
    { field: 'productos', header: 'Productos' },
    { field: 'fechaRevision', header: 'F. revisión' },
    { field: 'revision', header: 'Revisión' },
    { field: '', header: 'Acciones' },
  ];

  visibleSeleccionTrata = false
  statusNuevoTrata=0;


  selectedBono=0;
  tmpLineaTrat:lineaTratamientoDTO=new lineaTratamientoDTO();
  tmpTratamientosSeleccionables:tratamientosSeleccionablesBonoDTO[]=[];
  visibleSeleccionBono:boolean=false;
  statusNuevoBono=0;
  displayEditBono=false;
  currentBonoCli:BonoCliDTO=new BonoCliDTO;



  imgThumbs:imageDTO[]=[];
  docThumbs:imageDTO[]=[];
  uploadingItems="";
  editingItems="";




  constructor(  private clientesService: ClientesService,
                private bonosService:BonosService,
                private funciones:FuncionesService,
                private router:Router,
                private route: ActivatedRoute,
                private confirmationService:ConfirmationService,
                private filesService:FilesService,
                 private location:Location,
                private tratamientosService:TratamientosService,
                private lightbox:Lightbox,
                private globalService:GlobalService,
                private auth:AuthService,
                private datePipe:DatePipe,
                private usersService:UsersService,
                private service:MessageService) {
                }

  reintentar(){
    this.ngOnInit();
  }
  ngOnInit() {
    this.loader=true;
    this.solapas.activeIndex=(this.auth.lastPage.startsWith('/clientes/tratamiento')) ? 1 : 0;
    let id =this.route.snapshot.params.id;

    if (id==0){
      this.status=1;
      this.nombre.nativeElement.focus();
      this.loader=false;
      return;
    }

    if (id>0){

      this.clientesService.getThumbsCli('images', id).subscribe(
        (data)=>{
            this.imgThumbs=data;
        }
      );


      this.clientesService.getThumbsCli('docs',id).subscribe(
        (data)=>{
            this.docThumbs=data;
        }
      );

      this.clientesService.getConsentimientosGenerales(id).subscribe(
        (data)=>{
            this.consentimientos=data;
        }
      );

      this.clientesService.getCliente(id).subscribe(
        (data)=>{
            this.cliente=data;
            this.fecha = this.funciones.fecha_ddmmyyyy(this.cliente.fechaNac);

            this.alergias=this.filtraHistorias(0);
            this.medicaciones=this.filtraHistorias(1);
            this.enfermedades=this.filtraHistorias(2);
            this.intervenciones=this.filtraHistorias(3);
            this.antecedentesF=this.filtraHistorias(4);
            this.antecedentesM=this.filtraHistorias(5);
            this.genero=this.generos[this.cliente.genero];
            this.edad=this.globalService.calculaEdad(this.cliente.fechaNac);

            this.cliente.tratamientosCli.forEach(trat => {

              let row = new lineaTablaTratamientoDTO;
              row.id=trat.id;
              row.fecha=trat.fecha;
              row.fechaRevision=trat.fRevision;
              row.revision=trat.revision;

              trat.lineaTratamientos.forEach(linea => {
                if (row.tratamientos==''){
                  row.tratamientos = linea.descripcion;
                }else{
                  row.tratamientos = row.tratamientos + '<br />' + linea.descripcion;
                }
                row.importe += linea.precio;
              });

              trat.movAlmacen.forEach(mlinea => {
                if (row.productos==''){
                  row.productos = mlinea.descripcion + ' (' + (-1 * mlinea.cantidad) +' uds.)';
                }else{
                  row.productos = row.productos + '<br />' + mlinea.descripcion + ' (' + (-1*mlinea.cantidad) +' uds.)';
                }
                row.importe += mlinea.precio * mlinea.cantidad * -1;
              });

              this.totalImportes+=row.importe;

              this.tratamientos.push(row);
            });
            this.loader=false;
            this.status=1;
        },
        (error)=>{
          this.loader=false;
          this.status=-1;
          this.txtError=error.message;
        }
      );

    };

  }

  save() {


    this.cliente.genero=this.genero.value;


    if (this.cliente.nombre==null || this.cliente.nombre=='' || this.cliente.nombre.length<5) {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'El campo nombre no puede estar vacio o con menos de 5 carcateres!!!' });
      return false;
    }
    if (this.cliente.telefono==null || this.cliente.telefono=='' || this.cliente.telefono.length<9) {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'El campo teléfono no puede estar vacio o con menos de 9 números!!!' });
      return false;
    }

    if (this.fecha!=null && this.fecha!='' && !this.funciones.esFecha(this.fecha)){
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error de validación', detail: 'El campo `Fecha Nacimiento` no tiene el formato correcto!!!' });
      return;
    }

    this.loader=true;

    this.cliente.fechaNac = this.fecha!=null && this.fecha!='' ? this.funciones.fecha_yyyymmdd(this.fecha) : null;

    this.cliente.historiasCli=[];

    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.alergias);
    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.enfermedades);
    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.medicaciones);
    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.intervenciones);
    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.antecedentesF);
    this.cliente.historiasCli.push.apply(this.cliente.historiasCli, this.antecedentesM);

    this.clientesService.saveCliente(this.cliente).subscribe(
      (data)=> {
        this.loader=false;
        if (data.success) {

          this.cliente=data.data;
          this.location.replaceState("/clientes/edit/"+data.data.id);
          this.service.add({ key: 'tst',severity:'success', life:1000, summary: 'Guardado correcto', detail:'Datos guardados satisfactoriamente'});

        } else {
          this.notificaError("Error al guardar los datos", data.info);
        }

      },
      (error) => {
        this.notificaError("DB-Error al guardar los datos", error.message);

      }
    );


  }

  abrirConsentimiento(e, id){
    this.loader=true;
    e.preventDefault();
    let fileName='cons_'+id+'.pdf';
    this.clientesService.openFile(this.cliente.id, 'signed', fileName).subscribe(
      (data)=>{
          this.loader=false;
          let file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      },
      (error)=>{
          this.notificaError("Error al obtener el archivo", error.message);
      });

  }

  showDemo(e, id){
    e.preventDefault();
    this.loader=true;
    this.globalService.showDemoConsentimientoGeneral(id).subscribe(
    (data)=>{
          let file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          this.loader=false;
          window.open(fileURL);
    },
    (error)=>{
      this.notificaError('Database error', error.message);
    });

  }

  calculaEdad(){
    this.edad=this.globalService.calculaEdad(this.fecha);
  }

  filtraHistorias(type) {
    if (this.cliente.historiasCli) {
      return this.cliente.historiasCli.filter(x => x.tipo == type);
    }
    else
      return null;
  }

  addHistoria(tipo){
    this.currentHis=new historiaDTO();
    this.currentHis.tipo=tipo;
    this.currentHis.idCliente=this.cliente.id;
    this.displayDialog = true;

    setTimeout(() => {
      this.nombreHis.nativeElement.focus();
    }, 500);

  }


 editHistoria(tipo, index){

    this.currentHis=new historiaDTO();
    let hh=this.historias(this.currentHis.tipo);
    this.currentHis= hh[index];

    this.displayDialog = true;

    setTimeout(() => {
      this.nombreHis.nativeElement.focus();
    }, 500);
 }

  saveHistoria(){
    let x=0;
    this.displayDialog = false;

    if (this.currentHis.id==0){
        this.idHistoria-=1
        this.currentHis.id=this.idHistoria;
    }

    let hh=this.historias(this.currentHis.tipo);

    x=hh.findIndex(item=>item.id==this.currentHis.id)
    if (x<0)
        hh.push(this.currentHis);
    else
        hh[x].descripcion=this.currentHis.descripcion;
    }


  historias(tipo){
    switch (tipo){
      case 0:
        return this.alergias;
      case 1:
        return this.medicaciones;
      case 2:
        return this.enfermedades;
      case 3:
        return this.intervenciones;
      case 4:
        return this.antecedentesF;
      case 5:
        return this.antecedentesM;
      default:
        return [];
    }
  }


  cancelHistoria(){
    this.displayDialog = false;
  }

  delHistoria(){

    let hh=this.historias(this.currentHis.tipo);
    let x=hh.findIndex(item=>item.id==this.currentHis.id)
    if (x>=0)
        hh.splice(x,1);
    this.displayDialog = false;
  }

  cancel(){
    this.router.navigateByUrl('/clientes');
  }

  delete(){
    this.confirmationService.confirm({
      message: 'Desea eliminar el cliente actual ?????',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        setTimeout(() => {
          this.deleteCliente();
        }, 200);


      },
      reject: () => {
          return;
      }
  });
  }

  deleteCliente(){
    this.confirmationService.confirm({
      message: 'Esta completamente seguro ?????',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
          this.clientesService.delCliente(this.cliente.id).subscribe(
            (data)=>{
              if (data.success){
                this.router.navigate(["/clientes"]);
              }else{
                this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el cliente', detail: data.message });
              }
            },
            (error)=>{
              this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el cliente', detail: error.message });
            }
          );
      },
      reject: () => {
          return;
      }
  });
  }










/***************TRATAMIENTOS**************************************************************************************************** */
  openNewTrata(){
    this.visibleSeleccionTrata = true
    this.statusNuevoTrata=0;

    let user=this.auth.getCurrentUser();
    if (!user.doctor){
      this.usersService.getDoctors().subscribe(
        (data)=>{
          this.doctors=data;
          if (data.length>0){
            this.currentDoctor=data[0];
          }
        }
      );
    }

    this.tratamientosService.getTratamientos(true).subscribe(
      (data)=>{
          this.tratamientosMaestros=data;
          this.statusNuevoTrata=1;

      },
      (error)=>{
        this.statusNuevoTrata=-1;
      }
    );

  }

  editTratamiento(idTrata){
    this.router.navigate(["/clientes/tratamiento", idTrata]);
  }

  nuevoTratamiento(idTrata){
      this.visibleSeleccionTrata = false;
      let trat=this.tratamientosMaestros.find(item=>item.id===idTrata);



      this.confirmationService.confirm({
        message: 'Desea crear un nuevo tratamiento de <br><strong>'+ trat.descripcion+'<strong>?',
        header: 'Nuevo Tratamiento',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel:"Aceptar",
        rejectLabel:"Cancelar",
        accept: () => {
          this.loader=true;
          let nuevaLinea:lineaTratamientoDTO=new lineaTratamientoDTO;
          nuevaLinea.tratamiento=trat.id;
          nuevaLinea.descripcion=trat.descripcion;
          nuevaLinea.precio=trat.precio;
          nuevaLinea.tipo=trat.tipo;
          nuevaLinea.idCliente=this.cliente.id;

          if (this.currentDoctor==null){
              let doc=this.auth.getCurrentUser();
              nuevaLinea.idDoctor=doc.id;
          }else{
            nuevaLinea.idDoctor=this.currentDoctor.id;
          }


          this.clientesService.saveLineaTratamientoCli(nuevaLinea).subscribe(
          (data)=>{
            this.loader=false;
            if(data.success){

              this.router.navigate(["/clientes/tratamiento", data.data.idTratamiento]);
            }else{
              this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: data.message});
            }
          },
          (error)=>{
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: error.message});
            this.loader=false;
          }
        );
      },
      reject: () => {
          return;
      }
  });
  }




//#region BONOS**************************************************************************************************** */

totalBonos(){
  let suma=0;
  this.cliente.bonosCli.forEach(element => {
    suma+=element.precio;
  });
  return suma;
}

tickets(idBono:number){
    let bono=this.cliente.bonosCli.find(item=>item.id==idBono);
    let usados=0;
    this.cliente.tratamientosCli.forEach(linea => {
        linea.lineaTratamientos.forEach(element => {
          if (element.idBono==idBono){
            usados++;
          }
        });
    });

    let arr =  [];
    for (let i=1; i<=bono.sesiones; i++){
        arr.push(i<=usados);
    }

    return arr;

}

openTicketUsado(event, idBono:number, n:number, overlaypanel: OverlayPanel) {

    this.tmpClick=true;
    let contador=0;
    this.tmpLineaTrat=null;

    this.cliente.tratamientosCli.forEach(linea => {
      linea.lineaTratamientos.forEach(element => {
        if (element.idBono==idBono){
          if (contador==n){
            this.tmpLineaTrat=element;
          }
          contador++;
      }
      });
  });

  if (this.tmpLineaTrat!=null){
    overlaypanel.toggle(event);
  }

}

openSeleccionaTicket(event, idBono:number, overlaypanel: OverlayPanel) {
    this.tmpClick=true;
    this.tmpTratamientosSeleccionables=[];
    overlaypanel.toggle(event);
    this.bonosService.getDisponibilidadBono(idBono).subscribe(
      data=>{
        this.tmpTratamientosSeleccionables=data;
      },
      error=>{

      }
    );





}

openNewBono(){
  this.visibleSeleccionBono = true
  this.statusNuevoBono=0;
  this.bonosService.getBonos(true).subscribe(
    (data)=>{
        this.bonos=data;
        this.statusNuevoBono=1;
    },
    (error)=>{
      this.statusNuevoBono=-1;
    }
  );

}

comprarBono(idBono){

  let bonoCli=new BonoCliDTO();
  let bono = this.bonos.filter(item=> item.id==idBono);
  this.currentBonoCli.id=0;
  this.currentBonoCli.idBono=idBono;
  this.currentBonoCli.descripcion=bono[0].descripcion;
  this.currentBonoCli.precio=bono[0].precio;
  this.currentBonoCli.sesiones=bono[0].sesiones;
  this.currentBonoCli.idCliente=this.cliente.id;
  this.currentBonoCli.caduca=null;
  if (bono[0].validez!=null && bono[0].validez>0){
      var dt = new Date();
      dt.setMonth( dt.getMonth() + bono[0].validez );
      this.currentBonoCli.caduca=dt.toISOString().slice(0,10);
  }

  this.visibleSeleccionBono = false;
  this.displayEditBono=true;


}

saveBonoCli(bc:BonoCliDTO){



  this.displayEditBono=false;
  this.loader=true;
  bc.caduca=this.funciones.fecha_yyyymmdd(bc.caduca);
  this.bonosService.saveBonoCli(bc).subscribe(
    (data)=>{
      this.loader=false;
      if (data.success){
        let i = this.cliente.bonosCli.findIndex(item=> item.id==bc.id);
        if (i>=0){
          this.cliente.bonosCli[i]=data.data;
        }
        else{
          this.cliente.bonosCli.push(data.data);
        }
        return;
      }
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: data.message });
    },
    (error)=>{
      this.loader=false;
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: error.message });
    }
  );
}

delBonoCli(bono){
  this.confirmationService.confirm({
    message: 'Desea eliminar el bono seleccionado?',
    header: 'Confirmación',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel:"Aceptar",
    rejectLabel:"Cancelar",
    accept: () => {
      this.bonosService.delBonoCli(bono.id).subscribe(
        (data)=>{
          if(data.success){
            let id=this.cliente.bonosCli.findIndex(item=>item.id===bono.id);
            this.cliente.bonosCli.splice(id,1);
            this.displayEditBono=false;
          }else{
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el bono', detail: data.message});
          }
        },
        (error)=>{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el bono', detail: error.message});
        }
      );
    },
    reject: () => {
        return;
    }
});


}

editBono(bono){
  if (this.tmpClick){
    this.tmpClick=false;
    return;
  }
  this.currentBonoCli=Object.assign({},bono);
  this.currentBonoCli.caduca=this.funciones.fecha_ddmmyyyy(this.currentBonoCli.caduca);
  this.displayEditBono=true;
}

gastarBono(bono:tratamientosSeleccionablesBonoDTO){
  this.confirmationService.confirm({
    message: 'Desea crear el tratamiento de ' + bono.descripcion + ' utilizando este bono?',
    header: 'Confirmación',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel:"Aceptar",
    rejectLabel:"Cancelar",
    accept: () => {
      this.loader=true;


      let nuevaLinea:lineaTratamientoDTO=new lineaTratamientoDTO;
      nuevaLinea.tratamiento=bono.idTratamiento
      nuevaLinea.descripcion=bono.descripcion;
      nuevaLinea.precio=0;
      nuevaLinea.idBono=bono.idBonoCli;
      nuevaLinea.idCliente=this.cliente.id;
      this.clientesService.saveLineaTratamientoCli(nuevaLinea).subscribe(
      (data)=>{
        this.loader=false;
        if(data.success){
          this.router.navigate(["/clientes/tratamiento", data.data.idTratamiento]);
        }else{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: data.message});
        }
      },
      (error)=>{
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error al crear el nuevo tratamiento', detail: error.message});
        this.loader=false;
      });
    },
    reject: () => {
        return;
    }
});
}

//#endregion



/***************IMAGENES Y DOCS**************************************************************************************************** */

onUploadItem(tipo:string, event:any){
  this.editingItems='';
  this.uploadingItems=tipo;
  this.filesService.postFile(tipo,event.files,this.cliente.id).subscribe(
    (data)=>{
        this.uploadingItems="";
        if (data.success && tipo=='images'){
          this.imgThumbs.push(data.data);
          return;
        }
        if (data.success && tipo=='docs'){
          this.docThumbs.push(data.data);
          return
        }
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: data.message });
    },
    (error)=>{
      this.uploadingItems="";
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: error.message });
    }
  );
}

onSelectItem(tipo:string, fileName:string){
  this.selectedImage=fileName;
  this.editingItems='';
  if (this.funciones.esImagen(fileName)){
    this.clientesService.getImage(tipo, this.cliente.id, fileName).subscribe(
      (data)=>{
        let albums=[];
        albums.push({src: data.base64,caption: data.nombre,thumb: null});
        this.lightbox.open(albums, 0);
      }
    );
  }else{
    this.clientesService.openFile(this.cliente.id, 'docs', fileName).subscribe(
      (data)=>{

        if (fileName.endsWith("pdf") || fileName.endsWith("PDF")){
          let file = new Blob([data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);

          window.open(fileURL);
        }else{
          let file = new Blob([data]);
          var fileURL = URL.createObjectURL(file);
          var blob = new Blob([data], { type: 'application/octet-stream' });
          var url = window.URL.createObjectURL(blob);
          var linkElement = document.createElement('a');
          linkElement.setAttribute('href', url);
          linkElement.setAttribute("download", fileName);

          var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
          });
          linkElement.dispatchEvent(clickEvent);
        }

      },
      (error)=>{
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error al intentar abrir el archivo', detail: error.message });

      }

    );
  }
}

onDeleteItem(tipo:string, indice:number, fileName:string){
  this.confirmationService.confirm({
    message: 'Desea eliminar la imagen/documento seleccionado ?',
    header: 'Confirmación',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel:"Aceptar",
    rejectLabel:"Cancelar",
    accept: () => {
      this.clientesService.delFile(tipo, this.cliente.id, fileName).subscribe(
        (data)=>{

          if(data.success){
            if (tipo=='images'){
              this.imgThumbs.splice(indice, 1);
            }else{
              this.docThumbs.splice(indice, 1);
            }
          }else{
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el archivo.', detail: data.message});
          }
        },
        (error)=>{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar el archivo.', detail: error.message});
        }
      );
    },
    reject: () => {
        return;
    }
});
}

onEditItem(tipo:string){
  if (this.editingItems==tipo){
    this.editingItems=""
  }else{
    this.editingItems=tipo;
  }
}




onConfirm(){
  this.service.clear('c');

  if (this.delOption=='cliente'){
    this.clientesService.getCliente(this.cliente.id).subscribe(
      (data)=>{
        if (data.tratamientosCli.length>0){
          this.service.add({ key: 'tst', severity: 'error', summary: 'No se puede eliminar un cliente con tratamientos.', detail: 'Debe eliminar primero todos los tratamientos !!!' });
          return;
        }
        this.deleteCliente();
      },
      (error)=>{
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error en proceso', detail: 'Error al obtener la información del cliente !!!' });
      }
    );
  }



}

notificaError(summary:string, detail:string){
  this.loader=false;
  this.service.add({ key: 'tst', severity: 'error', summary: summary, detail: detail});
}





selectFoto(idx){


  let index=this.selectedFotos.findIndex(item=>item==idx);
  if (index>=0){
    this.selectedFotos.splice(index,1);
    return;
  }
  if (this.selectedFotos.length<4){
    this.selectedFotos.push(idx);
    return;
  }

  if (this.selectedFotos.length>=4){
    this.selectedFotos=[];
    this.selectedFotos.push(idx);
    return;
  }
}

isSelected(idx){

  let index=this.selectedFotos.findIndex(item=>item==idx);
   return (index>=0);
}
resetSel(){
  this.selectedFotos=[];
}

fechaFoto(index){

  if (index>this.selectedFotos.length){
    return "";
  }

  let id=this.selectedFotos[index];

  let index2=this.imgThumbs.findIndex(item=>item.id==id);
  if (index2>=0)
    return this.datePipe.transform(this.imgThumbs[index2].fecha, 'dd/MM/yyyy hh:mm');
  else
    return ""
}

openFaceToFace(){

  this.loader=true;
  this.faceFotos=[];

  if (this.selectedFotos.length>1){
    this.clientesService.getImage('images', this.cliente.id, this.imgThumbs[this.selectedFotos[0]].nombre ).subscribe(
      (data)=>{
        this.faceFotos.push(data.base64);
        this.openFaceToFace2();

      }
    );
    this.clientesService.getImage('images', this.cliente.id, this.imgThumbs[this.selectedFotos[1]].nombre ).subscribe(
      (data)=>{
        this.faceFotos.push(data.base64);
        this.openFaceToFace2();
      }
    );
  }
  if (this.selectedFotos.length>2){
    this.clientesService.getImage('images', this.cliente.id, this.imgThumbs[this.selectedFotos[2]].nombre ).subscribe(
      (data)=>{
        this.faceFotos.push(data.base64);
        this.openFaceToFace2();
      }
    );
  }
  if (this.selectedFotos.length>3){
    this.clientesService.getImage('images', this.cliente.id, this.imgThumbs[this.selectedFotos[3]].nombre ).subscribe(
      (data)=>{
        this.faceFotos.push(data.base64);
        this.openFaceToFace2();
      }
    );
  }
}
openFaceToFace2(){
  if (this.faceFotos.length==this.selectedFotos.length){
      this.loader=false;
      this.faceToFace=true;
  }
}



}
