<div *ngIf="loader" style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
        <p-progressSpinner class="spin"></p-progressSpinner>
</div>


<div *ngIf="!isAuthenticated" class="fondo">

  <div class="loginBox">
      <form id="login-form"  [formGroup]="loginForm" (ngSubmit)="submitLogin()" novalidate>

        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 etiqueta">
                  <label for="input">NIF</label>
              </div>
              <div class="ui-g-12 ui-md-6 ui-lg-2">
                  <input formControlName="nif" style="width: 100%;" class="box" type="text" pInputText>
                  <p class="error" *ngIf="submitted && error?.code == 1 && !loginForm.controls.nif.hasError('required')">{{error.message}}</p>
                  <p class="error" *ngIf="submitted && loginForm.controls.nif.hasError('required')">El campo usuario es obligatorio</p>
              </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 etiqueta">
                  <label for="input">Usuario/email</label>
              </div>
              <div class="ui-g-12 ui-md-6 ui-lg-2">
                  <input formControlName="username" style="width: 100%;" class="box" type="text" pInputText>
                  <p class="error" *ngIf="submitted && error?.code == 1 && !loginForm.controls.username.hasError('required')">{{error.message}}</p>
                  <p class="error" *ngIf="submitted && loginForm.controls.username.hasError('required')">El campo usuario es obligatorio</p>
              </div>
        </div>
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 etiqueta">
                  <label for="input">Contraseña</label>
              </div>
              <div class="ui-g-12 ui-md-6 ui-lg-2">
                  <input formControlName="password" style="width: 100%;" class="box" type="password" pInputText>
              </div>
        </div>

        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 etiqueta">

              </div>
              <div class="ui-g-12 ui-md-6 ui-lg-2">
                  <p-checkbox formControlName="recordar" label="Recordar credenciales" binary="true"></p-checkbox>

              </div>
        </div>

        <div *ngIf="txtError!=''" class="ui-g form-group">
            <div class="ui-g-12 ui-md-9 ui-lg-4" style="text-align: center;">
                  <p class="error"><i class="fas fa-exclamation-triangle"></i> {{txtError}}</p>
              </div>
        </div>




        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-xl-1 etiqueta">

            </div>

            <div class="ui-g-12 ui-md-6 ui-lg-2" style="text-align: right">
                <button type="submit" form="login-form" pButton icon="pi pi-check" title="Entrar en el sistema"  label="Entrar"></button>
            </div>
        </div>

          </form>
  </div>

</div>

<div *ngIf="isAuthenticated">
  <button type="button" class="miBtn" form="login-form" pButton icon="fa fa-check" label="Salir" (click)="logOut()"></button>
</div>




