<p-blockUI [blocked]="loader">
    <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
        <p-progressSpinner class="spin"></p-progressSpinner>
    </div>
</p-blockUI>

<p-confirmDialog [baseZIndex]="200" [style]="{width: '50vw'}"></p-confirmDialog>
<p-toast [baseZIndex]="300" key="tst" position="top-center" [style]="{marginTop: '35vh', width:'500px'}"></p-toast>




<div [hidden]="status==0" class="ui-fluid">
    <div class="ui-g">
        <div class="ui-lg-12" style="max-width: 1200px;">
            <div class="card card-w-title">
                    <p class="migaPan">
                            <a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>
                            <a [routerLink]="['/clientes']">Clientes </a>
                            <i class="fas fa-greater-than miguita"></i>
                            <a [routerLink]="['/clientes/edit', tratamiento.idCliente]">Cliente ({{tratamiento.idCliente}})</a>
                            <i class="fas fa-greater-than miguita"></i>
                            Tratamiento
                        </p>
                        <div class="card card-w-title">

                                <app-headerbar
                                    [titulo]="'Ficha de tratamiento'"
                                    [status]="status"
                                    [showPrint]="true"
                                    (save)="save()"
                                    (delete)="delete()"
                                    (cancel)="cancel()"
                                    (print)="print()"
                                    >
                                </app-headerbar>

                <div class="divSeccion">



                    <p-table  #dt1  [value]="tratamiento.lineaTratamientos" selectionMode="single">

                        <ng-template pTemplate="caption">
                            <div class="ui-g">
                                    <div class="ui-g-8 title">Presupuesto - Tratamientos</div>
                                    <div class="ui-g-4 btnAdd">
                                        <button type="button" style="width: 160px;" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="openNewTrata()" label="Nuevo tratamiento" title="Crear un nuevo tratamiento"></button>
                                    </div>
                                </div>
                        </ng-template>

                    <ng-template pTemplate="header" let-columns>

                        <tr>
                            <th style="width: 120px;" >Tipo</th>
                            <th style="text-align: left">Tratamiento</th>
                            <th style="width: 90px;">Bono</th>
                            <th style="width: 120px;">Consentimiento</th>
                            <th style="width: 100px;">Precio</th>

                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-linea>
                        <tr [pSelectableRow]="linea" title="Editar linea de tratamiento">
                            <td style="text-align: center" (click)="editLinea(linea)">{{linea.tipo}}</td>
                            <td (click)="editLinea(linea)">{{linea.descripcion}}</td>
                            <td (click)="editLinea(linea)" style="text-align: right">{{linea.idBono==0 ? '' : linea.idBono}}</td>
                            <td (click)="abrirConsentimiento(linea.id)" title="Ver consentimiento firmado" *ngIf="linea.firmado" class="firmado">Firmado</td>
                            <td (click)="abrirDemoConsentimiento(linea.id)" title="Ver consentimiento" *ngIf="!linea.firmado" class="pendiente">Pendiente</td>
                            <td (click)="editLinea(linea)" style="text-align: right" >{{linea.precio | number:'0.2-50':'es'}}  €</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="4" style="text-align: right">Total tratamientos</td>
                            <td style="text-align: right; font-weight: bold;" >{{totalTratas | number:'0.2-50':'es'}}  €</td>
                        </tr>
                        <tr>
                            <td colspan="4" style="text-align: right">Total Productos</td>
                            <td style="text-align: right; font-weight: bold;" >{{totalProductos | number:'0.2-50':'es'}}  €</td>
                        </tr>
                        <tr>
                            <td style="border-right: none">
                                <p-checkbox readonly="true" [(ngModel)]="pagado" label="Pagado" binary="true"></p-checkbox>
                            </td>
                            <td style="border: none" colspan="3" style="text-align: right; font-weight: bold">Total</td>
                            <td style="text-align: right; font-weight: bold;" >{{totalProductos+totalTratas | number:'0.2-50':'es'}}  €</td>
                        </tr>
                        <tr *ngIf="(totalActa>0) && (totalTratas+totalProductos-totalActa)!=0" >
                            <td colspan="4" style="text-align: right">Entregado a cuenta</td>
                            <td style="text-align: right; font-weight: bold;" >{{totalActa | number:'0.2-50':'es'}}  €</td>
                        </tr>
                        <tr *ngIf="(totalTratas+totalProductos-totalActa)!=0">
                            <td colspan="4" style="text-align: right">Pendiente de pago</td>
                            <td style="text-align: right; font-weight: bold;" [class.rojo]="totalProductos+totalTratas-totalActa>0" >{{totalTratas+totalProductos-totalActa | number:'0.2-50':'es'}}  €</td>
                        </tr>

                    </ng-template>

                    </p-table>
                </div>
            </div>

            <div class="card no-margin" >
                <p-tabView #solapas (onChange)="changeSolapa()">
                    <p-tabPanel header="Informe" leftIcon="fas fa-file-medical fa-2x">
                                <h1>Informe del tratamiento</h1>
                                <div style="min-height: 400px;">
                                        <div class="margin-10">
                                            <h4>Doctor/a</h4>
                                            <div style="width:350px">
                                            <p-dropdown [options]="doctors" [(ngModel)]="currentDoctor" placeholder="Seleccione un doctor " optionLabel="nombre"></p-dropdown>
                                        </div>
                                        </div>
                                        <div class="margin-10">

                                            <div *ngIf="tieneTipo('FACIAL')" class="p-grid">
                                                <div class="p-col-fixed" style="width:200px">
                                                    <h4>Tipo de piel</h4>
                                                    <p-dropdown [options]="pieles" [(ngModel)]="selectedPiel"  placeholder="Seleccione fototipo" optionLabel="name" [showClear]="true"></p-dropdown>
                                                </div>
                                                <div class="p-col-fixed" style="width:200px">
                                                    <h4>Foto-envejecimiento</h4>
                                                    <p-dropdown [options]="envejes" [(ngModel)]="selectedEnvejes" placeholder="Seleccione grado" optionLabel="name" [showClear]="true"></p-dropdown>
                                                </div>
                                            </div>

                                            <div *ngIf="tieneTipo('CORPORAL')" class="p-grid">
                                                <div class="p-col-fixed" style="width:120px">
                                                    <h4><i style="color: gray;" class="fas fa-weight"></i> Peso (Kg)</h4>
                                                    <input  maxlength="7" type="text"  pInputText pKeyFilter="num" [(ngModel)]="tratamiento.peso" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:120px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-vertical"></i> Talla (cm)</h4>
                                                    <input  maxlength="3" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.talla" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:120px">
                                                    <h4><i style="color: gray;" class="fas fa-percent"></i> I.M.C.</h4>
                                                    <input  maxlength="5" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.imc" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:120px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-horizontal"></i> Pecho (cm)</h4>
                                                    <input  maxlength="3" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.pecho" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:120px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-horizontal"></i> Cintura (cm)</h4>
                                                    <input  maxlength="3" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.cintura" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:140px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-horizontal"></i> Abdomen (cm)</h4>
                                                    <input  maxlength="3" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.abdomen" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:140px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-horizontal"></i> Cadera (cm)</h4>
                                                    <input  maxlength="3" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.cadera" style="text-align: right; width: 70px;">
                                                </div>
                                                <div class="p-col-fixed" style="width:140px">
                                                    <h4><i style="color: gray;" class="fas fa-ruler-horizontal"></i> Muñeca (cm)</h4>
                                                    <input  maxlength="2" type="text" pInputText pKeyFilter="num" [(ngModel)]="tratamiento.muneca" style="text-align: right; width: 70px;">
                                                </div>
                                            </div>
                                        </div>


                                        <div class="ui-g form-group margin-10">
                                            <label>Informe para el presupuesto</label>
                                            <textarea [(ngModel)]="tratamiento.informe" style="width: 100%;min-height:60px;" [rows]="3" pInputTextarea autoResize="true"></textarea>
                                        </div>

                                        <div class="ui-g form-group margin-10">
                                            <label>Informe del tratamiento</label>
                                            <textarea [(ngModel)]="tratamiento.observaciones" style="width: 100%;min-height:60px;" [rows]="3" pInputTextarea autoResize="true"></textarea>
                                        </div>
                                        <div class="ui-g form-group margin-10">
                                            <label>Reacciones adversas</label>
                                            <textarea [(ngModel)]="tratamiento.reacciones" style="width: 100%; min-height:60px;" [rows]="3" pInputTextarea autoResize="true"></textarea>
                                        </div>
                                        <div class="ui-g form-group margin-10">
                                            <h4>Revisión</h4>
                                            <textarea [(ngModel)]="tratamiento.revision" style="width: 100%;min-height:60px;" [rows]="3" pInputTextarea autoResize="true"></textarea>
                                        </div>
                                        <div class="margin-10">
                                            <h4>Fecha revisión</h4>
                                            <p-calendar [inputStyle]="{'width':'150px'}" [(ngModel)]="fRevision" [locale]="es" dateFormat="dd/mm/yy"  [selectOtherMonths]="true" [showIcon]="true" showButtonBar="true"></p-calendar>
                                        </div>
                                </div>
                    </p-tabPanel>

                    <!---------------------I N T E R V E N C I O N E S----------------->
                    <p-tabPanel header="Intervenciones" leftIcon="fas fa-syringe fa-2x">
                                    <div class="contenedor1" >
                                          <div class="p-grid">
                                                <div class="p-col">
                                                    <h1>Intervenciones</h1>
                                                </div>
                                                <div *ngIf="selectedFotos.length>1" class="p-col-fixed" style="width:170px">
                                                        <button type="button" class="ui-button-warning" pButton icon="fas fa-grin-squint" (click)="openFaceToFace()" label="Comparar" title="Comparar imágenes"></button>
                                                </div>
                                                <div *ngIf="selectedFotos.length>0" class="p-col-fixed" style="width:170px">
                                                        <button type="button" class="ui-button-secondary" pButton icon="fas fa-eraser" (click)="resetSel()" label="Reset selección" title="Quitar la selección de imágenes"></button>
                                                </div>
                                                <div class="p-col-fixed" style="width:170px">
                                                    <button type="button" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="selTemplate()" label="Agregar intervención" title="Agregar una nueva intervención"></button>
                                                </div>
                                            </div>
                                            <div class="p-grid">
                                                <div *ngFor="let th of imgThumbs; let i=index;" class="p-col-fixed" style="width:140px; position: relative; ">
                                                        <img [ngClass]="(selectedIntervencion!=th.id) ?'box':'box selected'" src="{{th.base64}}" (click)="onSelectIntervencion(th.id)">
                                                        <img *ngIf="!getTrazos(th.id)==''" class="trazos" [src]="getTrazos(th.id)" (click)="onSelectIntervencion(th.id)">
                                                        <div *ngIf="editingItems=='images'"  title="Eliminar imagen" (click)="onDeleteItem('images', i, th.nombre)" class="icoBorrar"><i class="fa fa-times col1btn" ></i></div>
                                                        <p (click)="selectFoto(th.id)" class="leyenda" [class.selected]="isSelected(th.id)" >{{th.nombre}}<br><span class="ff">{{th.fecha | date:'dd/MM/yyyy'}}</span></p>

                                                </div>
                                            </div>
                                        </div>
                    </p-tabPanel>

                    <!---------------------P R O D U C T O S----------------->
                    <p-tabPanel header="Productos" leftIcon="fas fa-notes-medical fa-2x">
                        <h1>Productos</h1>
                        <div style="min-height: 400px;">
                        <p-table  #dt2 [value]="tratamiento.movAlmacen" selectionMode="single">
                            <ng-template pTemplate="caption">
                                    <div class="ui-g">
                                            <div class="ui-g-8 title">Productos utilizados</div>
                                            <div class="ui-g-4 btnAdd">
                                                <button type="button" style="width: 160px;" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="openNewProd()" label="Agregar produto" title="Agregar un nuevo produto al tratamiento"></button>
                                            </div>
                                        </div>
                            </ng-template>

                            <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 110px;" >Código</th>
                                <th style="text-align: left">Descripción</th>
                                <th style="width: 115px;text-align: left">Lote</th>
                                <th style="width: 210px;" >Proveedor</th>
                                <th style="width: 80px;">Cantidad</th>
                                <th style="width: 100px;">Precio</th>
                                <th style="width: 115px;">Importe</th>
                            </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-linea>
                                <tr [pSelectableRow]="linea" (click)="editProd(linea)" title="Editar producto">
                                    <td>{{linea.codigo}}</td>
                                    <td>{{linea.descripcion}}</td>
                                    <td>{{linea.lote}}</td>
                                    <td>{{linea.proveedor}}</td>
                                    <td style="text-align: right" >{{linea.cantidad*-1 | number:'0.2-50'}}</td>
                                    <td style="text-align: right" >{{linea.precio | number:'0.2-50':'es'}}  €</td>
                                    <td style="text-align: right" >{{linea.cantidad * -1 * linea.precio | number:'0.2-50':'es'}}  €</td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <td colspan="4" style="text-align: right">
                                    Sumas ...
                                    </td>
                                    <td style="text-align: right; font-weight: bold;" >{{totalNumProductos() | number:'0.2-50'}}</td>
                                    <td></td>
                                    <td style="text-align: right; font-weight: bold;" >{{totalProductos | number:'0.2-50':'es'}}  €</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    </p-tabPanel>

                    <!---------------------P A G O S----------------->
                    <p-tabPanel header="Pagos" leftIcon="fas fa-cash-register fa-2x">
                        <h1>Pagos y entregas a cuenta</h1>
                        <div style="min-height: 400px;">
                        <p-table  #dt3 [value]="tratamiento.pagosCli" selectionMode="single">
                            <ng-template pTemplate="caption">
                                    <div class="ui-g">
                                            <div class="ui-g-8 title">Pagos y entregas a cuenta</div>
                                            <div class="ui-g-2 btnAdd">
                                                <button type="button" style="width: 120px;" class="ui-button-secondary" pButton icon="fa fa-plus" (click)="editPago(null)" label="Pago a cta" title="Crear un nuevo pago a cuenta"></button>
                                            </div>
                                            <div class="ui-g-2 btnAdd">
                                                <button [disabled]="(totalTratas+totalProductos-totalActa)<=0" type="button" style="width: 140px;" class="ui-button-secondary" pButton icon="fas fa-cash-register" (click)="completarPago(null)" label="Completar pago" title="Completar el pago de todo el tratamiento"></button>
                                            </div>
                                        </div>
                            </ng-template>

                            <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 120px;" >Fecha</th>
                                <th style="text-align: left">Observaciones</th>
                                <th style="width: 100px;">F.pago</th>
                                <th style="width: 100px;">Importe</th>
                            </tr>
                            </ng-template>

                            <ng-template pTemplate="body" let-linea>
                                <tr [pSelectableRow]="linea" (click)="editPago(linea)" title="Editar pago a cuenta">
                                    <td style="text-align: center" >{{linea.fecha | date:'dd/MM/yyyy'}}</td>
                                    <td>{{linea.descripcion}}</td>
                                    <td style="text-align: center;" [style.color]="types[linea.formaPago].color"><i [class]="types[linea.formaPago].icon"></i></td>
                                    <td style="text-align: right" >{{linea.importe | number:'0.2-50':'es'}}  €</td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <td colspan="3" style="text-align: right">
                                    Sumas ...
                                    </td>
                                    <td style="text-align: right; font-weight: bold;" >{{totalActa | number:'0.2-50':'es'}}  €</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>
</div>

<div class="ui-dialog-buttonpane ui-helper-clearfix center" style="max-width: 1200px; margin-bottom: 50px;">

    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-save" (click)="save()" label="Guardar" title="Guardar datos"></button>
    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-trash" (click)="delete()" title="Eliminar registro" label="Eliminar"></button>
    <button type="button" class="ui-button-secondary miBtn" pButton icon="fa fa-times" (click)="cancel()" label="Cancelar" title="Cancelar edición"></button>

</div>








<!-- desplegable lateral para un nuevo tratamiento  -->
<p-sidebar [(visible)]="visibleSeleccionTrata" [style]="{width:'45em'}" [baseZIndex]="99">
        <h1 style="font-weight:normal">Nuevo tratamiento</h1>
                <p-table  #tm  [value]="tratamientosMaestros"
                          selectionMode="single"
                          [scrollable]="true"
                          scrollHeight="80vh"
                          [columns]="['id', 'tipo','descripcion']"
                          [globalFilterFields]="['tipo','descripcion']"
                          dataKey="id">
                        <ng-template pTemplate="caption">
                                <div>
                                    <div style="text-align: left">
                                        <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                        <input type="text" pInputText size="50" placeholder="Buscar..." (input)="tm.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                    </div>
                                </div>
                            </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="text-align: left; width:90px;">Tipo</th>
                        <th style="text-align: left">Tratamiento</th>
                        <th style="text-align: right; width:80px;">Precio</th>
                    </tr>
               </ng-template>
               <ng-template pTemplate="body" let-item>
                    <tr [pSelectableRow]="item" (click)="nuevoTratamiento(item.id)" title="Selección de tratamiento">
                        <td style="text-align: left; width:90px;">{{item.tipo}}</td>
                        <td>{{item.descripcion}}</td>
                        <td style="text-align: right;width:80px;">{{item.precio | number:'0.2-50':'es'}}</td>
                    </tr>
                </ng-template>
            </p-table>
</p-sidebar>

<!-- Formulario de edición un tratamiento -->
<p-dialog header="Editar linea de tratamiento. Ref.{{currentLinea.id}}" [(visible)]="displayEditLinea" [modal]="true" [style]="{width: '600px'}" [baseZIndex]="100">
    <div style="width: 500px; min-height: 170px;">
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 divLabel">
                <label for="input">Tipo</label>
            </div>
            <div class="ui-g-12 ui-md-9">
                <p-dropdown [options]="tipos" [(ngModel)]="tmpTipo" placeholder="Seleccione un tipo" optionLabel="name"></p-dropdown>
            </div>
        </div>
       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Descripción</label>
           </div>
           <div class="ui-g-12 ui-md-9">
               <input #des1 style="width: 100%" type="text" pInputText [(ngModel)]="currentLinea.descripcion">
           </div>
       </div>
       <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 divLabel">
                <label for="input">Bono</label>
            </div>
            <div class="ui-g-12 ui-md-2" >
                <input disabled  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentLinea.idBono" style="text-align: right; width: 70px;">
            </div>
        </div>
       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Precio</label>
           </div>
           <div class="ui-g-12 ui-md-2" >
               <input  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentLinea.precio" style="text-align: right; width: 70px;">
           </div>
       </div>

   </div>
   <p-footer>
       <button type="button" pButton icon="pi pi-check" (click)="saveLinea()" label="Aceptar"></button>
       <button *ngIf="currentLinea.id>0" type="button" pButton icon="pi pi-trash" (click)="delLinea()" label="Eliminar" class="ui-button-secondary"></button>
       <button type="button" pButton icon="pi pi-times" (click)="displayEditLinea=false" label="Cancelar" class="ui-button-secondary"></button>
   </p-footer>
</p-dialog>

<!-- Formulario de seleccion de plantilla -->
<p-dialog header="Seleccione una plantilla imagen"  [(visible)]="displaySelTemplate" [modal]="true" [style]="{width: '580px'}" [baseZIndex]="100">
    <div style="height: 550px; width: 550px;">
        <div *ngIf="loadingTemplates" style="position:absolute;top:25%;left:240px;font-size: 8em;z-index: 10000;">
            <p-progressSpinner class="spin"></p-progressSpinner>
        </div>
        <div class="p-grid">
            <div *ngFor="let th of imgThumbs2; let i=index;" class="p-col-fixed" style="width:140px; position: relative;">
                    <img class="thumbnail" title="Selección de plantilla imagen" src="{{th.base64}}" (click)="selectTemplate(th.nombre)">
                    <p class="leyenda2">{{th.fecha | date:'dd/MM/yyyy HH:mm'}}</p>
            </div>
        </div>
   </div>
   <p-footer>
    <button type="button" pButton icon="pi pi-times" (click)="displaySelTemplate=false" label="Cancelar" title="Cerrar formulario" class="ui-button-secondary"></button>
 </p-footer>
</p-dialog>


<!-- desplegable lateral para un nuevo producto  -->
<p-sidebar [(visible)]="visibleSeleccionProds" [style]="{width:'45em'}" [baseZIndex]="99">
    <h1 style="font-weight:normal">Agregar producto</h1>
            <p-table  #pm [value]="productosMaestros"
                      selectionMode="single"
                      [scrollable]="true"
                      scrollHeight="80vh"
                      [columns]="['codigo', 'precio', 'descripcion']"
                      [globalFilterFields]="['codigo','descripcion']"
                      dataKey="id">
                    <ng-template pTemplate="caption">
                            <div>
                                <div style="text-align: left">
                                    <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                                    <input type="text" pInputText size="50" placeholder="Buscar..." (input)="tm.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                                </div>
                            </div>
                        </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="text-align: left; width:130px;">Código</th>
                    <th style="text-align: left">Descripción</th>
                    <th style="text-align: right; width:80px;">Precio</th>
                </tr>
           </ng-template>
           <ng-template pTemplate="body" let-item>
                <tr [pSelectableRow]="item" (click)="nuevoProd(item.codigo)" title="Selección de producto">
                    <td style="text-align: left; width:130px;">{{item.codigo}}</td>
                    <td>{{item.descripcion}}</td>
                    <td style="text-align: right;width:80px;">{{item.precio | number:'0.2-50':'es'}}</td>
                </tr>
            </ng-template>
        </p-table>
</p-sidebar>

<!-- Formulario de edición un producto -->
<p-dialog [header]="currentProd.id==0 ? 'Agregar un nuevo producto' : 'Editar producto. Ref:'+currentProd.id" [(visible)]="displayEditProd" [modal]="true" [style]="{width: '600px'}" [baseZIndex]="100">
<div style="width: 500px; min-height: 170px;">
    <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-3 divLabel">
            <label for="input">Código</label>
        </div>
        <div class="ui-g-12 ui-md-9">
            <input disabled style="width: 120px" type="text" pInputText [(ngModel)]="currentProd.codigo">
        </div>
    </div>
   <div class="ui-g form-group">
       <div class="ui-g-12 ui-md-3 divLabel">
           <label for="input">Descripción</label>
       </div>
       <div class="ui-g-12 ui-md-9">
           <input #des2 style="width: 100%" type="text" pInputText [(ngModel)]="currentProd.descripcion">
       </div>
   </div>
   <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-3 divLabel">
        <label for="input">Lote</label>
    </div>
    <div class="ui-g-12 ui-md-4">
        <input #des2 style="width: 100%" type="text" pInputText [(ngModel)]="currentProd.lote">
    </div>
</div>

   <div class="ui-g form-group">
       <div class="ui-g-12 ui-md-3 divLabel">
           <label for="input">Precio</label>
       </div>
       <div class="ui-g-12 ui-md-2" >
           <input  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentProd.precio" style="text-align: right; width: 70px;">
       </div>
   </div>

   <div class="ui-g form-group">
    <div class="ui-g-12 ui-md-3 divLabel">
        <label for="input">Cantidad</label>
    </div>
    <div class="ui-g-12 ui-md-2" >
        <input  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentProd.cantidad" style="text-align: right; width: 70px;">
    </div>
   </div>

    <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-3 divLabel">
            <label for="input">Importe</label>
        </div>
        <div class="ui-g-12 ui-md-2" >
            <input readonly style="background-color: lightgrey" type="text" pInputText pKeyFilter="num" [(ngModel)]="currentProd.precio*currentProd.cantidad" style="text-align: right; width: 70px;">
        </div>
    </div>


</div>
<p-footer>
   <button type="button" pButton icon="pi pi-check" (click)="saveProd()" label="Aceptar" title="Guardar cambios"></button>
   <button *ngIf="currentProd.id>0" type="button" pButton icon="pi pi-trash" (click)="delProd()" label="Eliminar" title="Eliminar registro" class="ui-button-secondary"></button>
   <button type="button" pButton icon="pi pi-times" (click)="displayEditProd=false" label="Cancelar" title="Cerrar formulario" class="ui-button-secondary"></button>
</p-footer>
</p-dialog>


<!-- Formulario de edición un pago -->
<p-dialog [header]="currentPago.id==0 ? 'Nuevo pago a cuenta' : 'Editar pago a cuenta. Ref:'+currentPago.id"  [(visible)]="displayEditPago" [modal]="true" [style]="{width: '600px'}">
    <div style="width: 500px;">
        <div class="ui-g form-group">
            <div class="ui-g-12 ui-md-3 divLabel">
                <label for="input">Fecha</label>
            </div>
            <div class="ui-g-12 ui-md-9">
                <p-calendar [selectOtherMonths]="true"  appendTo="body"  [inputStyle]="{'width':'150px'}" [(ngModel)]="tmpFecha" [locale]="es" dateFormat="dd/mm/yy"  [showIcon]="true" showButtonBar="true"></p-calendar>
            </div>
        </div>
       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Observaciones</label>
           </div>
           <div class="ui-g-12 ui-md-9">
               <input #des3 style="width: 100%" type="text" pInputText [(ngModel)]="currentPago.descripcion">
           </div>
       </div>

       <div class="ui-g form-group">
           <div class="ui-g-12 ui-md-3 divLabel">
               <label for="input">Importe</label>
           </div>
           <div class="ui-g-12 ui-md-2" >
               <input  type="text" pInputText pKeyFilter="num" [(ngModel)]="currentPago.importe" style="text-align: right; width: 70px;">
           </div>
       </div>

       <div class="ui-g form-group">
        <div class="ui-g-12 ui-md-3 divLabel">
            <label for="input">Forma de pago</label>
        </div>
        <div class="ui-g-12 ui-md-9">
            <p-selectButton [options]="types" [(ngModel)]="currentPago.formaPago"></p-selectButton>
        </div>
    </div>



   </div>
   <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="savePago()" label="Aceptar" title="Guardar cambios"></button>
    <button *ngIf="currentPago.id>0" type="button" pButton icon="pi pi-trash" (click)="delPago()" label="Eliminar" title="Eliminar registro" class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-times" (click)="displayEditPago=false" label="Cancelar" title="Cerrar formulario" class="ui-button-secondary"></button>
 </p-footer>
</p-dialog>


<!--****** FACE TO FACE-->
<p-sidebar [(visible)]="faceToFace" [fullScreen]="true" [baseZIndex]="100">
    <div>
        <div *ngIf="faceFotos.length==2" class="ui-g faces">
            <div class="ui-g-6 ftf"><div class="face1"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-6 ftf"><div class="face2"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
        </div>

        <div *ngIf="faceFotos.length==3" class="ui-g faces">
            <div class="ui-g-4 ftf"><div class="face11"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-4 ftf"><div class="face22"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
            <div class="ui-g-4 ftf"><div class="face22"><img [src]="faceFotos[2]"></div>{{fechaFoto(2)}}</div>
        </div>
        <div *ngIf="faceFotos.length==4" class="ui-g faces">
            <div class="ui-g-3 ftf"><div class="face111"><img [src]="faceFotos[0]"></div>{{fechaFoto(0)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[1]"></div>{{fechaFoto(1)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[2]"></div>{{fechaFoto(2)}}</div>
            <div class="ui-g-3 ftf"><div class="face222"><img [src]="faceFotos[3]"></div>{{fechaFoto(3)}}</div>
        </div>

    </div>
    <div>
        <div class="botonera1">
            <button pButton type="button" (click)="faceToFace = false" icon="fas fa-times" label="Cerrar" class="ui-button-secondary"></button>
        </div>
        <div class="botonera2">
                <button pButton type="button" (click)="faceToFace = false" icon="fas fa-times" label="Cerrar" class="ui-button-secondary"></button>
        </div>
    </div>
</p-sidebar>
