import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-headerbar',
  templateUrl: './headerbar.component.html',
  styleUrls: ['./headerbar.component.scss']
})
export class HeaderbarComponent implements OnInit {

  @Input() public titulo = 'titulo';
  @Input() public status = 0;
  @Input() public showPrint:boolean = false;
  @Input() public showDelete:boolean = true;
  @Input() public showSave:boolean = true;
  @Input() public showClose:boolean = true;
  @Input() public showNew:boolean = false;
  @Input() public newCaption ="Nuevo registro";
  
  @Output() public save = new EventEmitter();
  @Output() public delete = new EventEmitter();
  @Output() public cancel = new EventEmitter();
  @Output() public nuevo = new EventEmitter();
  @Output() public print = new EventEmitter();
  
  titul_cerrar="Cerrar y descartar cambios";
  constructor() { }

  ngOnInit() {
    if (!this.showSave){
      this.titul_cerrar="Cerrar";
    }
  }

  saveMe(){
    this.save.emit();
  }
  deleteMe(){
    this.delete.emit();
  }
  cancelMe(){
    this.cancel.emit();
  }
  newMe(){
    this.nuevo.emit();
  }
  printMe(){
    this.print.emit();
  }

}
