<div class="p-grid">
    <div class="p-col"><h1>{{titulo}}</h1></div>
    <div *ngIf="status==1" class="p-col-fixed" style="width:auto;text-align: right">
        <button *ngIf="showNew" pButton type="button" icon="fas fa-plus" class="ui-button-warning" title="{{newCaption}}" (click)="newMe()"></button>
        <button *ngIf="showSave" style="margin-left: 3px;" pButton type="button" icon="fas fa-save" class="ui-button-success" title="Guardar los cambios" (click)="saveMe()"></button>
        <button *ngIf="showDelete"  style="margin-left: 3px;" pButton type="button" icon="far fa-trash-alt" class="ui-button-danger" title="Eliminar registro" (click)="deleteMe()"></button>
        <button *ngIf="showPrint" style="margin-left: 3px;" pButton type="button" icon="fas fa-print" class="ui-button-secondary" title="Imprimir" (click)="printMe()"></button>
        <button *ngIf="showClose" style="margin-left: 3px;" pButton type="button" icon="fas fa-times"  title="{{titul_cerrar}}" (click)="cancelMe()"></button>
    </div>
</div>
