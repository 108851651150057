<p-blockUI [baseZIndex]="1300" [blocked]="loader">
    <div style="position:absolute;top:25%;left:50%;font-size: 8em;z-index: 10000;">
        <p-progressSpinner class="spin"></p-progressSpinner>
    </div>
</p-blockUI>

<div [hidden]="status==0" class="ui-g minAlto">
    <div class="ui-lg-12" style="max-width: 1200px;">
            <p class="migaPan">
                    <a title="ir al home" [routerLink]="['/home']"><i class="fas fa-home miguitaHome"></i></a>
                    Clientes <span class="sl" *ngIf="readOnly"> (solo-lectura)</span>
            </p>
        <div class="card card-w-title">
            <h1>Mantenimiento de clientes</h1>
            <app-errordata *ngIf="status<0"
                [error]="txtError"
                (reintentar)="reintentar()">
            </app-errordata>

            <p-table #dt [columns]="cols" 
                         [value]="clientes" 
                         [globalFilterFields]="['nombre','email', 'telefono', 'direccion', 'id']" 
                         [paginator]="true" 
                         [rows]="25"  
                         dataKey="id" 
                         selectionMode="single" 
                         [(selection)]="selectedClient">
                    
                <ng-template pTemplate="caption">
                    <div>
                            
                        <div style="text-align:left; float: left;">
                                <button *ngIf="!readOnly" class="ui-button-secondary" type="button" pButton icon="fa fa-plus" (click)="newCli()" label="Nuevo cliente" title="Crear un nuevo cliente"></button>
                                <button *ngIf="!readOnly" style="margin-left: 3px;" pButton type="button" icon="fas fa-file-excel" class="ui-button-secondary" title="Exportar a excel" (click)="exportExcel()"></button>
                        </div>
                        <div style="text-align: right">        
                            <i class="fa fa-search" style="margin:4px 4px 0 0"></i>
                            <input type="text" pInputText size="50" placeholder="Buscar..." (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto; padding-left: 5px;">
                        </div>
                </div> 
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th style="width: 90px;" pSortableColumn="id">Código<p-sortIcon field="id"></p-sortIcon></th>
                            <th style="width: 120px;" pSortableColumn="fechaAlta">F.Alta<p-sortIcon field="fechaAlta"></p-sortIcon></th>
                            <th pSortableColumn="nombre">Nombre<p-sortIcon field="nombre"></p-sortIcon></th>
                            <th style="width: 120px;"pSortableColumn="telefono">Teléfono<p-sortIcon field="telefono"></p-sortIcon></th>
                            <th pSortableColumn="email">E-mail<p-sortIcon field="email"></p-sortIcon></th>
                        </tr>
                    </ng-template>
                <ng-template pTemplate="body" let-client let-columns="columns">
                    <tr [pSelectableRow]="client" (click)="editCli(client)">
                        <td style="text-align: right" >{{client.id}}</td>
                        <td style="text-align: right" >{{client.fechaAlta | date:'dd/MM/yyyy'}}</td>
                        <td>{{client['nombre']}}</td>
                        <td>{{client.telefono}}</td>
                        <td>{{client.email}}</td>                        
                    </tr>
                </ng-template>
            </p-table>                
        </div>
    </div>
</div>
