import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ɵConsole } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalService } from 'src/app/core/services/global.service';
import { AgendaService } from 'src/app/core/services/agenda.service';
import { diaSemanaDTO, agendaDTO, entradaDTO, agendaCitaDTO, } from 'src/app/shared/model/agendaDTO';
import { DatePipe } from '@angular/common';
import { SelectItem} from 'primeng/api';
import { FuncionesService } from 'src/app/core/services/funciones.service';


import { userDTO } from 'src/app/shared/model/userDTO';
import { UsersService } from 'src/app/core/services/users.service';

import { ClientesService } from 'src/app/core/services/clientes.service';
import { tratamientoDTO } from 'src/app/shared/model/tratamientoDTO';
import { TratamientosService } from 'src/app/core/services/tratamientos.service';












@Component({
  selector: 'scheduler-calendar',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {



  private desCita: ElementRef;
  @ViewChild('desCita', {static: true}) set content(content: ElementRef) {this.desCita = content;}
  @ViewChild('nombre')  nombre?: ElementRef<HTMLElement>;
  idAgenda=0;
  displayEditSemana:boolean;
  users:userDTO[]=[];
  selectedUser:userDTO=new userDTO();
  tmp:string;
  baja:boolean=false;
  loader=false;
  status=0;
  txtError='';
  options: any;
  events: any[];
  clientes:any[];
  agendas:agendaDTO[]=[];
  selectedAgendas:agendaDTO[]=[];
  agenda:agendaDTO=new agendaDTO();

  showSelTrata=false;
  showSelCliente=false;
  tratamientosMaestros:tratamientoDTO[]=[];


  selectedCita:agendaCitaDTO=new agendaCitaDTO();
  selectedDesde:Date;
  selectedHasta:Date;
  ejercicio:number;
  es={firstDayOfWeek: 0,dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],dateFormat: 'HH:mm',}
  lastClick:any=0;
  lastAgenda:any=0;
  lasDate:string='';
  inicio='08:00';
  fin='22:00';
  cargando=false;
  estados: SelectItem[] = [{label: 'Concertada', value: 0}, {label: 'Confirmada', value: 1}, {label: 'En espera', value: 2}, {label: 'Realizada', value: 3}, {label: 'No presentada', value: 4}];

  semana:diaSemanaDTO[]=[];
  ejercicioCalendar=0;

  displayEvent=false;
  vistas:any;
  resources:any[]=[];
  dates:string[]=[];
  headers:any;

  calendarWeekends=false;
  aspectRatio=1;
  f='';
  fecha:Date;
  title:string;
  defaultGap:number=10;

  entrada:entradaDTO=new entradaDTO();



  constructor(  private route: ActivatedRoute,
                private router:Router,
                private clientesService:ClientesService,
                private confirmationService:ConfirmationService,
                private global:GlobalService,
                private datePipe:DatePipe,
                private funciones:FuncionesService,
                private agendaService:AgendaService,
                private usersService:UsersService,
                private tratamientosService:TratamientosService,
                private service:MessageService)
                {
                  this.es=this.funciones.fecha_es();
                  this.f=this.datePipe.transform(new Date(), 'yyyy-MM-dd');
                  this.fecha=new Date(this.f);

                }





  ngOnInit() {

    this.loader=true;
    this.status=0;
    this.dates.push(new Date().toISOString().slice(0,10));
    this.vistas={resourceTimeGrid5Day: {type: 'resourceTimeGrid', weekends:false, titleFormat:{ month: 'long',year: 'numeric',day: 'numeric'}, duration: { weeks: 1 },buttonText: 'semana 5'},
                 resourceTimeGrid7Day: {type: 'resourceTimeGrid', weekends:true, titleFormat:{ month: 'long',year: 'numeric',day: 'numeric'},duration: { weeks: 1 },buttonText: 'semana 7'}

                };
    this.headers={left: 'prev,next',center: 'title',right: 'resourceTimeGridDay, resourceTimeGrid5Day, resourceTimeGrid7Day'};

    this.agendaService.getAgendas().subscribe(
      (data)=>{
          this.status=1;
          this.agendas=data.filter(item=>item.idAgenda>0);
          this.selectedAgendas=this.agendas;

          this.agendas.forEach(element => {
            if (element.idAgenda==0){
              this.defaultGap=element.gap!=null ? element.gap : 10;
            }
            if (element.idAgenda>0)
              this.resources.push({ id: element.idAgenda , title: element.nombre, width:'50%' });
          });
          this.refreshCalendar();

      },
      (error)=>{
        this.status=-1;
        this.notificaError("Error al obtener las agenas", error.message);
      }
    );
  }

  reintentar(){
    this.ngOnInit();
  }

 refreshCalendar(){
    this.loader=false;
    this.cargando=true;

    var ages:number[]=[];
    this.resources.forEach(element => {
      ages.push(element.id);
    });

    this.agendaService.getPlanningBase (ages, this.dates).subscribe(
      (data)=>{
        this.events=data;
        let ev=this.agendaService.getPlanningEvents(data);
        this.status=1;
        //this.calen.events=ev;
        this.render();
        setTimeout(() => {
          this.cargando=false;
        }, 500);
      },
      (error)=>{
        this.notificaError('Error al obtener los datos del calendario/agenda', error.message);
      }
    );
  }


  changeDate(){
    this.f=this.datePipe.transform(this.fecha, 'yyyy-MM-dd');


  }

  changeAgendas(){
    this.resources=[];
    this.selectedAgendas.forEach(element => {
      if (element.idAgenda>0)
        this.resources.push({ id: element.idAgenda , title: element.nombre });
    });
    setTimeout(() => {
      this.render();
    }, 10);

  }

  dayRender(e){
   this.f=this.datePipe.transform(e.date, 'yyyy-MM-dd');
   this.fecha=new Date(this.f);
   if (this.dates.indexOf(this.f)<0) {
      this.dates.push(this.f);
      this.refreshCalendar();
   }



  }



  eventRender(e){
    if (e.el.innerHTML!=''){

          let textColor=this.isTooLightYIQ(e.event.backgroundColor) ? '#000' : '#fff';
          let horari=this.datePipe.transform(e.event.start, 'H:mm') + ' - ' + this.datePipe.transform(e.event.end, 'H:mm');
          let html='<div class="fc-content" style="color:'+ textColor +'">';
          html+=  '<div style="padding-top:2px; font-size:9px;" class="fc-time" data-start="'+this.datePipe.transform(e.event.start, 'HH:mm')+'" data-full="'+horari+'">'+ horari+'</div>';
          html+=  '<div style="padding-left:4px; font-size:13px; line-height:12px;" class="fc-title">'+ e.event.title +'</div>';
          html+=  '</div><div class="fc-resizer fc-end-resizer"></div>';

          e.el.innerHTML=html;
    }
  }


  deleteEvent(idEntrada:number){

    this.confirmationService.confirm({
      message: 'Desea eliminar la cita?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        this.loader=true;
        this.agendaService.delEntrada(idEntrada).subscribe(
          (data)=>{
            if(data.success){
              this.refreshCalendar();
              setTimeout(() => {
                this.loader=false;
                this.displayEvent=false;
              }, 200);
            }else{
              this.notificaError('Error al eliminar la cita', data.message);
            }

          },
          (error)=>{
            this.notificaError('DB.Error al eliminar la cita', error.message);
          }
        );
      },
      reject: () => {
        return;
      }
    });

  }

  eventDrop(e){
    let question="Desea cambiar?";
    if (e.event.start!=e.oldEvent.start){
      question="Desea cambiar la cita a las " + this.datePipe.transform(e.event.start, 'H:mm') + '?';
    }
    if (e.newResource){
      question="Desea cambiar la cita a " + e.newResource.title + " a las " + this.datePipe.transform(e.event.start, 'H:mm') + '?';
    }

    this.confirmationService.confirm({
      message: question,
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        this.modificaCita(e);
      },
      reject: () => {
        e.revert();
        return;
      }
    });
  }

  eventResize(e){
    let question="Desea modificar la cita hasta las " + this.datePipe.transform(e.event.end, 'HH:mm') + '?';

    this.confirmationService.confirm({
      message: question,
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
        this.modificaCita(e);
      },
      reject: () => {
        e.revert();
        return;
      }
    });
  }

  modificaCita(e){

  }

  eventClick(e){

    if (e.event.rendering=="background")
      return;

    var idEntrada=+e.event.id;
    if (this.lasDate==e.dateStr && this.lastAgenda==idEntrada && Date.now()-this.lastClick < 350){
      this.agendaService.getEntrada(idEntrada).subscribe(
        (data)=>{
          this.entrada=data;
          this.agenda=this.agendas.find(item=>item.idAgenda==this.entrada.idAgenda);
          this.selectedDesde=e.event.start;
          this.selectedHasta=e.event.end;
          var i =this.agenda.agendaCitas.findIndex(item=>item.idCita==this.entrada.tipoCita);
          this.selectedCita=new agendaCitaDTO();
          if (i>=0)
            this.selectedCita=this.agenda.agendaCitas[i];
          this.displayEvent=true;
          setTimeout(() => {
            this.nombre.nativeElement.focus();
          }, 100);
        },
        (error)=>{
          this.notificaError("DB-Error el los datos de la cita",error.message);
        }
      );
    }
    this.lasDate=e.dateStr;
    this.lastClick=Date.now();
    this.lastAgenda=idEntrada;

  }

  dateClick(e){

    if (this.lasDate==e.dateStr && this.lastAgenda==e.resource.id && Date.now()-this.lastClick < 350){
      this.entrada=new entradaDTO();
      if (e.allDay){
        this.selectedDesde=new Date(e.dateStr + ' ' + this.inicio);
      }else{
        this.selectedDesde=e.date;
      }
      this.entrada.estado=0;
      this.selectedHasta=null;
      this.entrada.duracion=null;
      this.entrada.tipoCita=null;
      this.entrada.id=0;
      this.entrada.fecha = this.datePipe.transform(e.dateStr, 'yyyy-MM-dd');
      this.entrada.ejercicio = parseInt(this.datePipe.transform(e.dateStr, 'yyyy'));
      this.entrada.idAgenda=parseInt(e.resource.id);
      this.agenda=this.agendas.find(item=>item.idAgenda==this.entrada.idAgenda);
      var gap=this.agenda.gap!=null ? this.agenda.gap : this.defaultGap;
      this.entrada.duracion=gap;
      this.cambiaDuracion();
      this.entrada.color="#ffffff";
      this.selectedCita=new agendaCitaDTO();
      this.title="Nueva cita para el " + this.global.diasSemana[new Date(e.dateStr).getDay()] + ', ' + this.datePipe.transform(e.dateStr, 'dd/MM/yyyy');
      this.displayEvent=true;
      setTimeout(() => {
        this.nombre.nativeElement.focus();
      }, 100);
    }



    this.lasDate=e.dateStr;
    this.lastClick=Date.now();
    this.lastAgenda=e.resource.id;

  }

  updateEvent(){



    var nuevo=this.entrada.id==0;


    if (this.selectedDesde==null || this.selectedHasta==null || this.entrada.duracion==null || this.selectedDesde.getTime()>=this.selectedHasta.getTime() || this.entrada.duracion<=0 ){
      this.notificaError("Error el los datos de la duración de la cita","Revise el inicio/fin de la cita");
      return;
    }
    this.loader=true;

    this.entrada.desde=this.datePipe.transform(this.selectedDesde, 'HH:mm');
    this.entrada.hasta=this.datePipe.transform(this.selectedHasta, 'HH:mm');
    this.entrada.tipoCita=(this.selectedCita.idCita==undefined) ? null : this.selectedCita.idCita;
    this.agendaService.saveEntrada(this.entrada).subscribe(
      (data)=>{
        this.refreshCalendar();
        this.loader=false;
        if (data.success){
          this.displayEvent=false;
        }else{
          this.notificaError("Error al guardar la cita en la agenda", data.message);
        }

      },
      (error)=>{
        this.refreshCalendar();
        this.notificaError("DB - Error al guardar la cita en la agenda", error.message);
      }
    );





  }


buscaCli(){
  this.loader=true;
  this.clientesService.getClientes().subscribe(
    (data)=>{
        this.clientes=data;
        this.showSelCliente=true;
        setTimeout(() => {
          this.loader=false;
        }, 150);
    },
    (error)=>{
      this.notificaError("Error al obtener los pacientes de la base de datos",error.message);
    }
  );
}
selCliente(item){
  this.entrada.nombre=item.nombre;
  this.entrada.idCliente=item.id;
  this.entrada.telefono=item.telefono;
  this.showSelCliente=false;
}

buscaTrata(){

    if (this.selectedCita.idCita==0 && this.agenda.agendaCitas.length>0 ){
      this.notificaError("Seleccione un tipo de cita", "Antes de seleccionar un tratamiento, debe seleccionar el tipo de visita");
      return;
    }
    this.showSelTrata = true
    this.loader=true;
    this.tratamientosService.getTratamientos(true).subscribe(
      (data)=>{
          this.tratamientosMaestros=data;
          this.loader=false;
      },
      (error)=>{
        this.loader=false;
        this.showSelTrata = false;
      }
    );
}

selTratamiento(item:tratamientoDTO){


  this.showSelTrata = false;
  this.entrada.tratamiento=item.descripcion;
  this.entrada.idTratamiento=item.id;
  if (item.duracion!=null && item.duracion!=0 && item.duracion!=undefined){
    this.entrada.duracion = item.duracion;
    this.cambiaDuracion();
  }
}


  cancel(){
    this.router.navigateByUrl('/config/agendas');
  }

  cambiaDuracion(){
    var h=this.selectedHasta;
    try{
      this.selectedHasta = new Date(this.selectedDesde);
      var m:number = +this.selectedHasta.getMinutes();
      var n:number = +this.entrada.duracion;
      this.selectedHasta.setMinutes(m+n);
    }catch{
      this.selectedHasta=h;
    }
  }
  cambiaFin(){

    if (this.selectedHasta==undefined)
      return;

    var diff =(this.selectedHasta.getTime() - this.selectedDesde.getTime()) / 1000;
    diff /= 60;
    this.entrada.duracion =  Math.round(diff);
  }

  cambiaTipoVisita(e){
    try{
      this.entrada.color=e.value.color;
      this.entrada.duracion= e.value.duracion;
      this.cambiaDuracion();
    }catch{
      return;
    }

  }



  render(){

  }


  notificaError(summary:string, detail:string){
    this.loader=false;
    this.cargando=false;
    this.service.add({ key: 'tst', severity: 'error', summary: summary, detail: detail});
  }

  isTooLightYIQ(hexcolor:string){

    try{
      hexcolor=hexcolor.replace('#','');
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
      var yiq = ((r*299)+(g*587)+(b*114))/1000;
    }
    catch{
      var yiq = 220;
    }


    return yiq >= 128;
  }




}
