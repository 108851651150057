import { StringifyOptions } from "querystring";
import { permisoDTO } from "./userDTO";

export class agendaDTO {
    idAgenda: number=0;
    nombre:string='';
    idUser:number;
    color:string;
    gap:number;
    permisoPublico:number;
    agendaCitas:Array<agendaCitaDTO>=[];
    permisosAgenda:Array<permisoAgendaDTO>=[];

}


export class permisoAgendaDTO{
    idAgenda: number=0;
    idUser: number=0;
    permiso: number=0;
}

export class agendaCitaDTO{
    idAgenda:   number=0;
    idCita:     number=0;
    descripcion:string;
    duracion:   number=0;
    color:      string;
}

export class entradaDTO{
    id:             number=0;
    ejercicio:      number=0;
    idAgenda:       number=0;
    fecha:          string;
    desde:          string;
    hasta:          string;
    duracion:       number;
    idCliente:      number;
    nombre:         string;
    telefono:       string;
    observaciones:  string;
    estado:         number;
    tipoCita:       number;
    color:          string;
    idTratamiento:  number;
    tratamiento:    string;

}

export class diaDTO {
    idAgenda:   number=0;
    ejercicio:  number=0;
    fecha:      Date;
    desde1:     string;
    hasta1:     string;
    desde2:     string;
    hasta2:     string;
    festivo:    boolean=false;
    duracion:   number;
    entradas:   Array<entradaDTO>=[];
}


export class diaSemanaDTO {
    idAgenda: number=0;
    ejercicio: number=0;
    dia:number=0;
    txtDia:string='';
    desde1:string;
    hasta1:string;
    desde2:string;
    hasta2:string;
    festivo:boolean=false;
    laborable:boolean=false;
}
