import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionesService {

  constructor() { }




fecha_ddmmyyyy(f: string) {

  if (f == null || f === undefined || f.length < 10) {
    return f;
  }

  const expresion=/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;

  if (f.match(expresion)) {
    return f;
  }


  const rdo = f.substr(8, 2) + '/' + f.substr(5, 2) + '/' + f.substr(0, 4);
  return rdo;

}


fecha_yyyymmdd(f: string) {

  if (f == null || f === undefined || f.length < 10) {
    return f;
  }

  const expresion=/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

  if (f.match(expresion)) {
    return f;
  }

  const rdo = f.substr(6, 4) + '-' + f.substr(3, 2) + '-' + f.substr(0, 2);
  return rdo;

}

miFecha(fecha:any): Date {
  if (fecha == null || fecha === undefined || fecha.length < 10) {
    return new Date;
  }

  const rdo = fecha.substr(6, 4) + '-' + fecha.substr(3, 2) + '-' + fecha.substr(0, 2);
  return new Date(rdo);

}


arregla(data){

for(var i = 0; i < data.length; i++){
  var obj = data[i];
  for(var prop in obj){
      if(obj.hasOwnProperty(prop) && obj[prop] !== null && !isNaN(obj[prop])){
        if (prop=='id' || prop=='precio' || prop=='baja')
          obj[prop] = +obj[prop];   
      }
  }
}
return data;
}

esImagen(fileName:string):boolean{

  return (fileName.endsWith('.jpg') ||
          fileName.endsWith('.JPG') ||
          fileName.endsWith('.png') ||
          fileName.endsWith('.PNG') ||
          fileName.endsWith('.gif') ||
          fileName.endsWith('.GIF') ||
          fileName.endsWith('.bmp') ||
          fileName.endsWith('.BMP'));

}


fecha_es(){
  let es = {
    firstDayOfWeek: 1,
    dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
    dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
    dayNamesMin: [ "D","L","M","X","J","V","S" ],
    monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
    monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
    today: 'Hoy',
    clear: 'Borrar',
    dateFormat: 'mm/dd/yy'
  }
return es;
}

esFecha(fecha:any):boolean{
  try {
          
          var splitComponents = fecha.split('/');
          if (splitComponents.length > 0) {
              var day = parseInt(splitComponents[0]);
              var month = parseInt(splitComponents[1]);
              var year = parseInt(splitComponents[2]);

              if (isNaN(day) || isNaN(month) || isNaN(year)) {
                  return false;
              }

              if (day <= 0 || month <= 0 || year <= 0) {
                return false;
              }

              if (month > 12) {
                return false;
              }

              let daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
              if (year % 4 == 0) {
                      // current year is a leap year
                      daysPerMonth[1] = 29;
               }

               if (day > daysPerMonth[month - 1]) {
                      return false
              }
          } else {
            return false;
          }

      } catch (e) {

          return false;
      }
  return true;
}

}
