
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';
import { Injectable, Injector, ɵConsole } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";

import { AuthService } from "./auth.service";
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector, private router:Router) {}

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {



        if (token && !this.isRefreshingToken){

            return req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }else{
            return req;
        }

    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const authService = this.injector.get(AuthService);
        var token=authService.getCurrentToken();


            return next.handle(this.addToken(req, token)).pipe(
                catchError(error => {

                    if (error instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>error).status) {
                            case 400:

                                return this.handle400Error(error);

                            case 401:

                                return this.handle401Error(req, next);
                            default:

                                return observableThrowError(error);
                        }
                    } else {
                        return observableThrowError(error);
                    }
                }));

    }

    handle400Error(error) {
        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return this.logoutUser();
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(AuthService);


            return authService.refreshToken().pipe(
                switchMap((data) => {

                    if (data.token) {
                        authService.setCurrentSession(data);
                        this.tokenSubject.next(data.token);
                        this.isRefreshingToken=false;
                        return next.handle(this.addToken(req, data.token));

                    }
                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    this.isRefreshingToken = false;
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),);
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }),);
        }
    }


    logoutUser() {
        // Route to the login page (implementation up to you)
        
        const authService = this.injector.get(AuthService);
        authService.logout();
        this.router.navigate(['/login']);
        return observableThrowError("");
    }
}







































/*

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { LoginObjectDTO } from 'src/app/model/loginObjectDTO';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(public auth:AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
      let token = this.auth.getCurrentToken();

      if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });

      }



      return next.handle(req).pipe(catchError( (err: HttpErrorResponse) => {
        console.log("CADUCATTTTT",err);

        if (err.status === 401) {
            this.handle401Error(req, next);
        }
        return Observable.throw(err);
    }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
        this.isRefreshingToken = true;

        // Reset here so that the following requests wait until the token
        // comes back from the refreshToken call.
        this.tokenSubject.next(null);



        return this.auth.refreshToken().pipe(
            switchMap((newToken: string) => {
                if (newToken) {
                    this.tokenSubject.next(newToken);
                    return next.handle();
                }

                // If we don't get a new token, we are in trouble so logout.
                return this.logoutUser();
            }),
            catchError(error => {
                // If there is an exception calling 'refreshToken', bad news so logout.
                return this.logoutUser();
            }),
            finalize(() => {
                this.isRefreshingToken = false;
            }),);
    } else {
        return this.tokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
                return next.handle(this.addToken(req, token));
            }),);
    }
}


*/
