<div style="margin: 75px 0" class="center">

  <img src="assets/images/exclamation.png">

  <h2>No se ha podido cargar el contenido.</h2>
  <h3>{{error}}</h3>
  
  <div class="ui-dialog-buttonpane ui-helper-clearfix center">
            
      <button style="width: 150px;" type="button" class="ui-button-secondary miBtn" pButton icon="fas fa-sync-alt" (click)="retry()" label="Volvel a intentarlo" title="Reintentar"></button>
          

      
  </div>

</div>


