import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { intervencionDTO } from 'src/app/shared/model/intervencionDTO';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientesService } from 'src/app/core/services/clientes.service';
import { FuncionesService } from 'src/app/core/services/funciones.service';
import { GlobalService } from 'src/app/core/services/global.service';



import { DatePipe } from '@angular/common';
import { canvasComponent } from 'src/app/shared/components/canvas/canvas.component';
import { ConfirmationService, MenuItem, MessageService, OverlayPanel } from 'primeng';


@Component({
  selector: 'app-intervencion',
  templateUrl: './intervencion.component.html',
  styleUrls: ['./intervencion.component.scss']
})
export class IntervencionComponent implements OnInit {

  @ViewChild ('view_colores',{static: true}) view_colores: OverlayPanel;
  @ViewChild ('view_brushes',{static: true}) view_brushes: OverlayPanel;
  @ViewChild ('canvas',{static: true}) canvas: canvasComponent;

  idIntervencion=0;
  intervencion:intervencionDTO=new intervencionDTO();
  loader=false;
  displaySelTemplate:boolean=false;
  es={};
  txtError='';
  items: MenuItem[];
  borrar=false;
  currentColor:number=0;
  currentBrush=0;
  fecha:Date;
  status=0;

  template:string='';
  arr_colores =   [ {name:'Implantes de Colágeno', color:'#eb2f06', acro:'I. Colág.' },
                    {name:'Ácido. Hialurónico', color:'#3498db', acro:'A. Hial.' },
                    {name:'Botox', color:'#27ae60', acro:'Botox' },
                    {name:'Hilos', color:'#D980FA', acro:'Hilos' },
                    {name:'Otros Negro', color:'#2f3542', acro:'Negro' },
                    {name:'Otros Blanco', color:'#ffffff', acro:'Blanco' },
                    {name:'Otros Amarillo', color:'#fff200', acro:'Amarillo' }];

  arr_brushes =   [ {name:'1 px', size:1},
                    {name:'2 px', size:2},
                    {name:'3 px', size:3},
                    {name:'4 px', size:4},
                    {name:'5 px', size:5},
                    {name:'8 px', size:8},
                    {name:'10 px', size:10}];

  selectedColor:any;
  selectedBrush:any;
  doctors:any[]=[];
  currentDoctor:any=null;

  constructor(private route: ActivatedRoute,
              private router:Router,
              private clientesService: ClientesService,
              private confirmationService:ConfirmationService,
              private funciones:FuncionesService,
              private globalService:GlobalService,
              private datePipe:DatePipe,
              private service:MessageService

  ) {
    this.es=this.funciones.fecha_es();
   }

   ngOnInit() {
    this.status=0;
    this.loader=true;
    this.currentColor=this.globalService.getColor();
    this.currentBrush=this.globalService.getBrush();
    this.selectedColor=this.arr_colores[this.currentColor];
    this.selectedBrush=this.arr_brushes[this.currentBrush];





    this.route.params.subscribe(params => {
      this.idIntervencion=params['id'];
      this.clientesService.getIntervencion(this.idIntervencion).subscribe(
        (data)=>{
            this.intervencion=data.intervencion;
            this.template=data.image.base64;
            this.loader=false;
            this.fecha = (this.intervencion.fecha==null) ? null : new Date(this.intervencion.fecha);
            this.canvas.loadTrazos(this.intervencion.trazos);
            this.globalService.getDoctors().subscribe(
              (data)=>{
                this.doctors=data;
                this.currentDoctor=data.find(item=>item.id==this.intervencion.idDoctor);
              }
            );
            this.status=1;
            this.loader=false;
        },
        (error)=>{
            this.txtError=error.message;
            this.status=-1;
            this.loader=false;
        }
      );
    });
  }


  reintentar(){
    this.ngOnInit();
  }

  setColor(name){
    this.view_colores.hide();
    this.currentColor= this.arr_colores.findIndex(item => item.name==name);
    this.globalService.setColor(this.currentColor);
    this.selectedColor=this.arr_colores[this.currentColor];
  }
  setBrush(name){
    this.view_brushes.hide();
    this.currentBrush= this.arr_brushes.findIndex(item => item.name==name);
    this.globalService.setBrush(this.currentBrush);
    this.selectedBrush=this.arr_brushes[this.currentBrush];
  }

  save(){
    this.loader=true;
    this.intervencion.trazos=this.canvas.obtenerTrazos();
    this.intervencion.fecha=this.datePipe.transform(this.fecha, 'yyyy-MM-dd');
    this.intervencion.idDoctor=(this.currentDoctor!=null && this.currentDoctor!=undefined) ? this.currentDoctor.id : null;

    if (this.intervencion.idDoctor==null){
      this.notificaError("Seleccione un doctor","Seleccione en doctor/a que se encargará de la intervención");
      return;
    }
    this.clientesService.saveIntervencion(this.intervencion).subscribe(
      (data)=>{
        this.loader=false;
        if (data.success){
          this.router.navigate(["/clientes/tratamiento", this.intervencion.idTratamiento ]);
        }else{
          this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: data.message });
        }
      },
      (error)=>{
        this.loader=false;
        this.service.add({ key: 'tst', severity: 'error', summary: 'Database error', detail: error.message });
      }
    );

  }

  delete(){
    this.confirmationService.confirm({
      message: 'Desea eliminar la intervención ?',
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel:"Aceptar",
      rejectLabel:"Cancelar",
      accept: () => {
          this.clientesService.delIntervencion(this.intervencion.id).subscribe(
            (data)=>{
              if (data.success){
                this.router.navigate(["/clientes/tratamiento", this.intervencion.idTratamiento ]);
              }else{
                this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar la intervención', detail: data.message });
              }
            },
            (error)=>{
              this.service.add({ key: 'tst', severity: 'error', summary: 'Error al eliminar la intervención', detail: error.message });
            }
          );
      },
      reject: () => {
          return;
      }
  });
  }

  cancel(){
    this.router.navigate(["/clientes/tratamiento", this.intervencion.idTratamiento ]);
  }

  desHacer(event){
    event.preventDefault();
    this.canvas.undo();
  }



  notificaError(summary:string, detail:string){
    this.loader=false;
    this.service.add({ key: 'tst', severity: 'error', summary: summary, detail: detail});
  }






}
