import { lineaTratamientoDTO } from './lineaTratamientoDTO';
import { intervencionDTO } from './intervencionDTO';

import { pagoDTO } from './pagoDTO';
import { movalmacenDTO } from './productoDTO';

export class tratamientoDTO {
    id: number=0;
    tipo:string='';
    descripcion:string='';
    observaciones:string='';
    precio:number=0;
    iva:number=0;
    baja:number=0;
    duracion:number=0;
    consentimiento:string='';
    informe:string='';


}
export class tratamientoCliDTO {
    id: number=0;
    idCliente: number=0;
    fecha:string=new Date().toISOString().slice(0,10);
    idDoctor: number=0;
    tipoTratamiento:string='';
    informe:string='';
    reacciones:string='';
    observaciones:string='';
    pagado:boolean=false;
    fRevision:string='';
    revision:string='';
    importe:number=0;
    piel:number=null;
    envejecimiento:number=null;
    talla :number=null;
    peso :number=null;
    pecho :number=null;
    cintura :number=null;
    abdomen :number=null;
    cadera :number=null;
    imc :number=null;
    muneca :number=null;
    lineaTratamientos:Array<lineaTratamientoDTO>=[];
    movAlmacen:Array<movalmacenDTO>=[];
    intervencionesCli:Array<intervencionDTO>=[];
    pagosCli:Array<pagoDTO>=[];








}
