import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BonoDTO, tratamientosSeleccionablesBonoDTO, BonoCliDTO } from '../../shared/model/bonoDTO';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { timeout } from 'rxjs/operators';
import { userDTO } from 'src/app/shared/model/userDTO';


@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public endpoint: string = '';
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};

  constructor(private http:HttpClient, private auth:AuthService) {

  }

  getUsers():Observable<any[]>{
    return this.http.get<any[]>(this.auth.endPoint + 'users').pipe(timeout(8000));;
  }

  getDoctors():Observable<any[]>{
    return this.http.get<any[]>(this.auth.endPoint + 'users/doctors').pipe(timeout(8000));;
  }

  getUser(id:number):Observable<userDTO>{
    return this.http.get<userDTO>(this.auth.endPoint + 'users/'+id).pipe(timeout(8000));;
  }

  delUser(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'users/'+id, this.httpOptions).pipe(timeout(8000));;
  }

  saveUser(user:userDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'users', user,this.httpOptions).pipe(timeout(8000));;
  }



  saveBonoCli(datos:BonoCliDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'bonosCli', datos,this.httpOptions).pipe(timeout(8000));;
  }

  delBonoCli(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'bonosCli/'+id, this.httpOptions).pipe(timeout(8000));;
  }

  changePass(idUser:number, actual:string, nuevo:string):Observable<any>{
    let datos = {idUser:idUser, actual:actual, nuevo:nuevo}

    

    return this.http.post<any>(this.auth.endPoint + 'users/changePass', datos,this.httpOptions).pipe(timeout(8000));;
  }



}
