import {
  Component, Input, ElementRef, AfterViewInit, ViewChild
} from '@angular/core';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styles: ['./canvas.component.scss']
})
export class canvasComponent implements AfterViewInit {

  @ViewChild('canvas',{static: true}) public canvas: ElementRef;

  @Input() public width = 400;
  @Input() public height = 800;
  @Input() public color = '#000';
  @Input() public brush = 1;
  @Input() public borrar = false;

  history=[];
  pintar=false;
  canvasElement: any;
  lastX=0;
  lastY=0;
  seHaMovido=false;
  accion='';
  private cx: CanvasRenderingContext2D;

  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;

    this.cx = canvasEl.getContext('2d');
    this.canvasElement = this.canvas.nativeElement;

    canvasEl.width = this.width;
    canvasEl.height = this.height;
    this.cx.lineWidth = this.brush;
    this.cx.lineCap = 'round';
    this.cx.strokeStyle = this.color;



  }



  loadTrazos(trazos){
    if (trazos!=null && trazos!=''){
      var image = new Image();
      image.src = trazos;
      let w=this.width;
      let h=this.height;
      let cx = this.cx;
      image.onload= function () {
        cx.drawImage(image,0,0,w,h);
        
      }
    }
  }

  touchStart(ev){
    ev.preventDefault();
    this.history.push(this.canvasElement.toDataURL("image/png"));
    let canvasPosition = this.canvasElement.getBoundingClientRect();
    this.lastX = ev.touches[0].pageX-canvasPosition.x;
    this.lastY = ev.touches[0].pageY-canvasPosition.y;
    this.seHaMovido=false;
    this.accion = (this.borrar) ? 'borrar' : 'pintar';
  }

  mouseDown(ev){
    ev.preventDefault();
    this.history.push(this.canvasElement.toDataURL("image/png"));
    let canvasPosition = this.canvasElement.getBoundingClientRect();
    this.lastX = ev.x-canvasPosition.x;
    this.lastY = ev.y-canvasPosition.y;
    this.seHaMovido=false;
    this.accion = (this.borrar) ? 'borrar' : 'pintar';
  }

  touchEnd(ev){

    ev.preventDefault();
    let canvasPosition = this.canvasElement.getBoundingClientRect();
    let currentX = ev.changedTouches[0].pageX-canvasPosition.x;
    let currentY = ev.changedTouches[0].pageY-canvasPosition.y;

    if (!this.seHaMovido && this.accion=='pintar')  {
        this.cx.beginPath();
        this.cx.fillStyle = this.color;
        this.cx.arc(currentX,currentY,this.brush, 0, Math.PI, true);
        this.cx.closePath();
        this.cx.fill();
    }

    if (!this.seHaMovido && this.accion=='borrar')  {
      this.clearCircle(this.cx,currentX, currentY,5+this.brush);
    }
    this.accion='';
  }

  mouseUp(ev){
    ev.preventDefault();
    let canvasPosition = this.canvasElement.getBoundingClientRect();
    let currentX = ev.x-canvasPosition.x;
    let currentY = ev.y-canvasPosition.y;

    if (!this.seHaMovido && this.accion=='pintar')  {
        this.cx.beginPath();
        this.cx.fillStyle = this.color;
        this.cx.arc(currentX,currentY,this.brush, 0, Math.PI*2, true);
        this.cx.closePath();
        this.cx.fill();
    }

    if (!this.seHaMovido && this.accion=='borrar')  {
      this.clearCircle(this.cx,currentX, currentY,5+this.brush);

    }
    this.accion='';
  }

  touchMove(ev){

    if (this.accion==''){
      return;
    }


    ev.preventDefault();
    this.seHaMovido=true;
    let canvasPosition = this.canvasElement.getBoundingClientRect();

    let currentX = ev.touches[0].pageX-canvasPosition.x;
    let currentY = ev.touches[0].pageY-canvasPosition.y;



    if (this.accion=='borrar'){
      this.clearCircle(this.cx,currentX, currentY,5+this.brush);
      return;
    }


    this.cx.beginPath();
    this.cx.lineJoin = "round";
    this.cx.moveTo(this.lastX, this.lastY);
    this.cx.lineTo(currentX, currentY);
    this.cx.closePath();
    this.cx.strokeStyle = this.color;
    this.cx.lineWidth = this.brush;
    this.cx.stroke();

    this.lastX = currentX;
    this.lastY = currentY;



  }

  mouseMove(ev){

    if (this.accion==''){
      return;
    }

    ev.preventDefault();
    this.seHaMovido=true;
    let canvasPosition = this.canvasElement.getBoundingClientRect();

    let currentX = ev.x-canvasPosition.x;
    let currentY = ev.y-canvasPosition.y;



    if (this.accion=='borrar'){
      this.clearCircle(this.cx,currentX, currentY,10+this.brush);
      return;
    }


    this.cx.beginPath();
    this.cx.lineJoin = "round";
    this.cx.moveTo(this.lastX, this.lastY);
    this.cx.lineTo(currentX, currentY);
    this.cx.closePath();
    this.cx.strokeStyle = this.color;
    this.cx.lineWidth = this.brush;
    this.cx.stroke();

    this.lastX = currentX;
    this.lastY = currentY;



  }

  clearCircle(context,x,y,radius) {
    context.save();
    context.beginPath();
    context.arc(x, y, radius, 0, 2*Math.PI, true);
    context.clip();
    context.clearRect(x-radius,y-radius,radius*2,radius*2);
    context.restore();

  }

  cleanCanvas(){

    this.cx.clearRect(0, 0, this.width, this.height);

  }



  public obtenerTrazos(){

    return this.canvasElement.toDataURL("image/png");
  }

  public undo(){
    if (this.history.length==0)
      return;

    var image = new Image();
    image.src = this.history.pop();
    let ctx = this.canvasElement.getContext('2d');
    this.canvasElement.width=this.width;
    this.canvasElement.height=this.height;
    let w=this.width ;
    let h=this.height ;
    image.onload= function () {
    ctx.drawImage(image,0,0,w,h);

  }
}

}




