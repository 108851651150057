import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { clienteDTO } from 'src/app/shared/model/clienteDTO';
import { tratamientoCliDTO } from 'src/app/shared/model/tratamientoDTO';
import { lineaTratamientoDTO } from 'src/app/shared/model/lineaTratamientoDTO';
import { pagoDTO } from 'src/app/shared/model/pagoDTO';
import { intervencionDTO, intervencionCompletaDTO } from 'src/app/shared/model/intervencionDTO';
import { timeout } from 'rxjs/operators';
import { consentimientoGeneralCliDTO } from 'src/app/shared/model/consentimientoGeneralcliDTO';



@Injectable({
  providedIn: 'root'
})


export class ClientesService {
  private httpOptions: any =  { headers: new HttpHeaders({'Content-Type':  'application/json'})};

  constructor(private http:HttpClient, private auth:AuthService) {

  }


  getClientes():Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'clientes').pipe(timeout(10000));
  }

  getCliente(idCliente:number):Observable<clienteDTO>{
    return this.http.get<clienteDTO>(this.auth.endPoint + 'clientes/'+idCliente).pipe(timeout(10000));
  }

  saveCliente(cliente:clienteDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'clientes', cliente, this.httpOptions).pipe(timeout(10000));
  }

  delCliente(idCliente:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint+ 'clientes/' + idCliente, this.httpOptions).pipe(timeout(10000));
  }

  /*** CONSENTIMIENTOS GENERALES */
  getConsentimientosGenerales(idCliente:number):Observable<any[]>{
    return this.http.get<consentimientoGeneralCliDTO[]>(this.auth.endPoint + 'consentimientosGenerales/pendientes/'+idCliente).pipe(timeout(10000));
  }



  /*** TRATAMIENTOS DEL CLIENTE */

  getTratamientoCli(id:number):Observable<tratamientoCliDTO>{
    return this.http.get<tratamientoCliDTO>(this.auth.endPoint + 'tratamientoscli/' + id).pipe(timeout(10000));
  }

  saveTratamientoCli(tratamientoCli:tratamientoCliDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'tratamientoscli', tratamientoCli, this.httpOptions).pipe(timeout(10000));
  }
  saveLineaTratamientoCli(linea:lineaTratamientoDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'tratamientoscli/linea', linea, this.httpOptions).pipe(timeout(10000));
  }
  delLineaTratamientoCli(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'tratamientoscli/linea/'+id, this.httpOptions).pipe(timeout(10000));
  }

  delTratamientoCli(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'tratamientoscli/' + id, this.httpOptions).pipe(timeout(10000));
  }


  /*** PAGOS A CUENTA */
  savePagoCli(pago:pagoDTO):Observable<any>{
      return this.http.post<any>(this.auth.endPoint + 'pagos', pago, this.httpOptions).pipe(timeout(10000));
  }

  delPagoCli(id:number):Observable<any>{
      return this.http.delete<any>(this.auth.endPoint + 'pagos/'+id, this.httpOptions).pipe(timeout(10000));
  }


   /*** INTERVENCIONES  */
  getIntervencion(id:number):Observable<intervencionCompletaDTO>{
    return this.http.get<intervencionCompletaDTO>(this.auth.endPoint + 'intervenciones/' + id).pipe(timeout(10000));
  }

  saveIntervencion(registro:intervencionDTO):Observable<any>{
    return this.http.post<any>(this.auth.endPoint + 'intervenciones', registro, this.httpOptions).pipe(timeout(10000));
  }

  delIntervencion(id:number):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'intervenciones/'+id, this.httpOptions).pipe(timeout(10000));
  }

  getThumbsIntervenciones(id:number):Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'intervenciones/thumbs/' + id).pipe(timeout(10000));
  }

  getFaceToFace(id1:number, id2:number, id3:number, id4:number):Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'intervenciones/faceToFace/' + id1 + '/' + id2 + '/' + id3 + '/' + id4).pipe(timeout(10000));
  }

  /*** IMAGENES DEL CLIENTE */

 getThumbsCli(folder:string, idCliente:number):Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'files/thumbs/'+ folder +"/" + idCliente).pipe(timeout(10000));
 }

 getTemplates():Observable<any[]>{
    return this.http.get<[any]>(this.auth.endPoint + 'files/thumbs/templates').pipe(timeout(10000));
 }


  getImage(tipo:string, idCliente:number, imagen:string):Observable<any>{
    //return this.http.get<any>(this.auth.endPoint + 'files/'+tipo+'/' + idCliente + "/" + imagen ).pipe(timeout(10000));
    return this.http.get<any>(this.auth.endPoint + 'files/'+idCliente+'/' + tipo + "/" + imagen ).pipe(timeout(10000));
  }


  delFile(folder:string,idCliente:number, imagen:string):Observable<any>{
    return this.http.delete<any>(this.auth.endPoint + 'files/' + idCliente + "/" + folder +"/" + imagen, this.httpOptions).pipe(timeout(10000));
  }


  openFile(idCliente:number, folder:string,  fileName:string):Observable<any>{
    return this.http.get(this.auth.endPoint + 'files/open/' + idCliente + "/" + folder + "/" + fileName, {responseType: 'arraybuffer'}).pipe(timeout(10000));
  }



}
