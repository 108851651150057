import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClientesComponent } from './clientes.component';
import { ClientesRoutes } from './clientes.routing';
import { EditComponent } from './edit/edit.component';
import { LightboxModule } from 'ngx-lightbox';
import { TratamientoComponent } from './tratamiento/tratamiento.component';
import { IntervencionComponent } from './intervencion/intervencion.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { canvasComponent } from 'src/app/shared/components/canvas/canvas.component';





@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ClientesRoutes),
        SharedModule,
        LightboxModule,


    ],

    exports: [
        SharedModule
    ],

    providers: [DatePipe],
    declarations: [ClientesComponent, EditComponent, TratamientoComponent, IntervencionComponent, canvasComponent]
})

export class ClientesModule {}
