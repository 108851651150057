import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginObjectDTO } from 'src/app/shared/model/loginObjectDTO';
import { sessionDTO } from 'src/app/shared/model/sessionDTO';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted: Boolean = false;
  error: {code: number, message: string} = null;
  isAuthenticated:boolean=false;
  loader:boolean=false;
  txtError:string="";
  deviceInfo = null;
  passSaved='';


  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private auth:AuthService,
              private deviceService: DeviceDetectorService,
              private router: Router) { }

  ngOnInit() {

    this.logOut();
    this.isAuthenticated=this.auth.isAuthenticated();

    this.loginForm = this.formBuilder.group({
        nif: ['', Validators.required],
        username: ['', Validators.required],
        password: ['', Validators.required],
        recordar: [false],
      })



    try{
        let cred=localStorage.getItem("credenciales");
        if (cred!=null){
          let cred2=JSON.parse(cred);
          this.loginForm = this.formBuilder.group({
            nif: [cred2.nif, Validators.required],
            username: [cred2.username, Validators.required],
            password: [cred2.password, Validators.required],
            recordar : [true]
            });

          this.passSaved = cred2.password;
          if (cred2.password != ''){
              this.loginForm.controls.password.setValue('doyouremember');
          }
        }
      }
      catch{


      }



  }
  submitLogin(){

    this.submitted = true;
    this.error = null;
    this.txtError="";
    var dataLogin = new LoginObjectDTO(this.loginForm.value);
    let deviceInfo = this.deviceService.getDeviceInfo();
    dataLogin.uuid = "pc";
    dataLogin.platform = deviceInfo.os_version;
    dataLogin.model = deviceInfo.browser;



    if(this.loginForm.valid){
      this.loader=true;
      let formData=new LoginObjectDTO(this.loginForm.value);
      if (formData.password=="doyouremember"){
        formData.password=this.passSaved;
      }


      this.authService.login(formData).subscribe(
        (data) => {
            if (this.loginForm.value.recordar){
            localStorage.setItem("credenciales", JSON.stringify(formData));
          }else{
            localStorage.removeItem("credenciales");
          }

          this.correctLogin(data)
          this.loader=false;
        },
        (error) => {
          this.loader=false;
          this.txtError=error.error.Message;
          this.loginForm.reset();
        this.submitted = false;

        }
      )
    }
  }


  private correctLogin(data: sessionDTO){
    this.auth.setCurrentSession(data);
    
    this.router.navigate(['home']);
  }

  logOut(){
    this.auth.removeCurrentSession();
    this.isAuthenticated=this.auth.isAuthenticated();
  }


}
