import { historiaDTO } from './historiaDTO';
import { tratamientoCliDTO } from './tratamientoDTO';
import { BonoDTO } from './bonoDTO';
import { consentimientoGeneralCliDTO } from './consentimientoGeneralcliDTO';
import { pagoDTO } from './pagoDTO';

export class clienteDTO {
    id: number=0;
    fechaAlta:string=new Date().toISOString().slice(0,10); 
    fechaNac:string;
    nif: string='';
    nombre: string='';
    direccion: string='';
    cp: string='';
    poblacion: string='';
    provinca: string='';
    email:string='';
    telefono:string='';
    observaciones:string='';
    foto:string='';
    genero:number=0;

    historiasCli:Array<historiaDTO>=[];
    tratamientosCli:Array<tratamientoCliDTO>=[];
    bonosCli:Array<BonoDTO>=[];
    consentimientosGeneralesCli:Array<consentimientoGeneralCliDTO>=[];
    pagosCli:Array<pagoDTO>=[];
    
    
}